import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, TooltipCoordinator } from "@frontapp/ui-kit";
import React from "react";

const Feedback = ({ selectResponseTypeHandler, openModal }) => {
  return (
    <div className="feedback-container">
      <TooltipCoordinator
        renderTooltip={() => <Tooltip>Good Experience</Tooltip>}
      >
        <button
          onClick={() => {
            selectResponseTypeHandler("GOOD");
            openModal();
          }}
          className="feedback-button feedback-button-green"
        >
          <FontAwesomeIcon fontSize={18} icon={faThumbsUp} />
        </button>
      </TooltipCoordinator>
      <TooltipCoordinator
        renderTooltip={() => <Tooltip>Bad Experience</Tooltip>}
      >
        <button
          onClick={() => {
            selectResponseTypeHandler("BAD");
            openModal();
          }}
          className="feedback-button feedback-button-red"
        >
          <FontAwesomeIcon fontSize={18} icon={faThumbsDown} />
        </button>
      </TooltipCoordinator>
    </div>
  );
};

export default Feedback;
