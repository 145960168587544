import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, SelectItem } from "@frontapp/ui-kit";
import React from "react";
import MaximizeSvg from "../assets/images/maximize.svg";
const AttachmentsDropdown = ({
  attachments,
  selectedAttachment,
  handleNavigateBack,
  handleSelectAttachment,
  handleExpand,
}) => {
  return (
    <div className="attachments-container">
      <div className="attachments-dropdown-container">
        <FontAwesomeIcon
          className="attachment-dropdown-1"
          onClick={handleNavigateBack}
          fontSize={12}
          icon={faChevronLeft}
        />
        <div className="attachments-dropdown">
          <Select
            placeholder="Choose Attachment"
            selectedValues={selectedAttachment.name}
            layerRootId="story--components-select--basic"
          >
            {attachments?.length
              ? attachments?.map((attachment) => (
                  <SelectItem
                    key={attachment.id}
                    isSelected={selectedAttachment?.id === attachment?.id}
                    onClick={() => handleSelectAttachment(attachment)}
                  >
                    {attachment.name}
                  </SelectItem>
                ))
              : null}
          </Select>
        </div>
        <button className="expand-btn" onClick={handleExpand}>
          <img
            src={MaximizeSvg}
            alt="expand the highlight"
            className="expand-button attachment-dropdown-2"
          />
        </button>
      </div>
    </div>
  );
};

export default AttachmentsDropdown;
