import Front from "@frontapp/plugin-sdk";

const updateDraft = async (id, updateDraftOption) => {
  try {
    const result = await Front.updateDraft(id, updateDraftOption);
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default updateDraft;
