import { Textarea, Button, Paragraph } from "@frontapp/ui-kit";
import BeatLoader from "react-spinners/BeatLoader";

const AIResponse = ({
  isLoading,
  progressMessage,
  value,
  onChange,
  submitButtonConfig,
  title,
  error,
  actionsButtonComponent,
  RedError,
}) => {
  if (isLoading) {
    return (
      <div className="loading-container">
        <p className="loading-message">{progressMessage}</p>
        <div className="spinner">
          <BeatLoader
            color={"#0059b3"}
            loading={true}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  if (error && RedError) {
    return <Paragraph color={"red"}>{error}</Paragraph>;
  }

  return (
    <div className={"ai-response-container"}>
      <h3 className="heading">{title}</h3>
      <Textarea
        rows={10}
        value={value}
        onChange={onChange}
        shouldAllowResize={true}
      />
      <div
        className="ai-response-1"
      ></div>

      {actionsButtonComponent ? (
        <div>{actionsButtonComponent}</div>
      ) : (
        <div className="flex justify-end">
          {!!submitButtonConfig ? (
            <Button type="primary" onClick={submitButtonConfig?.onSubmit}>
              {submitButtonConfig?.title}
            </Button>
          ) : null}
        </div>
      )}
      {error && !RedError && <Paragraph color={"green"}>{error}</Paragraph>}
    </div>
  );
};

export default AIResponse;
