import { front_plugin_url } from "../../constants/variables";

export const logoutUser = () => {
  const origin = localStorage.getItem("origin");
  const appGuid = localStorage.getItem("app_guid");

  const error =
    "Unauthorized! Please ensure you are subscribed to Upbrains Chat and check your Front Credential in the UpBrains Portal.";
  console.log(error);
  localStorage.setItem("unauthorized", "true");

  setTimeout(() => {
    window.location.replace(
      front_plugin_url + `/?app_guid=${appGuid}&origin=${origin}`
    );
    localStorage.clear();
  }, 4000);

  return error;
};
