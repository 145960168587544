import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Select, SelectItem, Textarea } from "@frontapp/ui-kit";
import { BeatLoader } from "react-spinners";

const TemplateModal = ({
  open,
  onClose,
  templateTextareaValue,
  templateName,
  changeTemplateName,
  changeTemplateTextarea,
  onSave,
  resources,
  templateCategory,
  setTemplateCategory,
  isLoading,
  error,
  data,
}) => {
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    if (resources?.length) {
      const categoryRes = resources.map(({ category }) => {
        if (
          category &&
          categoriesList.findIndex((item) => item === category) === -1
        ) {
          return category;
        }
      });

      setCategoriesList(categoryRes);
      setTemplateCategory(categoryRes[0]);
    } else {
      setCategoriesList(["Default"]);
      setTemplateCategory("Default");
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="feedback-modal">
        <div className="template-1">
          <h2>Save Your Response as a Template</h2>
          <button className="feedback-modal-close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
        <div className="template-2">
          <div className="template-3">
            <div className="custom-form-label">Template Name:</div>
            <input
              value={templateName}
              placeholder="Template Name"
              className="response-assist-input-url"
              onChange={(e) => changeTemplateName(e.target.value)}
            />
          </div>
          <div className="form-element-container">
            <div className="custom-form-label">Folder:</div>
            <Select
              placeholder="Choose template"
              selectedValues={templateCategory}
              layerRootId="story--components-select--basic"
            >
              {categoriesList.map((item) => (
                <SelectItem
                  key={item}
                  onClick={() => setTemplateCategory(item)}
                  isSelected={templateCategory === item}
                >
                  {item}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="template-3">
            <div className="custom-form-label">Template Response:</div>
            <Textarea
              rows={5}
              value={templateTextareaValue}
              onChange={changeTemplateTextarea}
              shouldAllowResize={true}
            />
          </div>
        </div>
        <div className="template-4">
          <Button
            className="margin-left-auto"
            type="primary"
            onClick={() => onSave()}
            isDisabled={
              !templateName ||
              !templateCategory?.length ||
              !templateTextareaValue
            }
          >
            {isLoading ? (
              <div className="template-5">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </div>
        {error && <div className="template-6">{error.message}</div>}
        {!error && !isLoading && data && (
          <div className="template-7">Saved as a template</div>
        )}
      </div>
    </Modal>
  );
};

export default TemplateModal;
