import { END_POINTS } from "../../constants/endpoints";
import { ignite_url } from "../../constants/variables";

export const getWorkflowsList = async (requestOptions) => {
  const res = await fetch(
    ignite_url + END_POINTS.SERVICE_APIS.INTEGRATION_CONFIG_WORKFLOWS,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const getConversationInfo = async (params, requestOptions) => {
  const res = await fetch(
    ignite_url + END_POINTS.INTEGRATION_APIS.GET_CONVERSATION_INFO + params,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postAttachmentsList = async (url, requestOptions) => {
  const res = await fetch(url, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};
