import { useState, useCallback, useMemo, useEffect } from "react";
import useChat from "../utils/hooks/useChat";
import DraftAResponseActions from "../components/common/DraftAResponseActions";
import { postUserFeedback } from "../api/api";
import { usePluginContext } from "../../../providers/contextManagement";
import { isZendesk } from "../../../constants/plugin_variables";
import usePrimitiveFunctions from "../services/server/usePrimitiveFunctions";
import useSWRMutation from "swr/mutation";

import { postAsComment } from "../api/comments";
import { getAttachmentExtractedResult } from "../api/extracor/index";
import useAttachmentsResultSWR from "../utils/hooks/useAttachmentsResultSWR";
import useAttachmentsSWR from "../utils/hooks/useAttachmentsSWR";
import showErrorMessage from "../utils/functions/showErrorMessage";

const useConversation = () => {
  const {
    SelectedTone,
    selectedMessageValue,
    selectLanguage,
    BulletItems,
    TokenSliderValue,
    DefaultTokenSliderValue,
    isFullConversationChecked,
    isSignatureChecked,
    Response1,
    InboxActionInProgress,
    InboxActionMessage,
    ErrorText1,
    disableReply,
    draftRedError,
    draftReplyError,
    disableDraftPrimary,
    loadingDraftReply,
    disableDraftSecondary,
    isLoading,
    isResLoading,
    isWorkflowLoading,
    _error,
    coldError,
    successMsg,
    draftLoaderStatus,
    responseAssistResRaw,
    TeamEmail,
    UpbrainsToken,
    ConversationID,
    postReply,
    postDraft,
    postNewDraft,
    handleChangeSignature,
    handleChangeFullConversation,
    valueText,
    handleChangeTokenSlider,
    handleSelectMessageValue,
    handleSelectedTone,
    handleSelectedLanguage,
    handleChangeBulletItems,
    handleSummarization,
    handleGeneratedDraftResponse,
    handleChangeResponse1,
    handleInsertAsComment,
    handleAutoDraft,
    handleResponseSnippets,
    handleSearchResources,
    handleAddToPoint,
    handleReset,
    resourcesList,
    handleSearchResource,
    resetEveryThing,
    autoCompleteValue,
    setAutocompleteValue,
    handleAutoCompleteChange,
    handleResponseAssistTextChange,
    searchInputTerm,
    isEngine,
    urlInput,
    langParams,
    workflowObj,
    handleUrlInput,
    handleLastMessage,
    handleTypeaheadChange,
    handleResponseAssistInputChange,
    features,
    handleSelectTemplate,
    selectedTemplate,
    getOrganizedResourcesLoading,
    getOrganizedResourcesError,
    resources,
    workflows,
    apis,
    templates,
    isSnippets,
    isColChecked,
    hasApiResult,
    selectedRows,
    clearFirstWord,
    setIsColChecked,
    setSelectedRows,
    setHasApiResult,
    RedError,
    getLastMessage,
    extractors,
    selectedExtractor,
    setSelectedExtractor,
    selectedConversationType,
    handleSelectConversationType,
    selectedConversationCount,
    handleSelectConversationCount,
  } = useChat();

  const { checkAuth, postLog, checkAuthForFormData } = usePrimitiveFunctions(
    UpbrainsToken,
    TeamEmail
  );

  const handlePostUserFeedback = async () => {
    try {
      const headers = await checkAuth();
      const body = JSON.stringify({
        plugin_user_name: context?.teammate?.username,
        plugin_user_email: context?.teammate?.email,
        integration_name: isZendesk ? "zendesk" : "front",
        rating: feedbackType === "GOOD" ? "1" : "-1",
        comment: feedback,
        service_name: "Chat",
        service_feature: selectedFeature.title,
        service_subfeature: "Plugin",
        service_metadata: "",
      });

      const requestOptions = {
        method: "POST",
        body,
        headers,
        redirect: "follow",
      };

      const result = await postUserFeedback(requestOptions);
      const response = await result.json();
      return response;
    } catch (e) {
      console.log("e", e);
    }
  };

  const fetchAttachmentResult = async () => {
    try {
      const headers = await checkAuthForFormData();
      const attachmentResult = await getAttachmentExtractedResult(
        selectedExtractor.id,
        selectedAttachment,
        headers,
        isZendesk ? "Zendesk" : "Front"
      );

      if (attachmentResult?.status !== 200)
        throw new Error(attachmentResult.status);

      const result = {
        payload: {
          processor_result: {
            ...attachmentResult,
          },
          processor_type: "Extractor",
        },
      };

      setAttachmentResult(result);
      return Promise.resolve(result);
    } catch (error) {
      const errorMessage = showErrorMessage(error.message);
      postLog(
        `fetchAttachmentResult: ${error}`,
        error.constructor.name,
        error.message,
        "attachment"
      );
      return Promise.reject(error.message);
    }
  };

  const postAttachmentResultAsComment = async (url, args) => {
    const integrationName = isZendesk ? "Zendesk" : "Front";
    const conversationId = context?.conversation?.id;
    const comment = args.arg?.payload?.processor_result?.extractor_result
      ?.documents
      ? args.arg?.payload?.processor_result?.extractor_result?.documents?.[0]
          ?.content
      : args.arg?.payload?.processor_result?.extractor_result?.pages?.[0]?.text;

    const commentData = isZendesk
      ? {
          ticket_id: conversationId,
          message: comment,
        }
      : {
          conversation_id: conversationId,
          comment_text: comment,
        };
    try {
      const headers = await checkAuth();
      const postResult = await postAsComment(
        integrationName,
        commentData,
        headers
      );
      return postResult;
    } catch (error) {
      throw new Error(error);
    }
  };

  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [attachmentResult, setAttachmentResult] = useState(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [attachmentType, setAttachmentType] = useState("MESSAGE");
  const [MessageIndex, setMessageIndex] = useState(0);
  const [hasAttachmentResult, setHasAttachmentResult] = useState(null);

  const [showDraftConfig, setShowDraftConfig] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(
    Boolean(localStorage.getItem("selected_feature_id")) &&
      features.find(
        (feature) => feature.id === localStorage.getItem("selected_feature_id")
      )
      ? features.find(
          (feature) =>
            feature.id === localStorage.getItem("selected_feature_id")
        )
      : {
          id: "draftAResponse",
          title: "Draft a Response",
          hasResponse: false,
          isDisabled: false,
        }
  );
  const [isCustomMode, setIsCustomMode] = useState(false);

  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [openAttachmentsModal, setAttachmentModal] = useState();

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  const {
    attachments,
    getAttachmentsError,
    getAttachmentsLoading,
    getAttachmentsMutate,
  } = useAttachmentsSWR(
    selectedConversationType,
    isZendesk,
    UpbrainsToken,
    TeamEmail
  );

  const {
    attachmentsResults,
    getAttachmentsResultError,
    getAttachmentsResultLoading,
    mutateAttachmentsResult,
  } = useAttachmentsResultSWR(UpbrainsToken, TeamEmail);

  const {
    data: fetchedAttachmentResult,
    trigger: mutateAttachmentResult,
    isMutating: getAttachmentResultMutating,
    error: getAttachmentResultError,
    reset: resetAttachmentResult,
  } = useSWRMutation(
    [
      "integration-apis/extract-file",
      selectedAttachment?.id,
      selectedExtractor?.id,
    ],
    fetchAttachmentResult
  );

  const {
    data: feedbackResult,
    trigger: mutateFeedback,
    isMutating: mutatingFeedback,
    error: mutateFeedbackError,
    reset: resetFeedback,
  } = useSWRMutation("/service-apis/feedback", handlePostUserFeedback, {
    onSuccess: () => {
      setTimeout(() => {
        closeFeedbackModal();
      }, [1000]);
    },
  });

  const {
    data: postAsCommentData,
    trigger: postAsCommentMutate,
    isMutating: posingAsCommentMutating,
    error: postAsCommentError,
  } = useSWRMutation(
    [
      "/integration-apis/post-as-comment",
      isZendesk ? "Zendesk" : "Front",
      context?.conversation?.id,
      attachmentResult?.payload?.processor_result?.extractor_result
        ?.documents?.[0]?.content || null,
    ],
    postAttachmentResultAsComment
  );

  const conversationSettingsProps = useMemo(() => {
    switch (selectedFeature?.id) {
      case "summarize":
        return {
          config: {
            hasSelectMessage: true,
            hasSelectLanguage: true,
            hasTokenSlider: true,
            hasSelectConversationType: true,
            hasSelectConversationCount: true,
          },
          submitButtonConfig: {
            buttonTitle: "Summarize",
            onSubmit: () => {
              features.find(({ id }) => id === "summarize").hasResponse = true;
              setSelectedFeature((prev) => ({ ...prev, hasResponse: true }));
              handleSummarization();
            },
          },
          selectedConversationCount,
          selectedConversationType,
          selectedMessageValue,
          selectLanguage,
          TokenSliderValue,
          DefaultTokenSliderValue,
          handleSelectConversationCount,
          handleSelectConversationType,
          handleChangeTokenSlider,
          handleSelectedLanguage,
          handleSelectMessageValue,
          messageSelectLabel: "What to Summarize",
          toneSelectLabel: "Summary Tone",
          languageSelectLabel: "Summary Language",
          textariaLabel: "Points to include in the Summary",
          tokenSliderLabel: "Max Summary Length",
          conversationTypeLabel: "Summarize",
          conversationCountLabel: `How many ${
            isZendesk ? "ticket" : "conversation"
          } to summarize`,
        };

      case "autoDraft":
        return {
          config: {
            isManual: false,
            hasSelectLanguage: true,
            hasTokenSlider: true,
            hasSelectTone: true,
            hasResourcesSetting: true,
          },
          title: (
            <p>
              Use AI to automatically draft a response using your resources (
              <a
                href="https://portal.upbrainsai.com/settings/knowledgebase"
                target="_blank"
                className="use-conversation-1"
              >
                Knowledge Base
              </a>{" "}
              and{" "}
              <a
                href="https://portal.upbrainsai.com/settings/apps"
                target="_blank"
                className="use-conversation-1"
              >
                APIs
              </a>
              ).
            </p>
          ),
          selectLanguage,
          SelectedTone,
          TokenSliderValue,
          defaultTokenSliderValue: DefaultTokenSliderValue,
          handleChangeTokenSlider,
          handleSelectedLanguage,
          handleSelectedTone,
          isLoading: isLoading || isResLoading || isWorkflowLoading,
          coldError: !!(!_error && coldError) && coldError,
          error: !!_error && _error,
          success: !!(!_error && !coldError && successMsg) && successMsg,
          loadingMsg: draftLoaderStatus,
          handleChangeTextArea: handleAddToPoint,
          submitButtonConfig: {
            buttonTitle: "Auto Draft",
            onSubmit: () => {
              handleAutoDraft();
            },
          },
        };

      case "draftAResponse":
        return {
          config: {
            hasTextArea: true,
            hasTokenSlider: true,
            hasSelectLanguage: true,
            hasResponseConfig: true,
            hasSelectTone: true,
            hasTemplate: true,
            hasAutoFindAnswer: resources?.length,
            hasResourcesSetting: true,
          },
          submitButtonConfig: {
            buttonTitle: "Draft a Response",
            onSubmit: () => {
              features.find(
                ({ id }) => id === "draftAResponse"
              ).hasResponse = true;
              setSelectedFeature((prev) => ({ ...prev, hasResponse: true }));
              handleGeneratedDraftResponse();
            },
          },
          selectedMessageValue,
          handleSelectMessageValue,
          SelectedTone,
          handleSelectedTone,
          handleSelectedLanguage,
          selectLanguage,
          BulletItems,
          handleChangeBulletItems,
          handleChangeTokenSlider,
          DefaultTokenSliderValue,
          valueText,
          TokenSliderValue,
          isFullConversationChecked,
          handleChangeFullConversation,
          isSignatureChecked,
          handleChangeSignature,
          templates,
          handleSelectTemplate,
          selectedTemplate,
          templateSelectedLabel: "Response Template",
          findAnswerHandler: () => handleResponseSnippets(),

          isLoading: isLoading || isResLoading || isWorkflowLoading,
          coldError: !!(!_error && coldError) && coldError,
          error: !!_error && _error,
          success: !!(!_error && !coldError && successMsg) && successMsg,
          loadingMsg: draftLoaderStatus,
          AIGeneratedResponse: responseAssistResRaw,
          handleChangeTextArea: handleAddToPoint,
          resourcesList,
          resetEveryThing,
          autoCompleteValue,
          setAutocompleteValue,
          handleAutoCompleteChange,
          handleResponseAssistTextChange,
          searchInputTerm,
          isEngine,
          urlInput,
          isWorkflowLoading,
          langParams,
          workflowObj,
          responseAssistResRaw,
          handleUrlInput,
          handleLastMessage,
          handleTypeaheadChange,
          handleResponseAssistInputChange,
        };

      case "Workflow":
        return {
          config: {
            isManual: true,
            hasWorkflow: true,
            hasTextArea: true,
            hasTokenSlider: true,
            hasSelectLanguage: true,
            hasResponseConfig: true,
            hasResourcesSetting: true,
            hasSelectTone: true,
            hasTemplate: true,
            hasTwoSteps: true,
            showDraftConfig,
          },
          submitButtonConfig: {
            buttonTitle: "Draft a Response",
            onSubmit: () => {
              features.find(({ id }) => id === "Workflow").hasResponse = true;
              setSelectedFeature((prev) => ({ ...prev, hasResponse: true }));

              handleGeneratedDraftResponse();
            },
          },
          label: "Select your attachment",
          title: (
            <p>
              workflows (create workflows in
              <a
                href="https://portal.upbrains.ai"
                target="_blank"
                className="use-conversation-1"
              >
                UpBrains Portal
              </a>
              ):
            </p>
          ),
          templates,
          handleSelectTemplate,
          selectedTemplate,
          templateSelectedLabel: "Response Template",

          resources: workflows,
          selectedMessageValue,
          handleSelectMessageValue,
          SelectedTone,
          handleSelectedTone,
          handleSelectedLanguage,
          selectLanguage,
          BulletItems,
          handleChangeBulletItems,
          handleChangeTokenSlider,
          DefaultTokenSliderValue,
          valueText,
          TokenSliderValue,
          isFullConversationChecked,
          handleChangeFullConversation,
          isSignatureChecked,
          handleChangeSignature,
          handleGetResources: (searchType) => handleSearchResources(searchType),
          isLoading: isLoading || isResLoading || isWorkflowLoading,
          coldError: !!(!_error && coldError) && coldError,
          error: !!_error && _error,
          success: !!(!_error && !coldError && successMsg) && successMsg,
          loadingMsg: draftLoaderStatus,
          AIGeneratedResponse: responseAssistResRaw,
          handleChangeTextArea: handleAddToPoint,
          resourcesList,
          handleAskResources: () => {
            const callback = () => {
              setShowDraftConfig(true);
            };
            handleSearchResource(callback);
          },
          resetEveryThing,
          autoCompleteValue,
          setAutocompleteValue,
          handleAutoCompleteChange,
          handleResponseAssistTextChange,
          searchInputTerm,
          isEngine,
          urlInput,
          isWorkflowLoading,
          langParams,
          workflowObj,
          responseAssistResRaw,
          handleUrlInput,
          handleLastMessage,
          handleTypeaheadChange,
          handleResponseAssistInputChange,
          isSnippets,
          isColChecked,
          hasApiResult,
          selectedRows,
          clearFirstWord,
          setIsColChecked,
          setSelectedRows,
          setHasApiResult,
        };

      case "API":
        return {
          config: {
            isManual: true,
            hasApis: true,
            hasTextArea: true,
            hasTokenSlider: true,
            hasSelectLanguage: true,
            hasResponseConfig: true,
            hasResourcesSetting: true,
            hasSelectTone: true,
            hasTemplate: true,
            hasTwoSteps: true,
            showDraftConfig,
          },
          submitButtonConfig: {
            buttonTitle: "Draft a Response",
            onSubmit: () => {
              features.find(({ id }) => id === "API").hasResponse = true;
              setSelectedFeature((prev) => ({ ...prev, hasResponse: true }));
              handleGeneratedDraftResponse();
            },
          },
          label: "Select your API",
          title: "Call your APIs and use the results to draft a response.",
          templates,
          handleSelectTemplate,
          selectedTemplate,
          templateSelectedLabel: "Response Template",
          resources: apis,
          selectedMessageValue,
          handleSelectMessageValue,
          SelectedTone,
          handleSelectedTone,
          handleSelectedLanguage,
          selectLanguage,
          BulletItems,
          handleChangeBulletItems,
          handleChangeTokenSlider,
          DefaultTokenSliderValue,
          valueText,
          TokenSliderValue,
          isFullConversationChecked,
          handleChangeFullConversation,
          isSignatureChecked,
          handleChangeSignature,
          handleGetResources: (searchType) => handleSearchResources(searchType),
          isLoading: isLoading || isResLoading || isWorkflowLoading,
          coldError: !!(!_error && coldError) && coldError,
          error: !!_error && _error,
          success: !!(!_error && !coldError && successMsg) && successMsg,
          loadingMsg: draftLoaderStatus,
          AIGeneratedResponse: responseAssistResRaw,
          handleChangeTextArea: handleAddToPoint,
          resourcesList,
          handleAskResources: () => {
            const callback = () => {
              setShowDraftConfig(true);
            };
            handleSearchResource(callback);
          },
          handleSearchResource,
          resetEveryThing,
          autoCompleteValue,
          setAutocompleteValue,
          handleAutoCompleteChange,
          handleResponseAssistTextChange,
          searchInputTerm,
          isEngine,
          urlInput,
          isWorkflowLoading,
          langParams,
          workflowObj,
          responseAssistResRaw,
          handleUrlInput,
          handleLastMessage,
          handleTypeaheadChange,
          handleResponseAssistInputChange,
          isSnippets,
          isColChecked,
          hasApiResult,
          selectedRows,
          clearFirstWord,
          setIsColChecked,
          setSelectedRows,
          setHasApiResult,
        };

      case "searchMyResources":
        return {
          config: {
            isManual: true,
            hasTextArea: true,
            hasTokenSlider: true,
            hasSelectLanguage: true,
            hasResponseConfig: true,
            hasResourcesSetting: true,
            hasSelectTone: true,
            hasTemplate: true,
            hasTwoSteps: true,
            showDraftConfig,
          },
          submitButtonConfig: {
            buttonTitle: "Draft a Response",
            onSubmit: () => {
              features.find(
                ({ id }) => id === "searchMyResources"
              ).hasResponse = true;
              setSelectedFeature((prev) => ({ ...prev, hasResponse: true }));

              handleGeneratedDraftResponse();
            },
          },
          label: "Select your resource",
          title: (
            <p>
              Search your knowledge base or the Web directly and use the results
              to draft a response. <br />
              <span className="use-conversation-2">
                Upload documents, FAQs and resources to your knowledge using{" "}
                <a
                  href="https://portal.upbrainsai.com/settings/knowledgebase"
                  target="_blank"
                  className="use-conversation-1"
                >
                  UpBrains Portal
                </a>
                .
              </span>
            </p>
          ),
          templates,
          handleSelectTemplate,
          selectedTemplate,
          templateSelectedLabel: "Response Template",
          resources,
          selectedMessageValue,
          handleSelectMessageValue,
          SelectedTone,
          handleSelectedTone,
          handleSelectedLanguage,
          selectLanguage,
          BulletItems,
          handleChangeBulletItems,
          handleChangeTokenSlider,
          DefaultTokenSliderValue,
          valueText,
          TokenSliderValue,
          isFullConversationChecked,
          handleChangeFullConversation,
          isSignatureChecked,
          handleChangeSignature,
          isLoading: isLoading || isResLoading || isWorkflowLoading,
          coldError: !!(!_error && coldError) && coldError,
          error: !!_error && _error,
          success: !!(!_error && !coldError && successMsg) && successMsg,
          loadingMsg: draftLoaderStatus,
          AIGeneratedResponse: responseAssistResRaw,
          handleChangeTextArea: handleAddToPoint,
          resourcesList,
          handleAskResources: () => {
            const callback = () => {
              setShowDraftConfig(true);
            };
            handleSearchResource(callback);
          },
          resetEveryThing,
          autoCompleteValue,
          setAutocompleteValue,
          handleAutoCompleteChange,
          handleResponseAssistTextChange,
          searchInputTerm,
          isEngine,
          urlInput,
          isWorkflowLoading,
          langParams,
          workflowObj,
          responseAssistResRaw,
          handleUrlInput,
          handleLastMessage,
          handleTypeaheadChange,
          handleResponseAssistInputChange,
          isSnippets,
          isColChecked,
          hasApiResult,
          selectedRows,
          clearFirstWord,
          setIsColChecked,
          setSelectedRows,
          setHasApiResult,
        };

      default:
        break;
    }
  }, [
    isSnippets,
    autoCompleteValue,
    isColChecked,
    hasApiResult,
    selectedRows,
    clearFirstWord,
    setIsColChecked,
    setSelectedRows,
    setHasApiResult,
    isEngine,
    urlInput,
    langParams,
    workflowObj,
    handleUrlInput,
    handleLastMessage,
    handleTypeaheadChange,
    handleResponseAssistInputChange,
    searchInputTerm,
    setAutocompleteValue,
    handleAutoCompleteChange,
    handleResponseAssistTextChange,
    resetEveryThing,
    handleSearchResource,
    resourcesList,
    responseAssistResRaw,
    draftLoaderStatus,
    isLoading,
    isResLoading,
    isWorkflowLoading,
    _error,
    coldError,
    successMsg,
    selectedFeature?.id,
    SelectedTone,
    selectedMessageValue,
    selectLanguage,
    BulletItems,
    TokenSliderValue,
    DefaultTokenSliderValue,
    isFullConversationChecked,
    isSignatureChecked,
    handleChangeSignature,
    handleChangeFullConversation,
    valueText,
    handleChangeTokenSlider,
    handleSelectMessageValue,
    handleSelectedTone,
    handleSelectedLanguage,
    handleChangeBulletItems,
    handleSummarization,
    handleGeneratedDraftResponse,
    features,
    handleSearchResources,
    handleAutoDraft,
    handleResponseSnippets,
    handleAddToPoint,
    selectedTemplate,
    handleSelectTemplate,
    templates,
    apis,
    workflows,
    resources,
    showDraftConfig,
    handleSelectConversationCount,
    handleSelectConversationType,
    selectedConversationCount,
    selectedConversationType,
  ]);

  const responseComponentProps = useMemo(() => {
    switch (selectedFeature?.id) {
      case "summarize":
        return {
          title: "Summary",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          RedError,
          onChange: handleChangeResponse1,
          value: Response1,
          submitButtonConfig: {
            title: "Insert as Comment",
            onSubmit: () => {
              handleInsertAsComment();
            },
          },
        };

      case "draftAResponse":
        return {
          title: "Drafted Response",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          onChange: handleChangeResponse1,
          value: Response1,
          RedError,
          actionsButtonComponent: (
            <DraftAResponseActions
              disableDraftSecondary={disableDraftSecondary}
              postDraft={postDraft}
              disableDraftPrimary={disableDraftPrimary}
              postNewDraft={postNewDraft}
              disableReply={disableReply}
              postReply={postReply}
              loadingDraftReply={loadingDraftReply}
              draftReplyError={draftReplyError}
              draftRedError={draftRedError}
              onSaveAsTemplate={() => setOpenTemplateModal(true)}
            />
          ),
        };

      case "autoDraft":
        return {
          title: "Auto Draft",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          onChange: handleChangeResponse1,
          value: Response1,
          RedError,
          actionsButtonComponent: (
            <DraftAResponseActions
              disableDraftSecondary={disableDraftSecondary}
              postDraft={postDraft}
              disableDraftPrimary={disableDraftPrimary}
              postNewDraft={postNewDraft}
              disableReply={disableReply}
              postReply={postReply}
              loadingDraftReply={loadingDraftReply}
              draftReplyError={draftReplyError}
              draftRedError={draftRedError}
            />
          ),
        };

      case "Workflow":
        return {
          title: "Workflow",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          onChange: handleChangeResponse1,
          value: Response1,
          RedError,
          actionsButtonComponent: (
            <DraftAResponseActions
              disableDraftSecondary={disableDraftSecondary}
              postDraft={postDraft}
              disableDraftPrimary={disableDraftPrimary}
              postNewDraft={postNewDraft}
              disableReply={disableReply}
              postReply={postReply}
              loadingDraftReply={loadingDraftReply}
              draftReplyError={draftReplyError}
              draftRedError={draftRedError}
            />
          ),
        };

      case "API":
        return {
          title: "Drafted Response",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          onChange: handleChangeResponse1,
          value: Response1,
          RedError,
          actionsButtonComponent: (
            <DraftAResponseActions
              disableDraftSecondary={disableDraftSecondary}
              postDraft={postDraft}
              disableDraftPrimary={disableDraftPrimary}
              postNewDraft={postNewDraft}
              disableReply={disableReply}
              postReply={postReply}
              loadingDraftReply={loadingDraftReply}
              draftReplyError={draftReplyError}
              draftRedError={draftRedError}
            />
          ),
        };

      case "searchMyResources":
        return {
          title: "Drafted Response",
          isLoading: InboxActionInProgress,
          progressMessage: InboxActionMessage,
          error: ErrorText1,
          onChange: handleChangeResponse1,
          value: Response1,
          RedError,
          actionsButtonComponent: (
            <DraftAResponseActions
              disableDraftSecondary={disableDraftSecondary}
              postDraft={postDraft}
              disableDraftPrimary={disableDraftPrimary}
              postNewDraft={postNewDraft}
              disableReply={disableReply}
              postReply={postReply}
              loadingDraftReply={loadingDraftReply}
              draftReplyError={draftReplyError}
              draftRedError={draftRedError}
            />
          ),
        };
      default:
        break;
    }
  }, [
    handleChangeResponse1,
    Response1,
    selectedFeature,
    ErrorText1,
    InboxActionInProgress,
    InboxActionMessage,
    handleInsertAsComment,
    disableDraftPrimary,
    disableDraftSecondary,
    disableReply,
    draftRedError,
    draftReplyError,
    loadingDraftReply,
    postDraft,
    postNewDraft,
    postReply,
    RedError,
  ]);

  const handleSelectAttachment = useCallback(
    (attachment) => {
      if (attachment) {
        const attachmentsFeature = features.find(
          (feature) => feature.id === "ATTACHMENTS"
        );
        handleSelectFeature({
          ...attachmentsFeature,
          selectedByAttachment: true,
        });
        if (
          attachmentsResults?.length &&
          attachmentsResults.find(
            (result) =>
              result?.payload?.processor_result?.extractor_result?.file_name ===
              attachment.name
          )
        ) {
          setHasAttachmentResult(true);
          setSelectedAttachment(attachment);
          const index = attachmentsResults.findIndex(
            (result) => result.id === attachment.id
          );
          setAttachmentResult(
            attachmentsResults.find(
              (result) =>
                result?.payload?.processor_result?.extractor_result
                  ?.file_name === attachment.name
            )
          );
          setMessageIndex(index);
          const attachmentAction = features.find(
            (feature) => feature.id === "ATTACHMENT"
          );
          handleSelectFeature(attachmentAction);
        } else {
          setHasAttachmentResult(false);
          setSelectedAttachment(attachment);
          setAttachmentResult(null);
        }
      } else {
        setAttachmentResult(null);
        setSelectedAttachment(null);
        setAttachmentResult(false);
      }
    },
    [attachmentsResults, features]
  );

  const handleSelectFeature = useCallback(
    (feature) => {
      if (feature) {
        if (feature.id === "ATTACHMENTS") {
          if (feature?.selectedByAttachment) {
            features.forEach((feature) => ({
              ...feature,
              hasResponse: false,
            }));
            setSelectedFeature({ ...feature, hasResponse: false });
          } else {
            handleSelectAttachment(attachments[0]);
            features.forEach((feature) => ({
              ...feature,
              hasResponse: false,
            }));
            setSelectedFeature({ ...feature, hasResponse: false });
          }
        } else {
          if (selectedFeature?.id !== feature?.id) {
            handleReset();
            setSelectedAttachment(false);
            setShowDraftConfig(false);

            features.forEach((feature) => ({
              ...feature,
              hasResponse: false,
            }));
            setSelectedFeature({ ...feature, hasResponse: false });
            setSelectedAttachment(null);
            setAttachmentResult(null);
          } else {
            setSelectedFeature((prev) => ({ ...prev }));
          }
        }
      } else setSelectedFeature(null);
    },
    [
      setSelectedFeature,
      features,
      selectedFeature,
      handleReset,
      attachments,
      handleSelectAttachment,
    ]
  );

  const handleChangeActionToDefaultOne = useCallback(() => {
    handleSelectAttachment(null);

    setSelectedFeature(
      Boolean(localStorage.getItem("selected_feature_id")) &&
        features.find(
          (feature) =>
            feature.id === localStorage.getItem("selected_feature_id")
        )
        ? features.find(
            (feature) =>
              feature.id === localStorage.getItem("selected_feature_id")
          )
        : {
            id: "draftAResponse",
            title: "Draft a Response",
            hasResponse: false,
            isDisabled: false,
          }
    );
    resetAttachmentResult();
  }, [handleSelectAttachment, resetAttachmentResult]);

  const handleSetFeedbackType = useCallback(
    (type) => setFeedbackType(type),
    []
  );

  const handleBackUserToExtractingStep = () => {
    setHasAttachmentResult(false);
    setAttachmentResult(null);
  };

  const handleSeeAllAttachments = () => {
    setAttachmentModal(true);
  };

  const handleSelectAttachmentsType = useCallback(
    (_attachmentType) => {
      try {
        setAttachmentType(_attachmentType);
        getAttachmentsMutate();
      } catch (error) {
        console.log("error", error);
      }
    },
    [getAttachmentsMutate, context?.conversation?.id]
  );

  const handleChangeUserFeedback = useCallback(
    (value) => setFeedback(value),
    []
  );
  const openFeedbackModal = () => {
    setFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setFeedbackModalOpen(false);
  };

  useEffect(() => {
    setAttachmentModal(false);
    setSelectedAttachment(false);
    setAttachmentResult(null);
    handleChangeActionToDefaultOne();
  }, [context?.conversation?.id, handleChangeActionToDefaultOne]);

  const [templateCategory, setTemplateCategory] = useState(null);

  return {
    handleSeeAllAttachments,
    setAttachmentModal,
    openAttachmentsModal,
    setIsCustomMode,
    isCustomMode,
    TeamEmail,
    UpbrainsToken,
    ConversationID,
    getOrganizedResourcesLoading,
    selectedFeature,
    handleChangeActionToDefaultOne,
    conversationSettingsProps,
    responseComponentProps,
    features,
    handleSelectFeature,
    getOrganizedResourcesError,
    setOpenTemplateModal,
    openTemplateModal,
    Response1,
    getLastMessage,
    handleSetFeedbackType,
    feedbackType,
    isFeedbackModalOpen,
    closeFeedbackModal,
    openFeedbackModal,
    setFeedbackModalOpen,
    mutateFeedback,
    feedbackResult,
    mutatingFeedback,
    mutateFeedbackError,
    feedback,
    handleChangeUserFeedback,
    extractors,
    attachments,
    attachmentsResults,
    getAttachmentsLoading,
    getAttachmentsError,
    getAttachmentsResultError,
    getAttachmentsResultLoading,
    handleSelectAttachment,
    selectedAttachment,
    attachmentResult,
    selectedExtractor,
    setSelectedExtractor,
    mutateAttachmentResult,
    handleBackUserToExtractingStep,
    hasAttachmentResult,
    fetchedAttachmentResult,
    getAttachmentResultMutating,
    getAttachmentResultError,
    postAsCommentMutate,
    postAsCommentData,
    posingAsCommentMutating,
    postAsCommentError,
    handleSelectAttachmentsType,
    attachmentType,
    MessageIndex,
    resources,
    templateCategory,
    setTemplateCategory,
    mutateAttachmentsResult,
    clearFirstWord,
    selectLanguage,
    handleSelectedLanguage,
  };
};

export default useConversation;
