import React from "react";
import { Button, Heading, Paragraph } from "@frontapp/ui-kit";
import { Space } from "../../assets/styles/js/components.style";
import SelectableTable from "./SelectableTable";

const AIGeneratedResponseAssist = ({
  generatedResponse,
  handleChangeTextArea,
  isSnippets,
  autoCompleteValue,
  isColChecked,
  hasApiResult,
  selectedRows,
  clearFirstWord,
  setIsColChecked,
  setSelectedRows,
  setHasApiResult,
}) => {
  return (
    <div>
      {!!generatedResponse?.length && (
        <div className="margin-top response-assist-result-container">
          <Heading size="large">Suggested Relevant Results:</Heading>
          <Space />
          <div className="response-assist-result-items-container">
            {generatedResponse.map(
              ({
                question,
                response,
                webSearchedContent,
                source,
                isCollapsed,
              }) => (
                <div key={`${question}-${Math.random()}-${isCollapsed}`}>
                  {!!question && <Paragraph isBold>{question}</Paragraph>}

                  {!!(
                    !isSnippets &&
                    autoCompleteValue?.type &&
                    autoCompleteValue.type?.toLowerCase() === "list" &&
                    isColChecked[autoCompleteValue.title]
                  ) && (
                    <>
                      <div
                        className={`api-result-container ${
                          !hasApiResult ? "remove-overflow" : ""
                        }`}
                      >
                        <div className="margin-top">
                          <SelectableTable
                            data={response}
                            isColChecked={isColChecked}
                            setIsColChecked={setIsColChecked}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            autoCompleteValue={autoCompleteValue}
                            setHasApiResult={setHasApiResult}
                            hasApiResult={hasApiResult}
                          />
                        </div>
                      </div>
                      {hasApiResult && (
                        <div className="margin-top api-result-add-to-point">
                          <Button
                            className="inboxAction"
                            type="tertiary"
                            onClick={(e) =>
                              handleChangeTextArea(
                                e,
                                question,
                                response,
                                "api_result"
                              )
                            }
                          >
                            Add to points
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  {!!(
                    !isSnippets &&
                    autoCompleteValue?.type &&
                    autoCompleteValue.type?.toLowerCase() === "record"
                  ) && (
                    <>
                      <div className="flex-col">
                        <div>
                          {response?.map(
                            ({ api_result, search_result, source }, index) => (
                              <div key={index}>
                                {!!(api_result && !search_result) && (
                                  <>
                                    {Object.keys(api_result)?.length ? (
                                      <>
                                        {Object.keys(api_result)?.map(
                                          (item) => (
                                            <div
                                              key={`${item}-${Math.random()}`}
                                              className="record-results-container margin-top"
                                            >
                                              <Paragraph isBold>
                                                {item}:&nbsp;
                                              </Paragraph>
                                              <Paragraph>
                                                {api_result[item]}
                                              </Paragraph>
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!(search_result && !api_result) && (
                                  <>
                                    {!search_result?.includes(
                                      "I don't know"
                                    ) ? (
                                      <div className="record-results-container margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          {search_result}
                                        </Paragraph>
                                      </div>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!source && (
                                  <div className="margin-top">
                                    <Paragraph>
                                      <span className="bold">Source:</span>{" "}
                                      {source}
                                    </Paragraph>
                                  </div>
                                )}
                                {!!(!api_result && !search_result) && (
                                  <div className="margin-top">
                                    <Paragraph>No response found!</Paragraph>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        <div className="justify-content-end">
                          <Button
                            className="inboxAction response-assist-result-items-url-add-to-point"
                            type="tertiary"
                            onClick={(e) =>
                              handleChangeTextArea(
                                e,
                                question,
                                response,
                                "search_result"
                              )
                            }
                          >
                            Add to points
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  {!!(
                    !isSnippets &&
                    response?.length &&
                    !autoCompleteValue.type
                  ) && (
                    <>
                      <div className="flex-col">
                        <div>
                          {response?.map(
                            ({ api_result, search_result, source }, index) => (
                              <div key={index}>
                                {!!(api_result && !search_result) && (
                                  <>
                                    {Object.keys(api_result)?.length ? (
                                      <>
                                        {Object.keys(api_result)?.map(
                                          (item) => (
                                            <div
                                              key={`${item}-${Math.random()}`}
                                              className="record-results-container margin-top"
                                            >
                                              <Paragraph isBold>
                                                {item}:&nbsp;
                                              </Paragraph>
                                              <Paragraph>
                                                {api_result[item]}
                                              </Paragraph>
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!(search_result && !api_result) && (
                                  <>
                                    {!search_result?.includes(
                                      "I don't know"
                                    ) ? (
                                      <div className="record-results-container margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          {search_result}
                                        </Paragraph>
                                      </div>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!source && (
                                  <div className="margin-top">
                                    <Paragraph>
                                      <span className="bold">Source:</span>{" "}
                                      {source}
                                    </Paragraph>
                                  </div>
                                )}
                                {!!(!api_result && !search_result) && (
                                  <div className="margin-top">
                                    <Paragraph>No response found!</Paragraph>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        <div className="justify-content-end">
                          <Button
                            className="inboxAction response-assist-result-items-url-add-to-point"
                            type="tertiary"
                            onClick={(e) =>
                              handleChangeTextArea(
                                e,
                                question,
                                response,
                                "search_result"
                              )
                            }
                          >
                            Add to points
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  {!!(isSnippets && response?.length) && (
                    <>
                      <div className="flex-col">
                        <div>
                          {response?.map(
                            ({ api_result, search_result, source }, index) => (
                              <div key={index}>
                                {!!(api_result && !search_result) && (
                                  <>
                                    {Object.keys(api_result)?.length ? (
                                      <>
                                        {Object.keys(api_result)?.map(
                                          (item) => (
                                            <div
                                              key={`${item}-${Math.random()}`}
                                              className="record-results-container margin-top"
                                            >
                                              <Paragraph isBold>
                                                {item}:&nbsp;
                                              </Paragraph>
                                              <Paragraph>
                                                {api_result[item]}
                                              </Paragraph>
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!(search_result && !api_result) && (
                                  <>
                                    {!search_result?.includes(
                                      "I don't know"
                                    ) ? (
                                      <div className="record-results-container margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          {search_result}
                                        </Paragraph>
                                      </div>
                                    ) : (
                                      <div className="margin-top">
                                        <Paragraph>
                                          <span className="bold">Answer:</span>{" "}
                                          No response found!
                                        </Paragraph>
                                      </div>
                                    )}
                                  </>
                                )}
                                {!!source && (
                                  <div className="margin-top">
                                    <Paragraph>
                                      <span className="bold">Source:</span>{" "}
                                      {source}
                                    </Paragraph>
                                  </div>
                                )}
                                {!!(!api_result && !search_result) && (
                                  <div className="margin-top">
                                    <Paragraph>No response found!</Paragraph>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        <div className="justify-content-end">
                          <Button
                            className="inboxAction response-assist-result-items-url-add-to-point"
                            type="tertiary"
                            onClick={(e) =>
                              handleChangeTextArea(
                                e,
                                question,
                                response,
                                "search_result"
                              )
                            }
                          >
                            Add to points
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  {!!webSearchedContent && (
                    <div>
                      <div className="response-assist-result-items-url-container margin-top">
                        <div
                          className={`response-assist-result-items-url ${
                            isCollapsed ? "ellipsis-url" : "word-break-url"
                          }`}
                        >
                          <span className="bold">Answer:</span>{" "}
                          {clearFirstWord(webSearchedContent)}
                        </div>
                      </div>
                      <div className="flex-row">
                        <div className="flex-one">
                          {!!source && (
                            <p>
                              <span className="bold">Source:</span> {source}
                            </p>
                          )}
                        </div>
                        <Button
                          className="inboxAction response-assist-result-items-url-add-to-point"
                          type="tertiary"
                          onClick={(e) =>
                            handleChangeTextArea(
                              e,
                              "",
                              webSearchedContent,
                              "web_search"
                            )
                          }
                        >
                          Add to points
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIGeneratedResponseAssist;
