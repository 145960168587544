import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, TooltipCoordinator } from "@frontapp/ui-kit";
import DocumentationIcon from "./common/images/DocumentationIcon";

const Features = ({ features, handleSelectFeature, selectedFeature }) => {
  const origin = localStorage.getItem("origin");
  const appGuid = localStorage.getItem("app_guid");

  return (
    <div>
      <div
        className="custom-form-label features-1"
      >
        <div className="custom-form-label">Quick Actions: </div>
        <div>
          <TooltipCoordinator
            renderTooltip={() => <Tooltip>Documentation</Tooltip>}
          >
            <a
              type="tertiary"
              href="https://community.upbrainsai.com/docs/product-walk-through/"
              target="_blank"
              className="features-2"
            >
              <DocumentationIcon />
            </a>
          </TooltipCoordinator>
          <TooltipCoordinator renderTooltip={() => <Tooltip>Settings</Tooltip>}>
            <a
              type="tertiary"
              href={`/setting/?app_guid=${appGuid}&origin=${origin}`}
            >
              <FontAwesomeIcon icon={faGear} />
            </a>
          </TooltipCoordinator>
        </div>
      </div>
      <div className="quick-actions">
        {features.map((feature) => (
          <TooltipCoordinator
            key={feature.id}
            renderTooltip={() => <Tooltip>{feature.hint}</Tooltip>}
          >
            <Button
              onClick={() => handleSelectFeature(feature)}
              type={
                selectedFeature?.id === feature.id ? "primary" : "secondary"
              }
              isActive={selectedFeature?.id === feature.id}
              size="small"
              isDisabled={feature.isDisabled}
            >
              {feature.title}
            </Button>
          </TooltipCoordinator>
        ))}
      </div>
    </div>
  );
};

export default Features;
