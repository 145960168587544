import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./modules/plugins-common/assets/styles/global.css";
import { FrontContextProvider } from "./providers/contextManagement";

import "bootstrap/dist/css/bootstrap.min.css";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <FrontContextProvider>
      <App />
    </FrontContextProvider>
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <FrontContextProvider>
//       <App />
//     </FrontContextProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
