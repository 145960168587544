import React from "react";
import { Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  TooltipCoordinator,
  Tooltip,
  Button,
  Textarea,
  Paragraph,
} from "@frontapp/ui-kit";
import { BeatLoader } from "react-spinners";

const FeedbackModal = ({
  open,
  onClose,
  handleSetFeedbackType,
  feedbackType,
  onSubmit,
  feedback,
  onFeedbackChange,
  feedbackResult,
  mutatingFeedback,
  mutateFeedbackError,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="feedback-modal">
        <div className="feedback-1">
          <h2>How was this experience?</h2>
          <button className="feedback-modal-close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
        <p>
          Your feedback is invaluable to us, helping us to enhance our services
          to better meet your needs.
        </p>
        <div className="response-buttons">
          <TooltipCoordinator
            renderTooltip={() => <Tooltip>Good Experience</Tooltip>}
          >
            <button
              onClick={() => {
                handleSetFeedbackType("GOOD");
              }}
              className={`feedback-button-large${
                feedbackType === "GOOD" ? "-active" : ""
              } `}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </button>
          </TooltipCoordinator>
          <TooltipCoordinator
            renderTooltip={() => <Tooltip>Bad Experience</Tooltip>}
          >
            <button
              onClick={() => {
                handleSetFeedbackType("BAD");
              }}
              className={`feedback-button-large${
                feedbackType === "BAD" ? "-active" : ""
              } `}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </button>
          </TooltipCoordinator>
        </div>
        <div className="custom-form-label feedback-2">Feedback: </div>
        <Textarea
          placeholder="Describe your experience"
          value={feedback}
          onChange={onFeedbackChange}
          rows={6}
        />
        <div className="feedback-3">
          <Button
            onClick={onSubmit}
            className="margin-left-auto"
            type="primary"
          >
            {mutatingFeedback ? (
              <div className="feedback-4">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
        {mutateFeedbackError && (
          <Paragraph color={"red"}>{mutateFeedbackError}</Paragraph>
        )}
        {!mutateFeedbackError && !mutatingFeedback && feedbackResult && (
          <Paragraph color={"green"}>{feedbackResult?.msg}</Paragraph>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackModal;
