import { useState, useEffect } from "react";
import { Button, Select, SelectItem } from "@frontapp/ui-kit";

const AutoComplete = ({
  data,
  label,
  onChange,
  isWorkflow,
  hasClearButton,
  hasDeleteButton,
  resetEveryThing,
  handleDeleteAction,
  defaultAutoComplete,
  setAutocompleteValue,
}) => {
  const [messages, setMessages] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const [selectedItem, setSelectedItem] = useState(defaultAutoComplete || {});
  const [selectedUserId, setSelectedUserId] = useState(
    defaultAutoComplete?.id || ""
  );

  const handleClearSearch = () => {
    setSearchValue("");
    setSelectedItem({});
    setSelectedUserId("");
  };

  const handleSelectItemClick = (
    id,
    title,
    text,
    type,
    index,
    appUrl,
    appName,
    inputParams,
    outputParams
  ) => {
    if (typeof resetEveryThing === "function") resetEveryThing();
    setSelectedUserId(id);
    setSelectedItem({
      id,
      title,
      ...((index || index === 0) && { index }),
      ...(type && { type }),
      ...(text && { text }),
      ...(appName && { appName }),
      ...(appUrl && { appUrl }),
      ...(inputParams && { inputParams }),
      ...(outputParams && { outputParams }),
    });
  };

  useEffect(() => {
    if (data && data.length) {
      const tempMsgs = data.map((item, index) => ({
        id: index,
        ...item,
      }));
      setMessages(tempMsgs);
    } else {
      setMessages([]);
    }
  }, [data]);

  useEffect(() => {
    if (!searchValue) {
      setUsersToDisplay(messages);
      return;
    }

    setUsersToDisplay(
      messages.filter((u) =>
        u.title?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
  }, [searchValue, messages]);

  useEffect(() => {
    const isEqualAry = data.map((item) => item?.title === selectedItem?.title);
    if (isEqualAry.find((item) => item === true)) {
      setAutocompleteValue(selectedItem);
    } else {
      setAutocompleteValue({});
    }
  }, [selectedItem]);

  return (
    <div
      className={`search-container ${
        !messages.length ? "disable-autocomplete" : ""
      }`}
    >
      <>
        <div
          className={`full-width ${
            !!(selectedUserId && hasClearButton) ? "select-container" : ""
          }`}
        >
          <Select
            selectedValues={
              (defaultAutoComplete &&
                messages.find(
                  (message) => message?.id === defaultAutoComplete?.id
                )?.title) ||
              messages.find(
                ({ id, title }) =>
                  id === selectedUserId && title === selectedItem?.title
              )?.title
            }
            headerLabel={label}
            searchValue={searchValue}
            onSearchChange={(value) => {
              setSearchValue(value);
            }}
            onSelectClosed={() => {
              setSearchValue("");
              if (onChange) onChange();
            }}
            layerRootId="story--components-select--async-search"
            placeholder={label}
          >
            {usersToDisplay.map(
              ({
                id,
                title,
                text,
                type,
                index,
                appName,
                appUrl,
                inputParams,
                outputParams,
              }) => (
                <SelectItem
                  key={id}
                  onClick={() =>
                    handleSelectItemClick(
                      id,
                      title,
                      text,
                      type,
                      index,
                      appUrl,
                      appName,
                      inputParams,
                      outputParams
                    )
                  }
                  isSelected={
                    (defaultAutoComplete && id === defaultAutoComplete?.id) ||
                    !!(id === selectedUserId && title === selectedItem?.title)
                  }
                >
                  <div className="remove-button-container">
                    <div
                      className={`remove-button-title ${
                        !!(
                          id === selectedUserId && title === selectedItem?.title
                        )
                          ? "item-is-selected"
                          : ""
                      }`}
                    >
                      {title}
                    </div>
                    {hasDeleteButton && (
                      <Button
                        type="primary-danger"
                        size="small"
                        onClick={(e) => handleDeleteAction(e, { id, title })}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </SelectItem>
              )
            )}
          </Select>
        </div>

        {!!(hasClearButton && selectedUserId) && (
          <div className="clear-search-button">
            <Button type="primary-danger" onClick={handleClearSearch}>
              Clear
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

export default AutoComplete;
