import { useEffect } from "react";
import { Checkbox, Paragraph } from "@frontapp/ui-kit";

function SelectableTable({
  data,
  isColChecked,
  hasApiResult,
  setHasApiResult,
  setIsColChecked,
  selectedRows,
  setSelectedRows,
  autoCompleteValue,
}) {
  const handleCheckbox = (val, item, title) => {
    const isColCheckedTemp = { ...isColChecked };
    isColCheckedTemp[title][item] = val;
    setIsColChecked(isColCheckedTemp);
  };

  useEffect(() => {
    if (data && data.length) {
      data?.map(({ api_result }) => {
        if (api_result?.length) setHasApiResult(true);
      });
    } else {
      setHasApiResult(false);
    }
  }, [data]);

  return (
    <div>
      {hasApiResult ? (
        <table>
          <tr className="thead-tr">
            {!!autoCompleteValue?.outputParams &&
              Object.keys(autoCompleteValue?.outputParams)
                ?.sort(
                  (a, b) =>
                    autoCompleteValue["outputParams"][a]?.order -
                    autoCompleteValue["outputParams"][b]?.order
                )
                ?.map((item, index) => (
                  <th key={index} className="th-height">
                    <Checkbox
                      isChecked={isColChecked[autoCompleteValue.title][item]}
                      onChange={(val) =>
                        handleCheckbox(val, item, autoCompleteValue?.title)
                      }
                    >
                      <Paragraph isBold>
                        {autoCompleteValue.outputParams[item]?.name}
                      </Paragraph>
                    </Checkbox>
                  </th>
                ))}
          </tr>

          {data &&
            data.length &&
            data?.map(({ api_result }, rowIndex) =>
              api_result?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => setSelectedRows(index)}
                  className={selectedRows === index ? "selected-row" : ""}
                >
                  {!!(Object.keys(item) && autoCompleteValue.outputParams) &&
                    Object.keys(item)?.map((key, counter) => (
                      <td key={counter}>
                        {item[
                          Object.keys(autoCompleteValue.outputParams)
                            .sort(
                              (a, b) =>
                                autoCompleteValue["outputParams"][a]?.order -
                                autoCompleteValue["outputParams"][b]?.order
                            )
                            ?.map((item) => item)[counter]
                        ] ||
                          item[
                            autoCompleteValue.outputParams[
                              Object.keys(autoCompleteValue.outputParams)
                                .sort(
                                  (a, b) =>
                                    autoCompleteValue["outputParams"][a]
                                      ?.order -
                                    autoCompleteValue["outputParams"][b]?.order
                                )
                                ?.map((item) => item)[counter]
                            ]?.name
                          ]}
                      </td>
                    ))}
                </tr>
              ))
            )}
        </table>
      ) : (
        <Paragraph>No Answer found.</Paragraph>
      )}
    </div>
  );
}

export default SelectableTable;
