export const END_POINTS = {
  SERVICE_APIS: {
    CHAT: "/service-apis/chat",
    GET_DATE: "/service-apis/get-date",
    INSERT_LOG: "/service-apis/insert-log",
    LAST_DRAFT: "/service-apis/last-draft",
    FRONT_REPLY: "/service-apis/front-reply",
    LAST_MESSAGE: "/service-apis/last-message",
    RESPONSE_SNIPPETS: "/service-apis/response-snippets",
    GET_PLUGIN_SETTINGS: "/service-apis/get-plugin-settings",
    SAVE_PLUGIN_SETTINGS: "/service-apis/save-plugin-settings",
    PLUGIN_USER_PROMPTS: "/service-apis/plugin-user-prompts",
    INTEGRATION_CONFIG_WORKFLOWS: "/service-apis/integration-config-workflows",
    SERVICE_RESULTS: "/service-apis/service-results",
  },
  RESPONSE_ASSIST: {
    RESOURCES: "/response-assist/resources",
    RESPONSE_AGENTS_CONFIG: "/response-assist/response-agents-config",
    API: {
      WORKFLOW: "/api/workflow",
      EMAIL: "/api/response-assist/email",
      QUERY: "/api/response-assist/query",
      CHATBOT: "/api/response-assist/chatbot",
      API_CALL: "/api/response-assist/api-call",
      WEB_SEARCH: "/api/response-assist/web-search",
      EMAIL_AUTO_RESPONSE: "/api/response-assist/email-auto-response",
      CHATBOT_AUTO_RESPONSE: "/api/response-assist/chatbot-auto-response",
    },
  },
  FRONT_APIS: {
    FRONT_COMMENT_TAG: "/front-apis/front-comment-tag",
    FULL_CONVERSATION_FRONT: "/front-apis/full-conversation-front",
  },
  ZENDESK_APIS: {
    ZENDESK_COMMENT: "/zendesk-apis/zendesk-comment",
    FULL_CONVERSATION_ZENDESK: "/zendesk-apis/full-conversation-zendesk",
  },
  THIRD_PARTY_APIS: {
    API_WORKFLOW_CONFIG: "/thirdparty-apis/api-workflows-config",
  },
  EXTRACTOR_APIS: {
    RESULTS: "/extractor-apis/results",
  },
  INTEGRATION_APIS: {
    GET_ATTACHMENTS: "/integration-apis/get-attachments",
    GET_CONVERSATION_INFO: "/integration-apis/get-conversation-info",
    TRANSLATE_MESSAGE: "/integration-apis/translate-message",
    TRANSLATE: "/integration-apis/translate",
  },
  API: {
    SUMMARIZE: "/api/summarize",
  },
};
