import { isZendesk } from "../../../../constants/plugin_variables";
import { front_plugin_url } from "../../constants/variables";

const origin = localStorage.getItem("origin");
const appGuid = localStorage.getItem("app_guid");
const showErrorMessage = (errorCode) => {
  let error = "";
  if (errorCode == "401" || String(errorCode).includes("401")) {
    error = "Unauthorized! Please ensure you are subscribed to UpBrains AI.";
    setTimeout(() => {
      window.location.replace(
        front_plugin_url + `/?app_guid=${appGuid}&origin=${origin}`
      );
      localStorage.clear();
    }, 4000);
  } else if (errorCode == "403" || String(errorCode).includes("403")) {
    error = !isZendesk
      ? "OAuth authentication only allows access to shared resources. Please update your Front token in UpBrains portal and update your personal preferences to allow access to personal resources."
      : `Zendesk resource access permission error. Please turn ON API access from your personal preferences: Zendesk Profile -> Preferences.`;
  } else if (errorCode == "404" || String(errorCode).includes("404")) {
    error = "No Resource found.";
  } else if (errorCode == "409" || String(errorCode).includes("409")) {
    error = "Duplicate resource name. Resource with this name already exists.";
  } else if (
    errorCode == "500" ||
    errorCode == "400" ||
    errorCode === "Failed to fetch" ||
    errorCode === undefined ||
    String(errorCode).includes("500") ||
    String(errorCode).includes("400") ||
    String(errorCode).includes("Failed to Fetch")
  ) {
    error =
      "Something went wrong. Please retry later. If the error persists, please contact support. ";
  } else if (errorCode == "503" || String(errorCode).includes("503")) {
    error =
      "ChatGPT service is busy and response is taking too long. Please try later.";
  } else if (errorCode == "504" || String(errorCode).includes("504")) {
    error =
      "The server took long to respond. Please refresh your page and retry.";
  } else if (errorCode == "204" || String(errorCode).includes("204")) {
    error = "Couldn't find the result";
  }
  return error;
};

export default showErrorMessage;
