export const mockData = {
  context: {
    id: "7e02d5c0-e3d4-439b-853b-b58280d97075",
    functionArities: {
      createWidget: 2,
      destroyWidget: 2,
      sendHttp: 2,
      relayHttp: 2,
      authenticate: 1,
      deauthenticate: 1,
      search: 2,
      listTeammates: 2,
      listInboxes: 2,
      listChannels: 2,
      listTags: 2,
      openUrl: 2,
      openUrlInPopup: 3,
      openConversationInPopup: 2,
      createDraft: 2,
      updateDraft: 3,
      addTopic: 3,
      addLink: 3,
      assign: 2,
      move: 2,
      setStatus: 2,
      tag: 2,
      untag: 2,
      removeLink: 2,
      fetchPath: 1,
      listMessages: 2,
      listComments: 2,
      downloadAttachment: 3,
      fetchDraft: 2,
      listRecipients: 2,
    },
    preferences: {
      type: "open_side_panel",
      endpoint: "https://conversebot.upbrainsai.com/",
      sdkVersion: "1.x",
      features: [],
      origin: "",
    },
    authentication: {},
    teammate: {
      id: "tea_7mcgc",
      name: "UpBrains AI",
      username: "upbrains",
      email: "admin@upbrains.ai",
    },
    type: "singleConversation",
    conversation: {
      id: "cnv_12wze2q4",
      type: "email",
      status: "open",
      subject: "Sending file again",
      blurb: "attached",
      recipient: {
        name: "UpBrains AI",
        handle: "upbrainsai@gmail.com",
        contact: {
          id: "crd_34fs1n0",
          name: "UpBrains AI",
          groupIds: [],
          handles: [
            {
              type: "email",
              handle: "upbrainsai@gmail.com",
            },
          ],
          customAttributes: [],
        },
        type: "email",
      },
      inboxes: [
        {
          id: "inb_99l3g",
          ownerId: "tim_47yh8",
          name: "Demo Inbox",
        },
      ],
      tags: [],
      topics: [],
      links: [],
    },
  },
  front_list_messages: {
    results: [
      {
        id: "msg_1us145ak",
        from: {
          name: "UpBrains AI",
          handle: "upbrainsai@gmail.com",
          contact: {
            id: "crd_34fs1n0",
            name: "UpBrains AI",
            groupIds: [],
            handles: [
              {
                type: "email",
                handle: "upbrainsai@gmail.com",
              },
            ],
            customAttributes: [],
          },
          type: "email",
        },
        to: [
          {
            handle: "9mdjg-cd7b5d03a05ef5b156df@in.frontapp.com",
            type: "email",
          },
        ],
        subject: "reversed files",
        date: "2023-06-22T22:24:28.000Z",
        status: "inbound",
        content: {
          body: "<div dir='ltr'><div class='gmail_quote'><div dir='ltr' class='fa-qphyz8'>Hallo<br /></div><div dir='ltr'><div class='gmail_quote'><div dir='ltr'><br />Kunt u bijgevoegd bankafschrift verwerken?<br /><br />Kunt u mij ook meer informatie geven over uw producten? Wat krijg ik met een abonnement per gebruiker? En hoe verschilt uw service van OpenAI?<br /><br />Bedankt<br /><div class='front-signature'>UpBranins AI</div></div>",
          // body: "<div dir='ltr'><div class='gmail_quote'><div dir='ltr' class='fa-qphyz8'>Hello<br /></div><div dir='ltr'><div><br /></div><div>I have sent my invoice to you last week. However, I do not see any update.</div><div><br /></div><div>Can you please tell me if you received it?</div><div><br /></div><div>How long it takes to process it? Please let me know.</div><div><br /></div><div>Hamid</div></div></div></div>",
          type: "html",
          attachments: [
            {
              id: "fil_33vf0a18",
              name: "Invoice-B12B7B53-0002.pdf",
              contentType: "application/pdf",
              size: 42863,
            },
            {
              id: "fil_33vf09zg",
              name: "Rotated2.pdf",
              contentType: "application/pdf",
              size: 783028,
            },
          ],
        },
      },
      {
        id: "msg_1us1457777ak",
        from: {
          name: "UpBrains AI",
          handle: "upbrainsai@gmail.com",
          contact: {
            id: "crd_34fs1n0",
            name: "UpBrains AI",
            groupIds: [],
            handles: [
              {
                type: "email",
                handle: "upbrainsai@gmail.com",
              },
            ],
            customAttributes: [],
          },
          type: "email",
        },
        to: [
          {
            handle: "9mdjg-cd7b5d03a05ef5b156df@in.frontapp.com",
            type: "email",
          },
        ],
        subject: "reversed files",
        date: "2023-06-22T22:24:28.000Z",
        status: "inbound",
        content: {
          body: "<div dir='ltr'><div class='gmail_quote'><div dir='ltr' class='fa-qphyz8'>Hallo0000<br /></div><div dir='ltr'><div class='gmail_quote'><div dir='ltr'><br />Kunt u bijgevoegd bankafschrift verwerken?<br /><br />Kunt u mij ook meer informatie geven over uw producten? Wat krijg ik met een abonnement per gebruiker? En hoe verschilt uw service van OpenAI?<br /><br />Bedankt<br /><div class='front-signature'>UpBranins AI</div></div></div></div></div></div>",
          // body: "<div dir='ltr'><div class='gmail_quote'><div dir='ltr' class='fa-qphyz8'>Hello<br /></div><div dir='ltr'><div><br /></div><div>I have sent my invoice to you last week. However, I do not see any update.</div><div><br /></div><div>Can you please tell me if you received it?</div><div><br /></div><div>How long it takes to process it? Please let me know.</div><div><br /></div><div>Hamid</div></div></div></div>",
          type: "html",
          attachments: [
            {
              id: "fil_33vf0a18",
              name: "Invoice-B12B7B53-0002.pdf",
              contentType: "application/pdf",
              size: 42863,
            },
            {
              id: "fil_33vf09zg",
              name: "Rotated2.pdf",
              contentType: "application/pdf",
              size: 783028,
            },
          ],
        },
      },
    ],
  },
  front_create_draft: {
    id: "dra:0c0f468fd95ececa35db6a9950593ba8",
    channel: {
      id: "cha_dhix8",
      type: "email",
      inbox: {
        id: "inb_a2s0c",
        ownerId: "tim_47yh8",
        name: "Info@upbrains",
      },
      name: "info@upbrains.ai",
      address: "dhix8-cd7b5d03a05ef5b156df@in.frontapp.com",
    },
    to: [
      {
        handle: "email@server.com",
        contact: {
          id: "crd_3b1zc24",
          name: "Sample Name",
          groupIds: [],
          handles: [
            {
              type: "email",
              handle: "user@mail.com",
            },
          ],
          customAttributes: [],
        },
        type: "email",
      },
    ],
    subject: "Re: Test",
    content: {
      body: "Dear User, Thank you ...",
      type: "html",
      attachments: [],
    },
    isEditable: true,
  },
  front_update_draft: undefined,
  front_fetch_draft: {
    id: "dra:3300f5de1256e8b469f695d60d69d189",
    channel: {
      id: "cha_9mdjg",
      type: "email",
      inbox: {
        id: "inb_99l3g",
        ownerId: "tim_47yh8",
        name: "Demo Inbox",
      },
      name: "9mdjg-cd7b5d03a05ef5b156df@in.frontapp.com",
      address: "9mdjg-cd7b5d03a05ef5b156df@in.frontapp.com",
    },
    to: [
      {
        name: "UpBrains AI",
        handle: "upbrainsai@gmail.com",
        contact: {
          id: "crd_34fs1n0",
          name: "UpBrains AI",
          groupIds: [],
          handles: [
            {
              type: "email",
              handle: "upbrainsai@gmail.com",
            },
          ],
          customAttributes: [],
        },
        type: "email",
      },
    ],
    subject: "Re: Order and supporting documents",
    content: {
      body: "<div>Dear Adam,</div>....</blockquote>",
      type: "html",
      attachments: [
        {
          id: "fil_34ue3cy4",
          name: "",
          contentType: "image/png",
          size: 11050,
          inlineCid: "59de5e77135ae4dc76ab0f995d547284",
        },
      ],
    },
    isEditable: true,
  },
  response_agents_config: {
    results: {
      agents_config: [
        {
          Default: {
            agent_id: 2,
            agent_name: "Default",
            apis_workflows: [
              {
                app_description:
                  "This API returns the status of an order given its order number",
                info: {
                  api_url:
                    "https://docreader.upbrainsai.com/response-assist/rest/orders/{order_id} ",
                  app_type: "API",
                  authorization_token:
                    "qoP4o-gqyx9tUO4z8TJ4IShIHHkLfJvsMB97yEeRsX1L73ru1FL6T8koDO8C",
                  method: "GET",
                  output_type: "Record",
                  team_id: 2,
                  token_inclusion: "Header",
                  token_type: "",
                },
                input_parameters: {
                  order_id: {
                    description: " ",
                    type: "string",
                  },
                },
                name: "Order Status API",
                output_parameters: {
                  statusName: {
                    description: "Status",
                    type: "string",
                  },
                },
                required: ["order_id"],
              },
              {
                app_description:
                  "This workflow searches MYP provider invoices information for a given contact or provider.",
                info: {
                  api_url: "http://108.59.86.136:8000/api/workflow",
                  app_type: "Workflow",
                  authorization_token:
                    "qoP4o-UdLyDBj3SDHxPh6mxvBduwUqz4VNgNkdcAw4sM1KQUmTPCeFvGEzBB",
                  method: "POST",
                  output_type: "List",
                  team_id: 2,
                  token_inclusion: "Header",
                  token_type: "Bearer",
                },
                input_parameters: {
                  "FirstName\u060cLastName\u060cProviderName\u060cProviderInvoiceReference\u060ctop":
                    {
                      description:
                        "First Name\u060cLast Name\u060cProvider Name\u060cProvider Invoice Reference\u060cNo of returned results",
                      type: "string",
                    },
                },
                name: "MYP Invoice Search",
                output_parameters: {
                  "CreationDate\u060cInvoiceTotal\u060cApprovalStatus\u060cRejectionReason\u060cProviderInvoiceReference\u060cProviderInvoiceGuid":
                    {
                      description:
                        "Date\u060cTotal\u060cStatus\u060cNote\u060cPI Reference\u060cPI GUID",
                      type: "string",
                    },
                },
                required: [],
              },
            ],
            body_author_email: "mlengms@upbrains.ai",
            folders: ["Default"],
            full_conversation: "False",
            instruction: "",
            is_deployed: "True",
            max_token: "500",
            resources: [
              {
                auto_refresh: false,
                category: "Default1",
                created_on: "Mon, 01 Jan 2024 14:45:02 GMT",
                id: 1,
                source: "URL",
                source_id:
                  "store.waveformlighting.com/pages/frequently-asked-questions",
                source_name:
                  "store.waveformlighting.com/pages/frequently-asked-questions",
                status: "Processed",
                uploaded_id: "8db73816-c05d-4569-8727-367417ae4a0d",
              },
              {
                auto_refresh: false,
                category: "Default2",
                created_on: "Mon, 01 Jan 2024 14:50:41 GMT",
                id: 2,
                source: "URL",
                source_id: "www.ontrack.com/en-ie/faqs",
                source_name: "www.ontrack.com/en-ie/faqs",
                status: "Processed",
                uploaded_id: "a5c6e06f-c5e7-4af9-af2b-d03f99bd0eb5",
              },
              {
                auto_refresh: false,
                category: "Default3",
                created_on: "Thu, 04 Jan 2024 14:19:47 GMT",
                id: 3,
                source: "template",
                source_id: "Response Template",
                source_name: "Response Template",
                status: "Processed",
                uploaded_id: null,
              },
            ],
            response_mode: "Plugin",
            sender_name: "",
            tone: "Professional",
          },
        },
      ],
    },
    status: 200,
  },
  response_assist_result: {
    user_resources: [
      {
        auto_refresh: false,
        category: "Default",
        created_on: "Mon, 01 Jan 2024 14:45:02 GMT",
        id: 1,
        source: "URL",
        source_id:
          "store.waveformlighting.com/pages/frequently-asked-questions",
        source_name:
          "store.waveformlighting.com/pages/frequently-asked-questions",
        status: "Processed",
        uploaded_id: "8db73816-c05d-4569-8727-367417ae4a0d",
      },
      {
        auto_refresh: false,
        category: "Default",
        created_on: "Mon, 01 Jan 2024 14:50:41 GMT",
        id: 2,
        source: "URL",
        source_id: "www.ontrack.com/en-ie/faqs",
        source_name: "www.ontrack.com/en-ie/faqs",
        status: "Processed",
        uploaded_id: "a5c6e06f-c5e7-4af9-af2b-d03f99bd0eb5",
      },
      {
        auto_refresh: false,
        category: "Default",
        created_on: "Thu, 04 Jan 2024 14:19:47 GMT",
        id: 3,
        source: "template",
        source_id: "Response Template",
        source_name: "Response Template",
        status: "Processed",
        uploaded_id: null,
      },
    ],
    status: 200,
  },
};
