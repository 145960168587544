import { END_POINTS } from "../../constants/endpoints";
import {
  AnyDoc_service_name,
  OCR_service_name,
  Prebuilt_service_name,
  ignite_url,
  xtractUrl,
} from "../../constants/variables";
import { logoutUser } from "../../utils/functions/logoutUser";

export const getAttachmentExtractedResult = async (
  extractorId,
  attachment,
  headers,
  integrationName
) => {
  try {
    const formData = new FormData();
    formData.append("integration_name", integrationName);
    formData.append("attachment", JSON.stringify(attachment));
    formData.append("extractor_id", extractorId);

    const requestOptions = {
      method: "POST",
      headers,
      body: formData,
      redirect: "follow",
    };

    const result = await fetch(
      `${xtractUrl}/integration-apis/extract-file`,
      requestOptions
    );
    if (result?.status !== 200) {
      throw new Error(
        "Failed to extract any information using this extractor. Please try again or choose another extractor."
      );
    } else {
      const response = await result.json();
      return response;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const getExtractorsBriefly = async (headers) => {
  try {
    let service_names =
      OCR_service_name +
      "," +
      Prebuilt_service_name +
      "," +
      AnyDoc_service_name;
    const url =
      ignite_url + `/extractor-apis/v2/ai-extractors?only_published=true`;
    // `/extractor-apis/v2/ai-models?system_models=true&brief=true&service_name=${Prebuilt_service_name}`;
    //  +
    // `&service_name=${service_names}`;

    const requestOptions = {
      method: "GET",
      headers,
      redirect: "follow",
    };

    const result = await fetch(url, requestOptions);

    ///LOGOUT USER
    if (!result?.ok && result?.status === 401) logoutUser();

    const response = await result.json();
    return response.results;
  } catch (error) {}
};

export const postExtractedResult = async (requestOptions) => {
  try {
    const url = ignite_url + "/service-apis/service-results";
    const result = await fetch(url, requestOptions);

    ///LOGOUT USER
    if (!result?.ok && result?.status === 401) logoutUser();

    const response = await result.json();
    if (result.status === 200 || result.status === 204) {
      return response;
    } else {
      return Promise.reject(response);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
