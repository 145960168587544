import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

const decryptData = (name) => {
  try {
    const encrypted = localStorage.getItem(name);

    if (!encrypted) throw new Error(`${name} It's not in the localStorage`);

    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  } catch (e) {
    return null;
  }
};

export default decryptData;
