import { useMemo } from "react";
import useSWR from "swr";
import { usePluginContext } from "../../../../providers/contextManagement";
import { ignite_url } from "../../constants/variables";
import usePrimitiveFunctions from "../../services/server/usePrimitiveFunctions";
import useListOfMessages from "../../../../utils/hooks/useListOfMessages";
import { logoutUser } from "../functions/logoutUser";

const useAttachmentsSWR = (
  attachmentType,
  isZendesk,
  UpbrainsToken,
  TeamEmail,
  key
) => {
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));
  const { checkAuth } = usePrimitiveFunctions(UpbrainsToken, TeamEmail);
  const { getListOfMessages } = useListOfMessages(UpbrainsToken, TeamEmail);

  const getAttachments = async () => {
    try {
      const conversationId = context?.conversation?.id;
      const { lastMsgId } = await getListOfMessages();

      if (!lastMsgId && !conversationId) {
        throw new Error("Please check your selected conversation or message.");
      }

      const body =
        attachmentType === "ALL"
          ? JSON.stringify({
              integration_name: isZendesk ? "Zendesk" : "Front",
              conversation_or_ticket_id: conversationId,
            })
          : JSON.stringify({
              integration_name: isZendesk ? "Zendesk" : "Front",
              // Message_or_Comment_ID: lastMsgId,
              conversation_or_ticket_id: conversationId,
            });

      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body,
        redirect: "follow",
      };

      const result = await fetch(
        ignite_url + "/integration-apis/get-attachments",
        requestOptions
      );

      if (!result?.ok && result?.status === 401) logoutUser();

      const response = await result.json();

      if (response?.status === 200) {
        const attachments = response?.attachments.filter(
          (attachment) => attachment?.name?.length
        );
        return attachments;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw error;
    }
  };

  const swrKey = useMemo(
    () => [
      "/attachments",
      context?.conversation?.id,
      localStorage.getItem("last_message_id"),
    ],
    [context, localStorage.getItem("last_message_id")]
  );

  if (key && context?.conversation?.id) swrKey.push(key);

  const {
    data: attachments,
    error: getAttachmentsError,
    isLoading: getAttachmentsLoading,
    mutate: getAttachmentsMutate,
  } = useSWR(swrKey, getAttachments, {
    revalidateOnFocus: false,
    revalidateIfStale: true,
  });

  return {
    attachments,
    getAttachmentsError,
    getAttachmentsLoading,
    getAttachmentsMutate,
  };
};

export default useAttachmentsSWR;
