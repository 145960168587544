import { END_POINTS } from "../../constants/endpoints";
import { ignite_url } from "../../constants/variables";

export const updateExtractor = async (requestOptions) => {
  const res = await fetch(
    ignite_url + END_POINTS.EXTRACTOR_APIS.RESULTS,
    requestOptions
  )
    .then((res) => {
      if (!res.ok || res.status === 204) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};
