import React, { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import PdfSvg from "../assets/images/pdf.svg";
import JpgSvg from "../assets/images/jpg.svg";
import { Button } from "@frontapp/ui-kit";
import { BeatLoader } from "react-spinners";
const Attachments = ({
  attachments,
  handleSelectAttachment,
  openModal,
  isLoading,
  defaultExpanded,
  attachmentType,
  handleSelectAttachmentsType,
}) => {
  const _attachments = useMemo(
    () => (attachments?.length > 3 ? attachments.slice(0, 3) : attachments),
    [attachments]
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (attachments?.length) {
      setIsExpanded(true);
    }
  }, [attachments]);
  return (
    <div className="attachments-container">
      <Accordion className="attachment-1" expanded={isExpanded}>
        <AccordionSummary
          className="attachment-2"
          expandIcon={
            <FontAwesomeIcon
              color="#000"
              fontSize={16}
              fill="#000"
              icon={faChevronDown}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
          onClick={handleToggleAccordion}
        >
          <div className="attachment-3">Attachments</div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="attachment-4">
              <Button
                onClick={() => handleSelectAttachmentsType("MESSAGE")}
                type={attachmentType === "MESSAGE" ? "primary" : "secondary"}
              >
                Last Message
              </Button>
              <Button
                onClick={() => handleSelectAttachmentsType("ALL")}
                type={attachmentType === "ALL" ? "primary" : "secondary"}
              >
                All
              </Button>
            </div>
            {isLoading ? (
              <div className="attachment-5">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="attachments">
                {attachments?.length && _attachments?.length ? (
                  <>
                    {_attachments?.map((attachment) => {
                      return (
                        <div
                          key={attachment.id}
                          className="attachment"
                          onClick={() => handleSelectAttachment(attachment)}
                        >
                          <div className="attachment-img">
                            <img
                              src={
                                attachment.content_type === "text/plain"
                                  ? PdfSvg
                                  : JpgSvg
                              }
                              alt="document"
                            />
                          </div>
                          <div className="attachment-title">
                            {attachment.name}
                          </div>
                        </div>
                      );
                    })}
                    <Button type="tertiary" onClick={openModal}>
                      See all({attachments.length})
                    </Button>
                  </>
                ) : (
                  <div className="custom-form-label">No attachments</div>
                )}
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Attachments;
