import { useState } from "react";

import { END_POINTS } from "../../constants/endpoints";
import { usePluginContext } from "../../../../providers/contextManagement";
import {
  ERRORTYPE,
  JSERRORCODE,
  plugin_service_name,
  ignite_url,
  front_plugin_url,
} from "../../constants/variables";
import decryptData from "../../utils/functions/decryptData";

const usePrimitiveFunctions = (upbrainsToken, teamEmail) => {
  const [userIdentifier, setUserIdentifier] = useState("");
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  /**
   * send error message to the ignite server
   *
   * @param {string} log_text
   * @param {string} error_type
   * @param {number} error_code
   * @param {string} service_name
   * @returns
   */
  const postLog = async (log_text, error_type, error_code, service_name) => {
    try {
      const payload = JSON.stringify({
        log_text,
        error_type: error_type || ERRORTYPE,
        error_code: error_code || JSERRORCODE,
        service_name: service_name || plugin_service_name,
        source_identifier: context?.teammate?.email,
      });

      var requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: payload,
        redirect: "follow",
      };

      await fetch(
        ignite_url + END_POINTS.SERVICE_APIS.INSERT_LOG,
        requestOptions
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * set identifier and token
   *
   * @returns
   */
  const setIdentifierAndToken = async () => {
    try {
      if (decryptData("UpbrainsToken")) {
        return { identifier: "", Token: decryptData("UpbrainsToken") };
      } else {
        const origin = localStorage.getItem("origin");
        const appGuid = localStorage.getItem("app_guid");

        localStorage.setItem("unauthorized", "true");
        setTimeout(() => {
          window.location.replace(
            front_plugin_url + `/?app_guid=${appGuid}&origin=${origin}`
          );
          localStorage.clear();
        }, 4000);
        return { identifier: "", Token: "" };
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * check the authorization of user
   *
   * @returns
   */
  const checkAuth = async () => {
    const myHeaders = new Headers();
    const Authorization_credentials = await setIdentifierAndToken();
    const identifier = Authorization_credentials.identifier;
    const Authorization = Authorization_credentials.Token;
    myHeaders.append("Authorization", Authorization);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("identifier", identifier);
    setUserIdentifier(identifier);

    return Promise.resolve(myHeaders);
  };

  const checkAuthForFormData = async () => {
    const myHeaders = new Headers();
    const Authorization_credentials = await setIdentifierAndToken();
    const identifier = Authorization_credentials.identifier;
    const Authorization = Authorization_credentials.Token;
    myHeaders.append("Authorization", Authorization);
    myHeaders.append("identifier", identifier);
    setUserIdentifier(identifier);

    return Promise.resolve(myHeaders);
  };

  return {
    userIdentifier,
    postLog,
    checkAuth,
    checkAuthForFormData,
    setIdentifierAndToken,
  };
};

export default usePrimitiveFunctions;
