import React from "react";
import AIGeneratedResponseAssist from "./AIGeneratedResponseAssist";
import ManualResponseAssist from "./ManualResponseAssist";

const ResponseAssistConfiguration = ({
  selectedFeature,
  isManual,
  handleChangeTextArea,
  AIGeneratedResponse,
  handleAskResources,
  resetEveryThing,
  autoCompleteValue,
  setAutocompleteValue,
  handleAutoCompleteChange,
  handleResponseAssistTextChange,
  searchInputTerm,
  isEngine,
  urlInput,
  isWorkflowLoading,
  langParams,
  workflowObj,
  responseAssistResRaw,
  handleUrlInput,
  handleLastMessage,
  handleTypeaheadChange,
  handleResponseAssistInputChange,
  resources,
  isSnippets,
  isColChecked,
  hasApiResult,
  selectedRows,
  clearFirstWord,
  setIsColChecked,
  setSelectedRows,
  setHasApiResult,
  label,
}) => {
  return (
    <div>
      {isManual && (
        <ManualResponseAssist
          selectedFeature={selectedFeature}
          resetEveryThing={resetEveryThing}
          resourcesList={resources}
          handleAskResources={handleAskResources}
          autoCompleteValue={autoCompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleResponseAssistTextChange={handleResponseAssistTextChange}
          searchInputTerm={searchInputTerm}
          isEngine={isEngine}
          urlInput={urlInput}
          isWorkflowLoading={isWorkflowLoading}
          langParams={langParams}
          workflowObj={workflowObj}
          responseAssistResRaw={responseAssistResRaw}
          handleUrlInput={handleUrlInput}
          handleLastMessage={handleLastMessage}
          handleTypeaheadChange={handleTypeaheadChange}
          handleResponseAssistInputChange={handleResponseAssistInputChange}
          label={label}
        />
      )}

      {!!AIGeneratedResponse?.length && (
        <AIGeneratedResponseAssist
          generatedResponse={AIGeneratedResponse}
          handleChangeTextArea={handleChangeTextArea}
          isSnippets={isSnippets}
          autoCompleteValue={autoCompleteValue}
          isColChecked={isColChecked}
          hasApiResult={hasApiResult}
          selectedRows={selectedRows}
          clearFirstWord={clearFirstWord}
          setIsColChecked={setIsColChecked}
          setSelectedRows={setSelectedRows}
          setHasApiResult={setHasApiResult}
        />
      )}
    </div>
  );
};

export default ResponseAssistConfiguration;
