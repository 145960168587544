import React, { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  InlineBanner,
  PluginLayout,
  VisualSizesEnum,
} from "@frontapp/ui-kit";
import { Paragraph, Button } from "@frontapp/ui-kit";
import {
  date_token,
  ignite_url,
  front_plugin_url,
  Third_party_app_name,
  Converse_service_name,
  integration_name,
} from "../constants/variables.js";
import { usePluginContext } from "../../../providers/contextManagement.js";
import sha256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";
import * as DOMPurify from "dompurify";
import * as EmailValidator from "email-validator";
import usePrimitiveFunctions from "../services/server/usePrimitiveFunctions.js";
import decryptData from "../utils/functions/decryptData.js";
import AutoComplete from "./common/AutoComplete.js";
import { END_POINTS } from "../constants/endpoints.js";
import useChat from "../utils/hooks/useChat.js";

const Settings = () => {
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));
  const { features } = useChat();

  const [TeamEmail, setTeamEmail] = useState("");
  const [UpbrainsToken, setUpbrainsToken] = useState("");
  const [ShowVerificationBox, setShowVerificationBox] = useState("");
  const [AsanaPat, setAsanaPat] = useState("");
  const [EmailVerified, setEmailVerified] = useState("");
  const [UpbrainsTokenPlaceholder, setUpbrainsTokenPlaceholder] = useState(
    "Your UpBrains API Token"
  );
  const [DisplayText, setDisplayText] = useState("");
  const [DisplayType, setDisplayType] = useState("");
  const [Initializer, setInitializer] = useState(true);
  const [show401Banner, setShow401Banner] = useState(false);
  const [isLanguageChecked, setIsLanguageChecked] = useState(
    JSON.parse(localStorage.getItem("isLanguageChecked"))
  );
  const [isGoogleChecked, setIsGoogleChecked] = useState(
    JSON.parse(localStorage.getItem("isGoogleChecked"))
  );
  const [agentAutoComplete, setAgentAutoComplete] = useState(
    localStorage.getItem("selected_agent") &&
      Object.keys(JSON.parse(localStorage.getItem("selected_agent"))).length
      ? JSON.parse(localStorage.getItem("selected_agent"))
      : {}
  );
  const [selectedFeatureId, setSelectedFeatureId] = useState(
    localStorage.getItem("selected_feature_id")
      ? features.find(
          (feature) =>
            feature.id === localStorage.getItem("selected_feature_id")
        )?.id
      : "draftAResponse"
  );
  const [jsonConfigSettings, setJsonConfigSettings] = useState({});
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  let StorageEmail = "";
  let StorageToken = "";
  let StorageAsanaPat = "";
  let StorageEmailVerified = "";
  let StorageShowVerificationBox = "";

  if (decryptData("UpbrainsToken") && !StorageToken) {
    StorageToken = decryptData("UpbrainsToken");
  }

  if (decryptData("TeamEmail") && !StorageEmail) {
    StorageEmail = decryptData("TeamEmail");
  }

  if (localStorage.getItem("EmailVerified") && !StorageEmailVerified) {
    StorageEmailVerified = JSON.parse(localStorage.getItem("EmailVerified"));
  }

  if (
    localStorage.getItem("ShowVerificationBox") &&
    !StorageShowVerificationBox
  ) {
    StorageShowVerificationBox = JSON.parse(
      localStorage.getItem("ShowVerificationBox")
    );
  }

  if (localStorage.getItem("AsanaPat") && !StorageAsanaPat) {
    StorageAsanaPat = JSON.parse(localStorage.getItem("AsanaPat"));
  }

  if (StorageEmail && !TeamEmail) {
    setTeamEmail(StorageEmail);
  }

  if (StorageToken && !UpbrainsToken) {
    if (StorageToken.length > 12) {
      let token_ten = StorageToken.slice(0, 11);
      let mask = "*".repeat(StorageToken.slice(12).length);
      setUpbrainsTokenPlaceholder(token_ten + mask);
    } else {
      setUpbrainsTokenPlaceholder(StorageToken);
    }
    setUpbrainsToken(StorageToken);
  }

  if (StorageEmailVerified && !EmailVerified) {
    setEmailVerified(StorageEmailVerified);
  }

  if (StorageShowVerificationBox && !ShowVerificationBox) {
    setShowVerificationBox(StorageShowVerificationBox);
  }

  if (StorageAsanaPat && !AsanaPat) {
    setAsanaPat(StorageAsanaPat);
  }
  const origin = localStorage.getItem("origin");
  const appGuid = localStorage.getItem("app_guid");
  if (
    ((!TeamEmail && !UpbrainsToken) ||
      ((TeamEmail || UpbrainsToken) && !ShowVerificationBox)) &&
    Initializer
  ) {
    get_credentials(true);
    setInitializer(false);
  }

  if (DisplayText) {
    setTimeout(() => {
      setDisplayText("");
    }, "60000");
  }

  const { postLog, checkAuth, userIdentifier } = usePrimitiveFunctions(
    StorageToken,
    StorageEmail
  );

  const get_services = async () => {
    try {
      var myHeaders = new Headers();
      const Authorization_credentials = await setIdentifier_and_token();
      const identifier = Authorization_credentials.identifier;
      const Authorization = Authorization_credentials.Token;
      myHeaders.append("Authorization", Authorization);
      myHeaders.append("identifier", identifier);
      myHeaders.append("Content-Type", "application/json");

      if (
        (identifier || Authorization) &&
        (identifier != "" || Authorization != "")
      ) {
        var raw = JSON.stringify({});
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        let result = await fetch(
          ignite_url + "/service-apis/subscription-check",
          requestOptions
        );
        let results = await result.json();

        if (result["status"] == 200) {
          var arr = [];
          for (var i = 0; i < results.user_subscriptions.length; i++) {
            arr.push(results.user_subscriptions[i].service_name);
          }
          return arr;
        } else return null;
      } else return null;
    } catch (e) {
      postLog(`Failed to post subscription check - get_services(): ${e}`);
    }
  };

  async function setIdentifier_and_token() {
    if (UpbrainsToken) {
      return { identifier: "", Token: UpbrainsToken };
    } else if (TeamEmail) {
      const key_var = String(process.env.REACT_APP_SYSTEM_KEY);
      let sysDate = await get_date();
      const syst = String(sha256(sysDate + TeamEmail + key_var)).toString(
        CryptoJS.enc.Hex
      );

      if (EmailValidator.validate(String(TeamEmail))) {
        return { identifier: TeamEmail, Token: syst };
      } else {
        setDisplayType("Error");
        setDisplayText("Pleases enter a valid Team Admin email.");
      }
    } else {
      return { identifier: "", Token: "" };
    }
  }

  function non200_error_alerts(status, f0f_error_msg, error_code) {
    setDisplayType("Error");
    if (status == 401) {
      setDisplayText(
        "The provided token is not a correct Uprains API Access Token or you do not have a valid Front Plugin subscription."
      );
    } else if (status == 403 || status == 404 || status == 410) {
      setDisplayText(f0f_error_msg);
    } else {
      // 400 or 500 error codes
      setDisplayText(
        "Something went wrong. Please try again. If the error persists, please contact Support."
      );
    }
  }

  async function get_pat() {
    try {
      var myHeaders = new Headers();
      const Authorization_credentials = await setIdentifier_and_token();
      const identifier = Authorization_credentials.identifier;
      const Authorization = Authorization_credentials.Token;
      myHeaders.append("Authorization", Authorization);
      myHeaders.append("identifier", identifier);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        app_name: Third_party_app_name,
        service_name: Converse_service_name,
        identifier: identifier,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      let result = await fetch(
        ignite_url + "/service-apis/get-third-party-app-credentials",
        requestOptions
      );
      let ThirdPartyResult = await result.json();

      if (ThirdPartyResult.result) {
        if (ThirdPartyResult.result.app_token) {
          setAsanaPat(ThirdPartyResult.result.app_token);
          localStorage.setItem(
            "AsanaPat",
            JSON.stringify(ThirdPartyResult.result.app_token)
          );
        } else {
          setAsanaPat("");
          localStorage.setItem("AsanaPat", JSON.stringify(""));
        }
      }
    } catch (e) {
      postLog(`Failed to post third party app credentials - get_pat(): ${e}`);
    }
  }

  function redirect(landing = false) {
    let help = "redirect to page.js";

    if (landing) {
      window.location.replace(
        `${front_plugin_url}?app_guid=${appGuid}&origin=${origin}`
      );
      localStorage.clear();
      return;
    }
    let default_tab = "";
    localStorage.getItem("DefaultTab")
      ? (default_tab = localStorage.getItem("DefaultTab"))
      : (default_tab = "Chat");
    if (default_tab == "Chat")
      window.location.replace(
        front_plugin_url + `/chat/?app_guid=${appGuid}&origin=${origin}`
      );
    else if (default_tab == "XtractConverse")
      window.location.replace(
        front_plugin_url + `/results/?app_guid=${appGuid}&origin=${origin}`
      );
    else
      window.location.replace(
        front_plugin_url + `/chat/?app_guid=${appGuid}&origin=${origin}`
      );
  }

  async function get_date() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", date_token);
    var formdata = new FormData();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    const response = await fetch(
      ignite_url + "/service-apis/get-date",
      requestOptions
    );
    const data = await response.json();
    if (data.time) {
      return data.time;
    }
  }

  async function get_credentials(initialize = false) {
    try {
      if (context) {
        var myHeaders = new Headers();
        const Authorization_credentials = await setIdentifier_and_token();
        const identifier = Authorization_credentials.identifier;
        const Authorization = Authorization_credentials.Token;

        if (identifier == "" && Authorization == "") {
          return;
        }

        myHeaders.append("Authorization", Authorization);
        myHeaders.append("identifier", identifier);
        myHeaders.append("Content-Type", "application/json");

        if (TeamEmail) {
          if (!EmailValidator.validate(String(TeamEmail))) {
            setDisplayType("Error");
            setDisplayText("Pleases enter a valid email.");
            return null;
          }
        }

        var raw = JSON.stringify({
          plugin_integration: integration_name,
          // "plugin_service_name": Converse_service_name,
          plugin_username: context?.teammate?.username,
          plugin_user_email: context?.teammate?.email,
          plugin_api_token: UpbrainsToken,
          identifier: identifier,
          plugin_team_email: TeamEmail,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let result = await fetch(
          ignite_url + "/service-apis/get-plugin-settings",
          requestOptions
        );
        let get_result = await result.json();

        if (get_result.status != 200) {
          setShowVerificationBox("true");
          localStorage.setItem("ShowVerificationBox", "true");
          setEmailVerified("");
          localStorage.setItem("EmailVerified", "");
          non200_error_alerts(get_result.status, get_result.error, "x0002s02");
          return null;
        }

        if (get_result.result) {
          if (initialize) {
            if (get_result.result.plugin_api_token) {
              setUpbrainsTokenPlaceholder(get_result.result.plugin_api_token);
              setUpbrainsToken(get_result.result.plugin_api_token);
              localStorage.setItem(
                "UpbrainsToken",
                JSON.stringify(get_result.result.plugin_api_token)
              );
            }
            if (get_result.result.plugin_team_email) {
              setTeamEmail(get_result.result.plugin_team_email);
              localStorage.setItem(
                "TeamEmail",
                JSON.stringify(get_result.result.plugin_team_email)
              );
            }

            if (get_result.result.email_verified == true) {
              localStorage.setItem("EmailVerified", JSON.stringify(true));
              setDisplayText("Account and subscription is verified.");
              setDisplayType("Success");
              setEmailVerified("true");
              setShowVerificationBox("");
              localStorage.setItem("ShowVerificationBox", "");
              get_pat();
              setDisplayText(
                "Account is verified. Loaded credentials successfully."
              );
              setDisplayType("Success");
              return;
            } else if (get_result.result.email_verified == false) {
              setEmailVerified("");
              localStorage.setItem("EmailVerified", "");
              setShowVerificationBox("true");
              localStorage.setItem("ShowVerificationBox", "true");
              setDisplayText("Account is not verified.");
              setDisplayType("Error");
              return;
            }

            if (
              get_result?.result?.json_config &&
              Object.keys(get_result.result.json_config)?.length
            ) {
              setJsonConfigSettings(get_result.result.json_config);
            }
          } else {
            if (get_result.result.email_verified == true) {
              localStorage.setItem("EmailVerified", JSON.parse(true));
              setDisplayText("Account and subscription is verified.");
              setDisplayType("Success");
              setEmailVerified("true");
              setShowVerificationBox("");
              localStorage.setItem("ShowVerificationBox", "");
              get_pat();
              setDisplayText(
                "Account is verified. Loaded credentials successfully."
              );
              setDisplayType("Success");
              return;
            } else if (get_result.result.email_verified == false) {
              setEmailVerified("");
              localStorage.setItem("EmailVerified", "");
              setShowVerificationBox("true");
              localStorage.setItem("ShowVerificationBox", "true");
              setDisplayText("Account is not verified.");
              setDisplayType("Error");
              return;
            }
          }
          setDisplayText("Loaded saved credentials, if any.");
          setDisplayType("Success");
        } // if get_result.results
      } // context
    } catch (e) {
      postLog(`Failed to post plugin settings - get_credentials(): ${e}`);
    }
  } //end of get credentials

  useEffect(() => {
    if (EmailVerified) {
      let StorageChatService = "";
      let isChatPresent = false;

      get_services()
        .then((services) => {
          if (services) {
            for (var i = 0; i < services.length; i++) {
              if (
                services[i].includes("Chat") ||
                services[i].includes("FrontApp-Plugin")
              ) {
                isChatPresent = true;
              }
            }

            if (services && isChatPresent) {
              localStorage.setItem("ChatService", JSON.stringify("Yes"));
            } else {
              localStorage.setItem("ChatService", JSON.stringify("No"));
            }
          }
        })
        .catch((err) => {
          postLog(`Failed to get services - get_services(): ${err}`);
        });
    }
  }, [EmailVerified]);

  const handleLanguageChange = (value) => {
    setIsBtnDisabled(false);
    setIsLanguageChecked(value);
    localStorage.setItem("isLanguageChecked", JSON.stringify(value));
  };

  const handleGoogleChange = (value) => {
    setIsBtnDisabled(false);
    setIsGoogleChecked(value);
    localStorage.setItem("isGoogleChecked", JSON.stringify(value));
  };

  const setPluginSettings = useCallback(async () => {
    try {
      const body = JSON.stringify({
        plugin_integration: integration_name,
        // "plugin_service_name": Converse_service_name,
        plugin_username: context?.teammate?.username,
        plugin_user_email: context?.teammate?.email,
        plugin_api_token: UpbrainsToken || StorageToken,
        identifier: userIdentifier,
        plugin_team_email: TeamEmail || StorageEmail,
        json_config: {
          selected_agent: JSON.parse(localStorage.getItem("selected_agent")),
          isLanguageChecked: isLanguageChecked,
          isGoogleChecked: isGoogleChecked,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body,
        redirect: "follow",
      };

      const { status, result } = await fetch(
        ignite_url + END_POINTS.SERVICE_APIS.SAVE_PLUGIN_SETTINGS,
        requestOptions
      );

      if (status !== 200) throw new Error(status);
      setIsBtnDisabled(true);
      // }
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const handleAgentChange = () => {
    setIsBtnDisabled(false);
  };

  useEffect(() => {
    if (Object.keys(agentAutoComplete)?.length) {
      localStorage.setItem("selected_agent", JSON.stringify(agentAutoComplete));
      localStorage.setItem(
        "agent_name",
        JSON.stringify(agentAutoComplete.text)
      );
    }
  }, [agentAutoComplete]);

  useEffect(() => {
    if (jsonConfigSettings && Object.keys(jsonConfigSettings)?.length) {
      if (jsonConfigSettings.selected_agent) {
        setAgentAutoComplete(jsonConfigSettings.selected_agent);
        localStorage.setItem(
          "selected_agent",
          JSON.stringify(jsonConfigSettings.selected_agent)
        );
      }
      if (jsonConfigSettings.isLanguageChecked) {
        setIsLanguageChecked(jsonConfigSettings.isLanguageChecked);
        localStorage.setItem(
          "isLanguageChecked",
          JSON.stringify(jsonConfigSettings.isLanguageChecked)
        );
      }
      if (jsonConfigSettings.isGoogleChecked) {
        setIsGoogleChecked(jsonConfigSettings.isGoogleChecked);
        localStorage.setItem(
          "isGoogleChecked",
          JSON.stringify(jsonConfigSettings.isGoogleChecked)
        );
      }
    }
  }, [jsonConfigSettings]);

  const handleSelectDefaultAction = (action) => {
    setSelectedFeatureId(action?.id);
    localStorage.setItem("selected_feature_id", action?.id);
  };

  useEffect(() => {
    if (localStorage.getItem("unauthorized") === "true") {
      setShow401Banner(true);
    } else {
      setShow401Banner(false);
    }
  }, [localStorage.getItem("unauthorized")]);

  return (
    <PluginLayout>
      <div className="header">
        <h1>Upbrains AI</h1>
      </div>
      <div className="mainflex">
        <div className="flex2">
          <Paragraph>
            <b>Welcome to UpBrains AI Front Plugin.</b>{" "}
          </Paragraph>
          <br />
          {!EmailVerified && !UpbrainsToken ? (
            <>
              <Paragraph>
                <p>
                  Follow the following 3 steps to get access and login with
                  Single-Click Login option:
                </p>
                <p>
                  {" "}
                  1-{" "}
                  <a href="https://portal.upbrainsai.com/" target="_blank">
                    Subscribe for a 7-Day Free Trial
                  </a>
                  .
                </p>
                <p>
                  {" "}
                  2- Add yourself and your team to the authorized Plugin users
                  by visiting{" "}
                  <a
                    href="https://portal.upbrainsai.com/settings/Front_integration?service=plugin"
                    target="_blank"
                  >
                    UpBrains Plugin Settings
                  </a>
                  .
                </p>
                <p>
                  {" "}
                  3- Once added, please visit{" "}
                  <a className="returnLink" onClick={() => redirect(true)}>
                    Plugin Homepage
                  </a>
                  and click on Login link.
                </p>
                <p>Welcome Aboard!</p>
              </Paragraph>
            </>
          ) : (
            <></>
          )}

          {DisplayText && DisplayType == "Error" ? (
            <Paragraph color="red">{DisplayText}</Paragraph>
          ) : DisplayText && DisplayType == "Success" ? (
            <Paragraph color="green">{DisplayText}</Paragraph>
          ) : (
            <></>
          )}
          <br />

          {!!(UpbrainsToken && EmailVerified) && (
            <>
              <>
                <Paragraph isBold>Default action in Response Assist:</Paragraph>
                <div className="margin-top">
                  <AutoComplete
                    data={features.filter((feature) => !feature.isDisabled)}
                    hasClearButton={false}
                    label="Select one of the features"
                    defaultAutoComplete={features.find(
                      (feature) => feature.id === selectedFeatureId
                    )}
                    setAutocompleteValue={handleSelectDefaultAction}
                  />
                </div>
                <br />
              </>
              {!!(
                localStorage.getItem("agents_list") &&
                JSON.parse(localStorage.getItem("agents_list")).length
              ) && (
                <>
                  <Paragraph isBold>Plugin Response Assist Agent:</Paragraph>
                  <div className="margin-top">
                    <AutoComplete
                      data={JSON.parse(localStorage.getItem("agents_list"))}
                      hasClearButton={false}
                      label="Select one of the agents"
                      defaultAutoComplete={agentAutoComplete}
                      setAutocompleteValue={setAgentAutoComplete}
                      onChange={handleAgentChange}
                    />
                  </div>
                  <div className="margin-top">
                    <Paragraph>
                      Resources available to this agent will be used in the
                      plugin.
                    </Paragraph>
                  </div>
                  <br />
                </>
              )}
              <Checkbox
                isChecked={isLanguageChecked}
                onChange={handleLanguageChange}
              >
                Enable Language Selection Input in Response Assist
              </Checkbox>
              <br />
              <Checkbox
                isChecked={isGoogleChecked}
                onChange={handleGoogleChange}
              >
                Enable Google and DuckDuckGo options in Search My Resources
              </Checkbox>
              <br />

              <div className="flex justify-content-end margin-top">
                <Button
                  type="tertiary"
                  isDisabled={isBtnDisabled}
                  onClick={setPluginSettings}
                >
                  Save Settings
                </Button>
              </div>
            </>
          )}
          {EmailVerified ? (
            <>
              <div className="margin-top">
                <Paragraph>
                  <a
                    className="returnLink margin-top"
                    onClick={() => redirect()}
                  >
                    Return to Workspace
                  </a>
                </Paragraph>
              </div>
              <br />
              <br />

              <div className="margin-top">
                <Paragraph>
                  <a
                    className="returnLink margin-top"
                    onClick={() => {
                      redirect(true);
                    }}
                  >
                    Logout
                  </a>
                </Paragraph>
              </div>
            </>
          ) : (
            <>
              <div className="margin-top">
                <Paragraph>
                  <a
                    className="returnLink margin-top"
                    onClick={() => redirect(true)}
                  >
                    Return to Homepage
                  </a>
                </Paragraph>
              </div>
              <br />
              <p>
                <b>Setup Instructions:</b>
              </p>
              <Paragraph>
                Please visit{" "}
                <a href="https://upbrainsai.com/front" target="_blank">
                  Front Integration
                </a>{" "}
                page for detailed setup instructions to UpBrains AI Front Plugin
                and to activate and configure Inbox Skills and Document Skills
                (Xtract OCR, Xtract Prebuilt, Xtract AnyDoc). <br />
              </Paragraph>
            </>
          )}
        </div>
      </div>

      {!!show401Banner && (
        <div className="setting-1">
          <InlineBanner
            size={VisualSizesEnum.SMALL}
            type="error"
            title="Unauthorized! Please ensure you are subscribed to Upbrains Chat and check your Front Credential in the UpBrains Portal!"
            onClose={() => setShow401Banner(false)}
          />
        </div>
      )}
    </PluginLayout>
  );
};

export default Settings;
