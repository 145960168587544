import { END_POINTS } from "../../constants/endpoints";
import { ignite_url, integration_name } from "../../constants/variables";

export const getConversationBrief = async (requestOptions , ticket_or_conversation_id) => {
  const res = await fetch(
    ignite_url + END_POINTS.SERVICE_APIS.SERVICE_RESULTS + `?ticket_or_conversation_id=${ticket_or_conversation_id}&integration_name=${integration_name}&brief=true`,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};
