import { useState } from "react";
import listMessages from "../../plugin-apis/listMessages";
import usePrimitiveFunctions from "../../modules/plugins-common/services/server/usePrimitiveFunctions";
import getTextFromHTMLstr from "../../modules/plugins-common/utils/functions/getTextFromHTMLstr";
import extractLastMessageFromHTMLBody from "../functions/extractLastMessageFromHTMLBody";
// import { mockData } from "../../mock";

const useListOfMessages = (upbrainsToken, teamEmail) => {
  const [lastMsgId, setLastMsgId] = useState("");
  const [selectList, setSelectList] = useState([]);
  const [ListOfMessages, setListOfMessages] = useState([]);

  const { postLog } = usePrimitiveFunctions(upbrainsToken, teamEmail);

  /**
   * gets the list of all messages of this conversation from front
   */
  const getListOfMessages = async () => {
    try {
      let results_minus1;
      let result, results;
      const listMsgObj = {
        lastMsg: "",
        fullMsg: "",
        lastMsgId: "",
        subject: "",
        fromName: "",
        address: "",
        type: "",
        status: "",
      };

      result = await listMessages();
      // result = mockData.front_list_messages;

      results = result.results;

      // want to get any message, not only the inbound ones.
      /* results = results.filter(
        ({ status }) => status?.toLowerCase() === "inbound"
      ); */

      results_minus1 = results[results?.length - 1];

      let last_message_text = "";
      let full_message_text = "";

      if (results_minus1?.content?.body?.length > 0) {
        let body;
        const contentBody = results_minus1.content.body;
        body = contentBody
          ? extractLastMessageFromHTMLBody(
              contentBody,
              true,
              false,
              JSON.parse(localStorage.getItem("isSignatureChecked"))
            )
          : "";
        last_message_text = body ? getTextFromHTMLstr(body, true) : "";

        body = contentBody
          ? extractLastMessageFromHTMLBody(
              contentBody,
              false,
              true,
              JSON.parse(localStorage.getItem("isSignatureChecked"))
            )
          : "";
        full_message_text = body ? getTextFromHTMLstr(body, true) : "";

        if (results_minus1?.id) {
          listMsgObj.lastMsg = last_message_text || "";
          listMsgObj.fullMsg = full_message_text || "";
          listMsgObj.lastMsgId = results_minus1.id;
          listMsgObj.subject = results_minus1?.subject || "";
          listMsgObj.fromName = results_minus1?.from?.name || "";
          listMsgObj.address = results_minus1?.from?.handle || "";
          listMsgObj.type = results_minus1?.from?.type || "";
          listMsgObj.status = results_minus1?.status || "";

          setLastMsgId(results_minus1.id);
        }
      }

      const modifiedResults = results
        .filter(({ content, id }) => content?.body && id)
        .map((item) => item);

      setListOfMessages(modifiedResults);
      setSelectList(modifiedResults.slice(0, -1));

      return Promise.resolve(listMsgObj);
    } catch (e) {
      const errorCode = e.code;
      const errorName = e.constructor.name;
      const errorMessage = `getListOfMessages got an error: ${e.message}`;
      postLog(errorMessage, errorName, errorCode, "Front-listMessages");
    }
  };

  return {
    lastMsgId,
    selectList,
    ListOfMessages,
    setListOfMessages,
    getListOfMessages,
  };
};

export default useListOfMessages;
