import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  InlineBanner,
  Paragraph,
  VisualSizesEnum,
} from "@frontapp/ui-kit";
import { Features } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ConversationSettings from "../components/common/ConversationSettings";
import AIResponse from "../components/common/AIResponse";
import { BeatLoader } from "react-spinners";
import useConversation from "./useConversation";
import TemplateModal from "../components/common/TemplateModal";
import { chat_url } from "../constants/variables";
import usePrimitiveFunctions from "../services/server/usePrimitiveFunctions";
import FeedbackModal from "../components/common/FeedbackModal";
import Feedback from "../components/common/Feedback";
import Attachments from "../components/Attachments";
import DocumentsAndActions from "../components/DocumentAndAction";
import AttachmentsDropdown from "../components/AttachmentsDropdown";
import ExtractorDropdown from "../components/ExtractorDropdown";
import { Box, Modal } from "@mui/material";
import PdfSvg from "../assets/images/pdf.svg";
import JpgSvg from "../assets/images/jpg.svg";
import Workflow from "../components/actions/Workflow";
import TranslateAttachment from "../components/actions/TranslateAttachment";
import useSWRMutation from "swr/mutation";
import showErrorMessage from "../utils/functions/showErrorMessage";
import ConversationBrief from "../components/actions/ConversationBrief";
import { getConversationBrief } from "../services/server/conversation-brief.service";
import { usePluginContext } from "../../../providers/contextManagement";

const Conversation = () => {
  const {
    TeamEmail,
    UpbrainsToken,
    getOrganizedResourcesLoading,
    selectedFeature,
    handleChangeActionToDefaultOne,
    conversationSettingsProps,
    responseComponentProps,
    features,
    handleSelectFeature,
    getOrganizedResourcesError,
    openTemplateModal,
    setOpenTemplateModal,
    getLastMessage,
    handleSetFeedbackType,
    feedbackType,
    isFeedbackModalOpen,
    closeFeedbackModal,
    openFeedbackModal,
    feedback,
    handleChangeUserFeedback,
    mutateFeedback,
    feedbackResult,
    mutatingFeedback,
    mutateFeedbackError,
    extractors,
    attachments,
    handleSelectAttachment,
    selectedAttachment,
    attachmentResult,
    selectedExtractor,
    setSelectedExtractor,
    mutateAttachmentResult,
    hasAttachmentResult,
    handleBackUserToExtractingStep,
    getAttachmentResultMutating,
    getAttachmentResultError,
    setAttachmentModal,
    openAttachmentsModal,
    handleSeeAllAttachments,
    getAttachmentsLoading,
    postAsCommentError,
    postAsCommentData,
    posingAsCommentMutating,
    attachmentType,
    handleSelectAttachmentsType,
    postAsCommentMutate,
    resources,
    templateCategory,
    setTemplateCategory,
    mutateAttachmentsResult,
    clearFirstWord,
    Response1,
    selectLanguage,
    handleSelectedLanguage,
    ConversationID,
  } = useConversation();

  const [templateName, setTemplateName] = useState("");
  const [show401Banner, setShow401Banner] = useState(false);
  const { checkAuth, postLog } = usePrimitiveFunctions(
    UpbrainsToken,
    TeamEmail
  );

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  const handleSaveResponseAsTemplate = async () => {
    try {
      const { lastMsg } = await getLastMessage();
      const body = JSON.stringify({
        last_message: lastMsg,
        template_text: responseComponentProps.value,
        name: templateName,
        category: templateCategory,
      });
      const header = await checkAuth();
      var requestOptions = {
        method: "POST",
        headers: header,
        body,
        redirect: "follow",
      };
      const response = await fetch(
        chat_url + "/api/response-assist/save-template",
        requestOptions
      );

      if (!response?.ok) {
        const errorMsg = showErrorMessage(response?.status);
        throw new Error(errorMsg);
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const {
    data: saveTemplateData,
    error: saveTemplateError,
    isMutating: isSavingTemplate,
    trigger: saveTemplateTrigger,
    reset: saveTemplateReset,
  } = useSWRMutation(
    ["api/response-assist/save-template", Response1],
    handleSaveResponseAsTemplate,
    {
      onSuccess: () => {
        setTimeout(() => {
          setOpenTemplateModal(false);
          setTemplateCategory(null);
          saveTemplateReset();
        }, 1000);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const processorType = attachmentResult?.payload?.processor_type;
  const serviceName =
    processorType === "Extractor"
      ? attachmentResult?.payload?.processor_result?.extractor_info
          ?.service_name
      : attachmentResult?.payload?.processor_result?.extractors_response
          ?.extractor_info?.service_name;
  const isOcr = serviceName && serviceName.toLowerCase().includes("ocr");
  const [isExpanded, setIsExpanded] = useState(false);
  const [conversationBriefError, setConversationBriefError] = useState("");
  const [conversationBriefResult, setConversationBriefResult] = useState([]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 454,
    borderRadius: 2,
    border: "1px solid #E5E7EB",
    p: 0,
    bgcolor: "#fff",
    display: "flex",
    flexDirection: "column",
    maxHeight: "600px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },

    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#757575",
      borderRadius: "6px",
    },

    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#757575",
    },

    "::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
      borderRadius: "6px",
    },

    "::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
    "::-webkit-scrollbar": {
      width: "6px",
    },
  };

  const leftDays = () => {
    if (localStorage.getItem("expire_date_timestamp")) {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const leftTimestamp =
        Number(localStorage.getItem("expire_date_timestamp")) -
        currentTimestamp;
      const leftDays = Math.floor(leftTimestamp / (60 * 60 * 24));
      return leftDays;
    } else return 0;
  };

  const conversationBrief = useCallback(async () => {
    try {
      // const { results, status } = {
      //   number_of_results: 6,
      //   results: [
      //     {
      //       action_category: "Document Skills",
      //       action_name: "Translate",
      //       content:
      //         "CONSULTING CONTENT CLOUD\n123 شارع ماين، جناح 100\nسان فرانسيسكو، كاليفورنيا 93106\n(415) 555-1234\n\nفاتورة\n\nفاتورة لـ                  المدفوعة إلى                رقم الفاتورة\nهيكتور روبيو                 استشارات محتوى السحابة  123456\nالإنتاج النهائي\n1400 شرق شارع 3، جناح C    المشروع                   تاريخ الاستحقاق\nنيويورك، نيويورك، 10003          استراتيجية السحابة             31/12/2023\n\n\nالوصف                                        الكمية      سعر الوحدة     السعر الإجمالي\nخدمات الاستشارات الشهرية                         12        $2,000.00     $24,000.00\nرسوم النسخ الاحتياطي لمرة واحدة                   1       $1,500.00      $1,500.00\n                                                                                 $0.00\n                                                                                 $0.00\nملاحظات:                                                         الإجمالي     $25,500.00\n                                                           التعديلات      -$1,500.00\n                                                                            $24,000.00",
      //       id: 7,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Document Skills",
      //       action_name: "Classify Attachment",
      //       content: "Document Class: Invoice",
      //       id: 6,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Document Skills",
      //       action_name:
      //         "Extract Information - Bank Statement Summary (Prebuilt)",
      //       content:
      //         "File Name: Sample Support Invoice.png\nDocument: Bank Statement\nBank Name: \nAccount Name: CLOUD CONTENT CONSULTING\nStatement Date: 00/00/00\nStatement Start Date: 00/00/00\nStatement End Date: 00/00/00\nAccount Number: \nAccount Type: \nDeposits, Additions and Credits: -1\nHuman Review: Total Deposits",
      //       id: 5,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Document Skills",
      //       action_name: "Extract Information - Receipt (Prebuilt)",
      //       content:
      //         "Merchant Address: 123 Main Street Suite 100San Francisco, CA 93106\nMerchant Name: CLOUD CONTENT CONSULTING\nMerchant Phone Number: (415) 555-1234\nSubtotal: $25,500.00\nTotal: $24,000.00\n\nTotal Items: 2\n\nItem 1:\nDescription: Monthly Consulting Services\nPrice: $2,000.00\nQuantity: 12\nTotal Price: $24,000.00\n\nItem 2:\nDescription: One-time Backup Fee\nPrice: $1,500.00\nQuantity: 1\nTotal Price: $1,500.00\n",
      //       id: 3,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Document Skills",
      //       action_name: "PDF/Image to Text (OCR)",
      //       content:
      //         "CLOUD CONTENT CONSULTING\n123 Main Street Suite 100\nSan Francisco, CA 93106\n(415) 555-1234\n\nInvoice\n\nInvoice for                  Payable to                Invoice #\nHector Rubio                 Cloud Content Consulting  123456\nFinal Production\n1400 E 3rd Avenue Suite C    Project                   Due date\nNew York, NY, 10003          Cloud Strategy             12/31/2023\n\n\nDescription                                        Qty      Unit price     Total price\nMonthly Consulting Services                         12        $2,000.00     $24,000.00\nOne-time Backup Fee                                   1       $1,500.00      $1,500.00\n                                                                                 $0.00\n                                                                                 $0.00\nNotes:                                                         Subtotal     $25,500.00\n                                                           Adjustments      -$1,500.00\n                                                                            $24,000.00",
      //       id: 2,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Document Skills",
      //       action_name: "Extract Information - OCR (Prebuilt)",
      //       content:
      //         "CLOUD CONTENT CONSULTING\n123 Main Street Suite 100\nSan Francisco, CA 93106\n(415) 555-1234\n\nInvoice\n\nInvoice for                  Payable to                Invoice #\nHector Rubio                 Cloud Content Consulting  123456\nFinal Production\n1400 E 3rd Avenue Suite C    Project                   Due date\nNew York, NY, 10003          Cloud Strategy             12/31/2023\n\n\nDescription                                        Qty      Unit price     Total price\nMonthly Consulting Services                         12        $2,000.00     $24,000.00\nOne-time Backup Fee                                   1       $1,500.00      $1,500.00\n                                                                                 $0.00\n                                                                                 $0.00\nNotes:                                                         Subtotal     $25,500.00\n                                                           Adjustments      -$1,500.00\n                                                                            $24,000.00",
      //       id: 1,
      //       processed_data: "Attachment - Sample Support Invoice.png",
      //     },
      //     {
      //       action_category: "Response Assist",
      //       action_name: "Summarize",
      //       content:
      //         "CONSULTING CONTENT CLOUD\n123 شارع ماين، جناح 100\nسان فرانسيسكو، كاليفورنيا 93106\n(415) 555-1234\n\nفاتورة\n\nفاتورة لـ                  المدفوعة إلى                رقم الفاتورة\nهيكتور روبيو                 استشارات محتوى السحابة  123456\nالإنتاج النهائي\n1400 شرق شارع 3، جناح C    المشروع                   تاريخ الاستحقاق\nنيويورك، نيويورك، 10003          استراتيجية السحابة             31/12/2023\n\n\nالوصف                                        الكمية      سعر الوحدة     السعر الإجمالي\nخدمات الاستشارات الشهرية                         12        $2,000.00     $24,000.00\nرسوم النسخ الاحتياطي لمرة واحدة                   1       $1,500.00      $1,500.00\n                                                                                 $0.00\n                                                                                 $0.00\nملاحظات:                                                         الإجمالي     $25,500.00\n                                                           التعديلات      -$1,500.00\n                                                                            $24,000.00",
      //       id: 11,
      //       processed_data: "Message Body",
      //     },
      //     {
      //       action_category: "Conversation Skills",
      //       action_name: "Analyze Sentiment",
      //       content: "Label: Somewhat Happy",
      //       id: 12,
      //       processed_data: "Message Body",
      //     },
      //   ],
      //   status: 200,
      // };
      const ticket_or_conversation_id = context?.conversation.id;
      const requestOptions = {
        method: hasAttachmentResult ? "PUT" : "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };
      const { results, status } = await getConversationBrief(
        requestOptions,
        ticket_or_conversation_id
      );

      if (status !== 200) throw new Error(status);

      setConversationBriefResult(results);
      return Promise.resolve(results);
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      setConversationBriefError(error);
      postLog(
        `conversationBrief got an error: ${err}`,
        errorName,
        errorCode,
        "service-results?brief=true"
      );
      return Promise.reject(err.message);
    }
  }, [postLog]);

  useEffect(() => {
    if (selectedFeature.id === "conversationResult") {
      conversationBrief();
    }
  }, [selectedFeature]);
  useEffect(() => {
    if (localStorage.getItem("unauthorized") === "true") {
      setShow401Banner(true);
    } else {
      setShow401Banner(false);
    }
  }, [localStorage.getItem("unauthorized")]);

  return (
    <div className="conversation-container custom-scrollbar">
      {openAttachmentsModal && (
        <Modal
          open={openAttachmentsModal}
          onClose={() => setAttachmentModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="conversation-2">Open Files</div>
            <div className="conversation-3">
              {attachments?.length ? (
                attachments.map((attachment) => (
                  <div
                    className={`conversation-4 ${
                      attachment?.isInvoice
                        ? "conversation-21"
                        : "conversation-22"
                    }`}
                    key={attachment.id}
                    onClick={() => {
                      handleSelectAttachment(attachment);
                      setAttachmentModal(false);
                    }}
                  >
                    <div className="attachment-img">
                      <img
                        src={
                          attachment.content_type === "text/plain"
                            ? PdfSvg
                            : JpgSvg
                        }
                        alt="pdf"
                      />
                    </div>
                    <div className="conversation-5">
                      <div className="conversation-6">{attachment.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Attachments</div>
              )}
            </div>
          </Box>
        </Modal>
      )}

      {openTemplateModal && (
        <TemplateModal
          resources={resources?.filter((resource) => resource?.category)}
          open={openTemplateModal}
          onClose={() => {
            setOpenTemplateModal(false);
            saveTemplateReset();
          }}
          templateName={templateName}
          templateTextareaValue={responseComponentProps.value}
          changeTemplateName={setTemplateName}
          changeTemplateTextarea={responseComponentProps.onChange}
          onSave={saveTemplateTrigger}
          templateCategory={templateCategory}
          setTemplateCategory={setTemplateCategory}
          isLoading={isSavingTemplate}
          error={saveTemplateError}
          data={saveTemplateData}
        />
      )}
      {isFeedbackModalOpen && (
        <FeedbackModal
          feedbackType={feedbackType}
          open={isFeedbackModalOpen}
          handleSetFeedbackType={handleSetFeedbackType}
          onClose={closeFeedbackModal}
          feedback={feedback}
          onFeedbackChange={handleChangeUserFeedback}
          onSubmit={mutateFeedback}
          feedbackResult={feedbackResult}
          mutatingFeedback={mutatingFeedback}
          mutateFeedbackError={mutateFeedbackError}
        />
      )}
      <div className="conversation-box">
        {!!(
          localStorage.getItem("subscription_status") === "trialing" ||
          localStorage.getItem("subscription_status") === "trial"
        ) && (
          <span className="conversation-7">
            Your trial subscription expires in {leftDays()} days. Please{" "}
            <a
              href="https://portal.upbrainsai.com/settings/billing?sn=VXBCcmFpbnMtQUk&sf=QWxs"
              target="_blank"
              className="conversation-8"
              rel="noreferrer"
            >
              upgrade
            </a>{" "}
            to supercharge your AI journey.
          </span>
        )}
        <div className="margin-bottom quick-action-container">
          <Features
            selectedFeature={selectedFeature}
            features={features}
            handleSelectFeature={handleSelectFeature}
          />
        </div>
        {!selectedAttachment && !attachmentResult ? (
          <Attachments
            attachments={attachments}
            handleSelectAttachment={handleSelectAttachment}
            isLoading={getAttachmentsLoading}
            defaultExpanded={attachments?.length}
            openModal={handleSeeAllAttachments}
            attachmentType={attachmentType}
            handleSelectAttachmentsType={handleSelectAttachmentsType}
          />
        ) : attachments?.length ? (
          <AttachmentsDropdown
            attachments={attachments}
            handleNavigateBack={handleChangeActionToDefaultOne}
            handleSelectAttachment={handleSelectAttachment}
            selectedAttachment={selectedAttachment}
            handleExpand={() => setIsExpanded((prev) => !prev)}
            isExpanded={isExpanded}
          />
        ) : null}
        <div className="main-box  margin-top flex-grow position-relative">
          {selectedAttachment && attachmentResult ? (
            <>
              <div className="flex-box-left conversation-9">
                {!hasAttachmentResult && (
                  <Button
                    onClick={handleBackUserToExtractingStep}
                    type="secondary"
                    size="small"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                )}
                <h3 className="heading conversation-10">
                  '
                  {selectedAttachment.name.length > 10
                    ? selectedAttachment.name.substring(0, 10) + "..."
                    : selectedAttachment.name}
                  ' Attachment Result:
                </h3>
              </div>
              <DocumentsAndActions
                postAsCommentMutate={postAsCommentMutate}
                hasAttachmentResult={hasAttachmentResult}
                postAsCommentData={postAsCommentData}
                posingAsCommentMutating={posingAsCommentMutating}
                postAsCommentError={postAsCommentError}
                isOCR={isOcr}
                attachmentResult={attachmentResult}
                mutateAttachmentsResult={mutateAttachmentsResult}
                isExpanded={isExpanded}
              />
              {posingAsCommentMutating && (
                <div className="conversation-11">
                  <BeatLoader
                    color={"#0059b3"}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
              {postAsCommentError && (
                <Paragraph color={"red"}>{postAsCommentError}</Paragraph>
              )}

              {postAsCommentData && (
                <Paragraph color={"green"}>{postAsCommentError?.msg}</Paragraph>
              )}
            </>
          ) : selectedAttachment && !attachmentResult ? (
            <>
              <div className="flex-box-left ">
                <h3 className="conversation-10">
                  Extract information from the attachment
                </h3>
              </div>
              {/* <span className="conversation-12">
                You can create custom extractors and apply them on your
                attachments automatically. See{" "}
                <a
                  href="https://community.upbrainsai.com/docs/quick-start-guide/first-document-extractor/"
                  target="_blank"
                  className="conversation-8"
                >
                  {" "}
                  UpBrains Documentation
                </a>
                .
              </span>
              <br /> */}
              <span className="conversation-12">
                <strong>Note:</strong> You can automate the application of
                extractors to your attachments by creating and publishing a{" "}
                <a
                  href="https://portal.upbrainsai.com"
                  target="_blank"
                  className="conversation-8"
                >
                  {" "}
                  workflow
                </a>
                .
              </span>
              <ExtractorDropdown
                extractors={extractors}
                handleSubmit={mutateAttachmentResult}
                handleSelectExtractor={(extractor) =>
                  setSelectedExtractor(extractor)
                }
                selectedExtractor={selectedExtractor}
                submitError={getAttachmentResultError?.message}
              />
              {getAttachmentResultError && (
                <Paragraph color={"red"}>
                  {getAttachmentResultError?.message?.replace(
                    "Error: Error: ",
                    ""
                  )}
                </Paragraph>
              )}
              {getAttachmentResultMutating && (
                <div className="conversation-13">
                  <div className="conversation-14">
                    Processing with{" "}
                    <string className="conversation-15">
                      {selectedExtractor?.display_name}
                    </string>{" "}
                    ...
                  </div>
                  <BeatLoader
                    color={"#0059b3"}
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
            </>
          ) : getOrganizedResourcesLoading ? (
            <div className="conversation-16">
              <BeatLoader
                color={"#0059b3"}
                loading={true}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : selectedFeature &&
            selectedFeature?.id &&
            selectedFeature.id !== "TRANSLATE" &&
            selectedFeature.id !== "Workflow" &&
            selectedFeature.id !== "conversationResult" ? (
            <>
              <div className="flex-box-left">
                <Button
                  onClick={handleChangeActionToDefaultOne}
                  type="secondary"
                  size="small"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <h3 className="heading">
                  {selectedFeature && selectedFeature.title}
                </h3>
              </div>

              <div className="margin-top-24 flex-column">
                {getOrganizedResourcesError ? (
                  <Paragraph color={"red"}>
                    Something went wrong. Please refresh or contact support, if
                    not resolved within a few minutes.
                  </Paragraph>
                ) : (
                  <>
                    <div>
                      <ConversationSettings
                        selectedFeature={selectedFeature}
                        clearFirstWord={clearFirstWord}
                        {...conversationSettingsProps}
                      />
                    </div>
                    {selectedFeature && selectedFeature.hasResponse && (
                      <div>
                        <AIResponse
                          {...responseComponentProps}
                          handleSetFeedbackType={handleSetFeedbackType}
                          openModal={openFeedbackModal}
                          isModalOpen={isFeedbackModalOpen}
                        />
                      </div>
                    )}
                    <div className="conversation-17">
                      <Feedback
                        selectResponseTypeHandler={handleSetFeedbackType}
                        openModal={openFeedbackModal}
                        isModalOpen={isFeedbackModalOpen}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : selectedFeature &&
            selectedFeature?.id &&
            selectedFeature.id === "TRANSLATE" ? (
            <>
              <div className="flex-box-left">
                <Button
                  onClick={handleChangeActionToDefaultOne}
                  type="secondary"
                  size="small"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <h3 className="heading">
                  {selectedFeature && selectedFeature.title}
                </h3>
              </div>

              <div className="margin-top-24">
                <TranslateAttachment
                  UpbrainsToken={UpbrainsToken}
                  TeamEmail={TeamEmail}
                  selectLanguage={selectLanguage}
                  handleSelectedLanguage={handleSelectedLanguage}
                  features={features}
                  ConversationID={ConversationID}
                />
              </div>
              <div className="conversation-18">
                <Feedback
                  selectResponseTypeHandler={handleSetFeedbackType}
                  openModal={openFeedbackModal}
                  isModalOpen={isFeedbackModalOpen}
                />
              </div>
            </>
          ) : selectedFeature &&
            selectedFeature?.id &&
            selectedFeature.id === "Workflow" ? (
            <>
              <div className="flex-box-left">
                <Button
                  onClick={handleChangeActionToDefaultOne}
                  type="secondary"
                  size="small"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <h3 className="heading">
                  {selectedFeature && selectedFeature.title}
                </h3>
              </div>

              <div className="margin-top-24">
                <Workflow UpbrainsToken={UpbrainsToken} TeamEmail={TeamEmail} />
              </div>
              <div className="conversation-20">
                <Feedback
                  selectResponseTypeHandler={handleSetFeedbackType}
                  openModal={openFeedbackModal}
                  isModalOpen={isFeedbackModalOpen}
                />
              </div>
            </>
          ) : (
            selectedFeature &&
            selectedFeature?.id &&
            selectedFeature.id === "conversationResult" && (
              <>
                <div className="flex-box-left">
                  <Button
                    onClick={handleChangeActionToDefaultOne}
                    type="secondary"
                    size="small"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <h3 className="heading">
                    {selectedFeature && selectedFeature.title}
                  </h3>
                </div>

                <div className="margin-top-24">
                  <ConversationBrief results={conversationBriefResult} />
                </div>
                <div className="conversation-18">
                  <Feedback
                    selectResponseTypeHandler={handleSetFeedbackType}
                    openModal={openFeedbackModal}
                    isModalOpen={isFeedbackModalOpen}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
      {!!show401Banner && (
        <div className="conversation-19">
          <InlineBanner
            size={VisualSizesEnum.SMALL}
            type="error"
            title={`Unauthorized! Please ensure you are subscribed to UpBrains AI.`}
            onClose={() => setShow401Banner(false)}
          />
        </div>
      )}
    </div>
  );
};

export default Conversation;
