import { useCallback, useState, useEffect, useMemo } from "react";
import { BeatLoader } from "react-spinners";
import {
  Button,
  Checkbox,
  Paragraph,
  Select,
  SelectItem,
} from "@frontapp/ui-kit";

import { isZendesk } from "../../../../constants/plugin_variables";
import useAttachmentsSWR from "../../utils/hooks/useAttachmentsSWR";
import {
  getConversationInfo,
  getWorkflowsList,
  postAttachmentsList,
} from "../../services/server/workflow.service";
import usePrimitiveFunctions from "../../services/server/usePrimitiveFunctions";
import { usePluginContext } from "../../../../providers/contextManagement";
import showErrorMessage from "../../utils/functions/showErrorMessage";

const Workflow = ({ UpbrainsToken, TeamEmail }) => {
  const [error, setError] = useState("");
  const [hasBody, setHasBody] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [workflowsList, setWorkflowsList] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [attachmentType, setAttachmentType] = useState("MESSAGE");
  const [hasAttachmentToWorkflow, setHasAttachmentToWorkflow] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState({
    id: "",
    name: "",
    is_inline: false,
    content_type: "",
  });

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));
  const { checkAuth, postLog } = usePrimitiveFunctions(
    UpbrainsToken,
    TeamEmail
  );
  const {
    attachments,
    getAttachmentsError,
    getAttachmentsLoading,
    getAttachmentsMutate,
  } = useAttachmentsSWR(
    attachmentType,
    isZendesk,
    UpbrainsToken,
    TeamEmail,
    "/workflow"
  );
  if (error || isSucceed) {
    setTimeout(() => {
      setError("");
      setIsSucceed(false);
    }, 10000);
  }

  const handleSelectAttachmentType = (type) => {
    setAttachmentType(type);
    getAttachmentsMutate();
  };

  const handleGetWorkflowsList = useCallback(async () => {
    try {
      setIsLoading(true);
      const requestOptions = {
        method: "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };

      const { workflows, status } = await getWorkflowsList(requestOptions);
      // const { workflows, status } = {
      //   number_of_workflows: 5,
      //   status: 200,
      //   workflows: [
      //     {
      //       integration_name: "Zendesk",
      //       trigger_type: "new_ticket",
      //       webhook_url: "https://v1/webhooks/id1",
      //       workflow_id: "id1",
      //       workflow_type: "workflow",
      //     },
      //     {
      //       integration_name: "Zendesk",
      //       trigger_type: "new_ticket",
      //       webhook_url: "https://v1/webhooks/id2",
      //       workflow_id: "id2",
      //       workflow_type: "workflow",
      //     },
      //     {
      //       integration_name: "Zendesk",
      //       trigger_type: "new_ticket",
      //       webhook_url: "https://v1/webhooks/id2",
      //       workflow_id: "id2",
      //       workflow_type: "workflow",
      //     },
      //     {
      //       integration_name: "Front",
      //       trigger_type: "new_ticket",
      //       webhook_url: "https://v1/webhooks/id3",
      //       workflow_id: "id3",
      //       workflow_type: "workflow",
      //     },
      //     {
      //       integration_name: "Front",
      //       trigger_type: "new_ticket",
      //       webhook_url: "https://v1/webhooks/id3",
      //       workflow_id: "id3",
      //       workflow_type: "workflow",
      //     },
      //   ],
      // };

      if (status !== 200) throw new Error(status);

      setWorkflowsList(workflows);
      setIsLoading(false);
      return Promise.resolve(workflows);
    } catch (err) {
      setIsLoading(false);
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const errorMessage = showErrorMessage(err.message);
      postLog(
        `handleGetWorkflowsList got an error: ${err}`,
        errorName,
        errorCode,
        "plugin-integration-config-workflows"
      );
      setError(errorMessage);
      return Promise.reject(err.message);
    }
  }, [checkAuth, postLog]);

  const handleGetConversationInfo = useCallback(async () => {
    try {
      const params = `?integration_name=${
        isZendesk ? "Zendesk" : "Front"
      }&ticket_or_conversation_id=${context?.conversation?.id}`;
      const requestOptions = {
        method: "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };

      const { conversation_info, status } = await getConversationInfo(
        params,
        requestOptions
      );

      // const { conversation_info, status } = {
      //   conversation_info: {
      //     attachments: [
      //       {
      //         content_type: "application/pdf",
      //         content_url:
      //           "https://upbrains-ai-inc.api.frontapp.com/download/fil_3p9tzwzw",
      //         id: "fil_3p9tzwzw",
      //         is_inline: false,
      //         name: "INVOIC_DEMO2.pdf",
      //       },
      //     ],
      //     body: "invoice",
      //     channel_address: "ezbnw-cd7b5d03a05ef5b156df@in.frontapp.com",
      //     channel_type: "email",
      //     comment_or_message_id: "msg_25emqs98",
      //     from: {
      //       email: "upbrainsai@gmail.com",
      //       name: "UpBrains AI",
      //     },
      //     inbox_or_group_id: "inb_as1zg",
      //     integration_name: "Front",
      //     subject: "Fwd: invoice 2-2",
      //     tags: [
      //       {
      //         color: "blue",
      //         id: "tag_40s8lo",
      //         name: "Invoice",
      //       },
      //     ],
      //     ticket_or_conversation_id: "cnv_177iwato",
      //     timestamp: 1703264018,
      //     to: [
      //       {
      //         email: "demo@upbrains.ai",
      //         name: "",
      //       },
      //     ],
      //   },
      //   status: 200,
      // };

      if (status !== 200) throw new Error(status);

      return Promise.resolve(conversation_info);
    } catch (err) {
      setIsLoading(false);
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const errorMessage = showErrorMessage(err.message);
      postLog(
        `handleGetConversationInfo got an error: ${err}`,
        errorName,
        errorCode,
        "plugin-get-conversation-info"
      );
      setError(errorMessage);
      return Promise.reject(err.message);
    }
  }, [context, checkAuth, postLog]);

  const handlePostWorkflow = useCallback(async () => {
    try {
      setIsSubmitLoading(true);
      setIsSucceed(false);
      const { attachments, body, ...rest } = await handleGetConversationInfo();
      const payload = JSON.stringify({
        attachments: [...(selectedAttachment?.id ? [selectedAttachment] : [])],
        body: hasBody ? body : "",
        integration_name: isZendesk ? "Zendesk" : "Front",
        ...rest,
      });

      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: payload,
        redirect: "follow",
      };

      const result = await postAttachmentsList(
        selectedWorkflow?.webhook_url,
        requestOptions
      );

      if (result && result.status && result.status !== 200)
        throw new Error(result.status);

      if (result && typeof result === "object") {
        setIsSubmitLoading(false);
        setIsSucceed(true);
      }
      return;
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const error = showErrorMessage(err.message);
      setError(error);
      setIsSucceed(false);
      setIsSubmitLoading(false);
      postLog(
        `handlePostWorkflow got an error: ${err}`,
        errorName,
        errorCode,
        "workflow-api"
      );
      return Promise.reject(err.message);
    }
  }, [
    handleGetConversationInfo,
    selectedAttachment,
    hasBody,
    checkAuth,
    selectedWorkflow,
    postLog,
  ]);

  useEffect(() => {
    if (getAttachmentsError && getAttachmentsError?.message) {
      const errorMessage = showErrorMessage(getAttachmentsError.message);
      setError(errorMessage);
    }
  }, [getAttachmentsError]);

  useEffect(() => {
    handleGetWorkflowsList();
  }, []);

  return (
    <>
      <div className="conversion-settings-container">
        <div className="custom-form-label">
          Run your workflows (create workflows in
          <a
            href="https://portal.upbrains.ai"
            target="_blank"
            className="workflow-1"
          >
            {" "}
            UpBrains Portal
          </a>
          ):
        </div>
        <div className="w-full">
          <Select
            isDisabled={isLoading}
            placeholder="Choose Workflow"
            layerRootId="story--components-select--multi"
            selectedValues={selectedWorkflow.workflow_id}
          >
            {workflowsList?.length
              ? workflowsList?.map((workflow) => (
                  <SelectItem
                    key={workflow.workflow_id}
                    isSelected={
                      selectedWorkflow?.workflow_id === workflow?.workflow_id
                    }
                    onClick={() =>
                      setSelectedWorkflow({
                        workflow_id: workflow.workflow_id,
                        integration_name: workflow.integration_name,
                        trigger_type: workflow.trigger_type,
                        webhook_url: workflow.webhook_url,
                        workflow_type: workflow.workflow_type,
                      })
                    }
                  >
                    {workflow?.workflow_name || "Untitled"} (
                    {workflow.workflow_id})
                  </SelectItem>
                ))
              : null}
          </Select>
        </div>

        {isLoading && (
          <div className="workflow-2">
            <BeatLoader
              color={"#0059b3"}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}

        <div
          className={`${
            !workflowsList?.length && "disable-element"
          } workflow-3`}
        >
          <Checkbox isChecked={hasBody} onChange={setHasBody}>
            Send message body to workflow
          </Checkbox>
          <Checkbox
            isChecked={hasAttachmentToWorkflow}
            onChange={setHasAttachmentToWorkflow}
          >
            Send attachments to workflow
          </Checkbox>

          <div
            className={`attachments-container p-15 ${
              !hasAttachmentToWorkflow && "disable-element"
            }`}
          >
            <div className="workflow-4">Select Your Attachments</div>
            <div className="workflow-5">
              <Button
                isDisabled={getAttachmentsLoading}
                onClick={() => handleSelectAttachmentType("MESSAGE")}
                type={attachmentType === "MESSAGE" ? "primary" : "secondary"}
              >
                Last Message
              </Button>
              <Button
                isDisabled={getAttachmentsLoading}
                onClick={() => handleSelectAttachmentType("ALL")}
                type={attachmentType === "ALL" ? "primary" : "secondary"}
              >
                All
              </Button>
            </div>

            {getAttachmentsLoading && (
              <div className="workflow-2">
                <BeatLoader
                  color={"#0059b3"}
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}

            <div className="w-full">
              <Select
                placeholder="Choose Attachment"
                layerRootId="story--components-select--multi"
                selectedValues={selectedAttachment.name}
                isDisabled={hasAttachmentToWorkflow && getAttachmentsLoading}
              >
                {attachments?.length
                  ? attachments?.map((attachment) => (
                      <SelectItem
                        key={attachment.id}
                        isSelected={selectedAttachment?.id === attachment?.id}
                        onClick={() =>
                          setSelectedAttachment({
                            id: attachment.id,
                            name: attachment.name,
                            is_inline: attachment.is_inline,
                            content_type: attachment.content_type,
                          })
                        }
                      >
                        {attachment.name}
                      </SelectItem>
                    ))
                  : null}
              </Select>
            </div>
          </div>
          <div className="workflow-6">
            <Button
              isDisabled={
                getAttachmentsLoading ||
                isLoading ||
                !selectedWorkflow?.workflow_id
              }
              onClick={handlePostWorkflow}
              type={"primary"}
            >
              Run
            </Button>
          </div>
        </div>
        {isSubmitLoading && (
          <div className="workflow-2">
            <BeatLoader
              color={"#0059b3"}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {!!error && <Paragraph color="red">{error}</Paragraph>}
        {!!isSucceed && (
          <Paragraph color="green">
            Successfully initiated the workflow execution.
          </Paragraph>
        )}
      </div>
    </>
  );
};

export default Workflow;
