const extractLastMessageFromHTMLBody = (
  body,
  veryLastMsg,
  fullConvs,
  excludeFromSignature = false
) => {
  if (!fullConvs && veryLastMsg) {
    let quote_index = body.indexOf("<blockquote");
    if (!excludeFromSignature) {
      let signature_index_single_quotes = body.indexOf(
        "<div class='front-signature'>"
      );
      let signature_index_double_quotes = body.indexOf(
        '<div class="front-signature">'
      );

      if (signature_index_single_quotes !== -1) {
        quote_index = signature_index_single_quotes;
      }
      if (signature_index_double_quotes !== -1) {
        quote_index = signature_index_double_quotes;
      }
    }
    if (quote_index != -1) {
      body = body.slice(0, quote_index);
    }
  }

  body = body.replace(/<\/div>/gi, "").replace(/<div[^>]*>/gi, "<br /><br />");
  body = body.replace(/<\/p>/gi, "").replace(/<p[^>]*>/gi, "<br /><br />");
  body = body
    .replace(/<\/span>/gi, "")
    .replace(/<span[^>]*>/gi, "<br /><br />");

  let blockqupte_index = body.indexOf(
    '<blockquote type="cite" class="front-blockquote">'
  );
  let quote_length = '<blockquote type="cite" class="front-blockquote">'.length;
  if (blockqupte_index != -1) {
    body = [
      body.slice(0, blockqupte_index + blockqupte_index),
      "<br/><br/>",
      body.slice(blockqupte_index + blockqupte_index + 1),
    ].join("");
  }

  body = body.replace(/<br[^>]*>/gi, "\n");
  if (
    !fullConvs &&
    veryLastMsg &&
    body.indexOf("On ") !== -1 &&
    body.indexOf("wrote:") !== -1
  ) {
    const removeText = body.substring(
      body.indexOf("On "),
      body.indexOf("wrote:") + "wrote:".length
    );
    body = body.replace(removeText, "");
  }

  return body;
};

export default extractLastMessageFromHTMLBody;
