import React from "react";
import AutoComplete from "./AutoComplete";
import {
  Button,
  Input,
  Textarea,
  Tooltip,
  TooltipCoordinator,
} from "@frontapp/ui-kit";
import { Typeahead } from "react-bootstrap-typeahead";
import { resourceTypeTemp } from "../../utils/hooks/useChat";
import lastMessageIcon from "../../assets/images/last_message_icon.svg";

const ManualResponseAssist = ({
  selectedFeature,
  resourcesList,
  handleAskResources,
  resetEveryThing,
  autoCompleteValue,
  setAutocompleteValue,
  handleAutoCompleteChange,
  handleResponseAssistTextChange,
  searchInputTerm,
  isEngine,
  urlInput,
  isWorkflowLoading,
  langParams,
  workflowObj,
  handleUrlInput,
  handleLastMessage,
  handleTypeaheadChange,
  handleResponseAssistInputChange,
  label,
}) => {
  const autoCompleteDefaultValue =
    resourcesList?.find(
      (resource) =>
        resource?.id ===
        JSON.parse(localStorage.getItem(selectedFeature?.id))?.id
    ) ||
    (autoCompleteValue?.id && autoCompleteValue) ||
    resourceTypeTemp[0];

  setAutocompleteValue(autoCompleteDefaultValue);
  return (
    <>
      <div className="form-element-container margin-top">
        <div className="custom-form-label">{label}:</div>
        <AutoComplete
          data={resourcesList}
          hasClearButton={false}
          resetEveryThing={resetEveryThing}
          label="Select one of the resources"
          defaultAutoComplete={autoCompleteDefaultValue}
          setAutocompleteValue={(value) => {
            localStorage.setItem(selectedFeature?.id, JSON.stringify(value));
            setAutocompleteValue(value);
          }}
          onChange={() => handleAutoCompleteChange()}
        />
      </div>

      {!!isEngine && (
        <div className="margin-top">
          <input
            value={urlInput}
            placeholder="[Optional] URL of a site to look for answers"
            className="response-assist-input-url"
            onChange={({ target }) => handleUrlInput(target.value)}
          />
        </div>
      )}

      {!!(
        selectedFeature?.id !== "Workflow" && selectedFeature?.id !== "API"
      ) && (
        <div className="flex-end margin-top">
          <TooltipCoordinator
            renderTooltip={() => <Tooltip>Insert Last Message</Tooltip>}
          >
            <Button type="tertiary" onClick={handleLastMessage}>
              <img src={lastMessageIcon} alt="last message" width={16} />
            </Button>
          </TooltipCoordinator>
        </div>
      )}

      {!!(
        autoCompleteValue?.type &&
        autoCompleteValue?.title?.toLowerCase().includes("workflow")
      ) && (
        <>
          {(localStorage.getItem("isWorkflowLoading") === "false" ||
            !localStorage.getItem("isWorkflowLoading")) &&
            !isWorkflowLoading && (
              <>
                {!!langParams?.length &&
                  langParams?.map(
                    ({ key, value, defaultValue, isAutoCompleteData }) => (
                      <div key={`${key}-${value}`} className="margin-top flex">
                        <div className="w-210">{value || key}</div>

                        {isAutoCompleteData &&
                        localStorage.getItem("workflowAutoCompleteObj") &&
                        JSON.parse(
                          localStorage.getItem("workflowAutoCompleteObj")
                        )[key] ? (
                          <Typeahead
                            id="workflow-autocomplete-true"
                            onChange={(selected) =>
                              handleTypeaheadChange(key, selected)
                            }
                            options={
                              JSON.parse(
                                localStorage.getItem("workflowAutoCompleteObj")
                              )[key]
                            }
                            labelKey={(option) => `${option.value}`}
                            minLength={2}
                            className="typeahead-container"
                            maxResults={5}
                          />
                        ) : (
                          <Input
                            placeholder={defaultValue || ""}
                            value={workflowObj[key] || ""}
                            className="response-assist-input-url"
                            onChange={(value) =>
                              handleResponseAssistInputChange(key, value)
                            }
                          />
                        )}
                      </div>
                    )
                  )}
              </>
            )}
        </>
      )}

      {!!(selectedFeature && selectedFeature.id !== "Workflow") &&
        autoCompleteValue &&
        resourcesList?.find(
          (resource) => resource?.id === autoCompleteValue?.id
        ) && (
          <div className="margin-top">
            <Textarea
              rows={4}
              value={searchInputTerm}
              shouldAllowResize={true}
              onChange={handleResponseAssistTextChange}
              placeholder={
                "Ask a question from your resources or search the Web"
              }
            />
          </div>
        )}

      {!!(
        (selectedFeature &&
          selectedFeature.id === "Workflow" &&
          autoCompleteValue?.type &&
          autoCompleteValue?.title?.toLowerCase().includes("workflow")) ||
        (selectedFeature &&
          selectedFeature.id !== "Workflow" &&
          autoCompleteValue &&
          resourcesList?.find(
            (resource) => resource?.id === autoCompleteValue?.id
          ))
      ) && (
        <div className="flex-end margin-top">
          <Button
            type="tertiary"
            onClick={handleAskResources}
            isDisabled={
              (!autoCompleteValue.outputParams && !searchInputTerm) ||
              (autoCompleteValue.outputParams &&
                !Object.keys(workflowObj).filter((key) => workflowObj[key])
                  ?.length &&
                !autoCompleteValue?.outputParams["auto-complete-data"] &&
                !searchInputTerm) ||
              isWorkflowLoading
            }
          >
            Ask
          </Button>
        </div>
      )}
    </>
  );
};

export default ManualResponseAssist;
