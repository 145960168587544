import { ignite_url } from "../../constants/variables";

export const postAsComment = async (integrationName, commentData, headers) => {
  try {
    const body = JSON.stringify({
      integration_name: integrationName,
      ...commentData,
    });
    const requestOptions = {
      method: "POST",
      headers,
      body,
      redirect: "follow",
    };

    const result = await fetch(
      ignite_url + "/integration-apis/post-as-comment",
      requestOptions
    );
    const response = await result.json();
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
};
