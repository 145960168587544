import styled from "styled-components";

export const Container = styled.div`
  margin: 0 20px;
  transition: all ease 0.5s;

  & button {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const Space = styled.div`
  height: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 20px;

  &.remove-margin {
    margin-bottom: 0;
  }
`;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`;

export const ElementContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  &.raw {
    justify-content: center;

    button {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &.submit {
    max-width: 154px;
    padding-top: 15px;
  }
`;

export const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputContainer = styled.div`
  overflow: hidden;
  transition: all ease 0.5s;

  &.active {
    padding-top: 7px;
  }
  &.inactive {
    max-height: 0;
  }

  display: flex;
  flex-direction: row;
`;
