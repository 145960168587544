import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@frontapp/ui-kit";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const ConversationBrief = ({ results }) => {
  const showSentiment = () => {
    const tag_color = {
      Unhappy: "red",
      "Somewhat Unhappy": "orange",
      Neutral: "grey",
      "Somewhat Happy": "lightblue",
      Happy: "green",
    };
    if (results?.length) {
      const analyzeContent = results.find((result) =>
        result?.action_name?.toLowerCase()?.includes("analyze sentiment")
      )?.content;

      if (analyzeContent) {
        return (
          <div className="custom-form-label">
            Sentiment:{" "}
            <span
              style={{
                color: tag_color[analyzeContent.replace("Label: ", "")],
              }}
            >
              {analyzeContent.replace("Label: ", "")}
            </span>
          </div>
        );
      }
    }
    return "";
  };

  const showClassification = () => {
    if (results?.length) {
      const analyzeContent = results.find((result) =>
        result?.action_name?.toLowerCase()?.includes("classification")
      )?.content;

      if (analyzeContent) {
        return (
          <div className="custom-form-label">
            Classification:{" "}
            <span>{analyzeContent.replace("Classes: ", "")}</span>
          </div>
        );
      }
    }
    return "";
  };

  const showSummary = () => {
    if (results?.length) {
      const summaryContent = results.find((result) =>
        result?.action_name?.toLowerCase()?.includes("summarize")
      )?.content;

      if (summaryContent) {
        return (
          <Accordion className="conversation-brief-1" defaultExpanded>
            <AccordionSummary
              className="attachment-2"
              expandIcon={
                <FontAwesomeIcon
                  color="#000"
                  fontSize={16}
                  fill="#000"
                  icon={faChevronDown}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="attachment-3">Summary</div>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <div className="formatted-text">{summaryContent}</div>
                {/* <div className="conversation-brief-2">
                  <Button
                    isDisabled={false}
                    onClick={() => console.log("here")}
                    type={"primary"}
                  >
                    Brief
                  </Button>
                </div> */}
              </>
            </AccordionDetails>
          </Accordion>
        );
      }
      return "";
    }
  };

  const showAttachments = () => {
    if (results?.length) {
      const extractorsList = results.map((res) => {
        if (
          res?.action_category?.toLowerCase()?.includes("document skills") ||
          res?.action_name?.toLowerCase()?.includes("extract information")
        ) {
          return res;
        }
        return undefined;
      });

      const data = extractorsList.filter(function (element) {
        return element !== undefined;
      });

      if (data?.length) {
        return (
          <Accordion className="conversation-brief-1" defaultExpanded>
            <AccordionSummary
              className="attachment-2"
              expandIcon={
                <FontAwesomeIcon
                  color="#000"
                  fontSize={16}
                  fill="#000"
                  icon={faChevronDown}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="attachment-3">Attachment Extraction Results</div>
            </AccordionSummary>
            <AccordionDetails>
              <>
                {data.map((file) => (
                  <div className="form-element-container">
                    {/* <div className="custom-form-label">
                  Attachment Extraction Results:
                </div> */}
                    {!!file.action_category
                      .toLowerCase()
                      .includes("document skills") && (
                      <div className="conversation-brief-3">
                        <div className="custom-form-label conversation-brief-4">
                          File Name:{" "}
                          <span className="conversation-brief-5">
                            {file.processed_data.replace("Attachment - ", "")}
                          </span>
                        </div>
                        {/* <a href="#" className="conversation-brief-6">
                      Review and Edit
                    </a> */}
                      </div>
                    )}

                    {!!file.action_name
                      .toLowerCase()
                      .includes("extract information") && (
                      <div className="custom-form-label conversation-brief-4">
                        Extractor Name:{" "}
                        <span className="conversation-brief-5">
                          {file.action_name.replace(
                            "Extract Information - ",
                            ""
                          )}
                        </span>
                      </div>
                    )}

                    <div className="custom-form-label conversation-brief-4">
                      Content:
                    </div>

                    <pre className="document-container">{file.content}</pre>

                    {/* <div className="conversation-brief-7">
                  <Button
                    isDisabled={false}
                    onClick={() => console.log("here")}
                    type={"primary"}
                  >
                    Brief
                  </Button>
                </div> */}
                  </div>
                ))}
              </>
            </AccordionDetails>
          </Accordion>
        );
      }
      return "";
    }
  };

  return (
    <div className="conversion-settings-container">
      {results?.length ? (
        <>
          {showSentiment()}
          {showClassification()}
          {showSummary()}
          {showAttachments()}
        </>
      ) : (
        <div className="custom-form-label">
          <p>
            To see results in conversation brief, please turn on one or more of
            the AI features:
          </p>

          <ul className="font-weight-normal">
            <li>Continuous summary</li>
            <li>Sentiment analysis</li>
            <li>Message or attachment classification</li>
            <li>Information extraction from message body</li>
            <li>Information extraction from attachments</li>
            <li>Workflow automation</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ConversationBrief;
