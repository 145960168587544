import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { usePluginContext } from "./providers/contextManagement";
import Setting from "./modules/plugins-common/components/Setting";
import NewLanding from "./modules/plugins-common/pages/NewLanding/NewLanding";
import { Conversation } from "./modules/plugins-common/pages";
import { Paragraph } from "@frontapp/ui-kit";
import DocumentAndActionPage from "./modules/plugins-common/components/DocumentAndAction";

const App = () => {
  const context = usePluginContext();

  useEffect(() => {
    const today = new Date().getTime();

    if (
      localStorage.getItem("workflowAutoCompleteObj") &&
      JSON.parse(localStorage.getItem("workflowAutoCompleteObj")).expiryDate &&
      today >
        JSON.parse(localStorage.getItem("workflowAutoCompleteObj")).expiryDate
    ) {
      localStorage.removeItem("workflowAutoCompleteObj");
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("isLanguageChecked")) {
      localStorage.setItem("isLanguageChecked", true);
    }
    if (!localStorage.getItem("isGoogleChecked")) {
      localStorage.setItem("isGoogleChecked", true);
    }
  }, []);

  let StorageChatService = "";
  let isSubscribedToChat = false;
  let emailVerified = false;
  let StorageEmailVerified = "";

  if (localStorage.getItem("ChatService") && !StorageChatService) {
    StorageChatService = JSON.parse(localStorage.getItem("ChatService"));
  }
  if (StorageChatService == "Yes") {
    isSubscribedToChat = true;
  }

  if (localStorage.getItem("EmailVerified") && !StorageEmailVerified) {
    StorageEmailVerified = JSON.parse(localStorage.getItem("EmailVerified"));
  }
  if (StorageEmailVerified == true) {
    emailVerified = true;
  } else {
    emailVerified = false;
    isSubscribedToChat = false;
  }

  let default_tab = localStorage.getItem("DefaultTab")
    ? localStorage.getItem("DefaultTab")
    : "Chat";

  if (!context) return;

  switch (context?.type) {
    case "noConversation":
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={NewLanding} />
            <Route path="/setting/" component={Setting} />
            <Route path="/results/" component={DocumentAndActionPage} />
            <Route path="/chat/" component={Conversation} />
            <Route path="/conversation/" component={Conversation} />
          </Switch>
        </BrowserRouter>
      );
    case "singleConversation":
      if (emailVerified && default_tab == "Chat") {
        return (
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Conversation} />
              <Route path="/setting/" component={Setting} />
              <Route path="/results/" component={DocumentAndActionPage} />
              <Route path="/chat/" component={Conversation} />
              <Route path="/conversation/" component={Conversation} />
            </Switch>
          </BrowserRouter>
        );
      } else {
        return (
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={NewLanding} />
              <Route path="/setting/" component={Setting} />
              <Route path="/results/" component={DocumentAndActionPage} />
              <Route path="/chat/" component={Conversation} />
              <Route path="/conversation/" component={Conversation} />
            </Switch>
          </BrowserRouter>
        );
      }
    case "multiConversations":
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={NewLanding} />
            <Route path="/results/" component={DocumentAndActionPage} />
            <Route path="/setting/" component={Setting} />
            <Route path="/chat/" component={Conversation} />
            <Route path="/conversation/" component={Conversation} />
          </Switch>
        </BrowserRouter>
      );
    default:
      console.error(`Unsupported context type: ${context?.type}`);
      return (
        <Paragraph className="prgrph">
          Please select a single conversation to continue.
        </Paragraph>
      );
      break;
  }
};

export default App;
