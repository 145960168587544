import { END_POINTS } from "./endpoints";

export const service_name = {
  Xtract_OCR: process.env.REACT_APP_OCR_SERVICE_NAME,
  Xtract_AnyDoc: process.env.REACT_APP_ANYDOC_SERVICE_NAME,
  Xtract_Prebuilt: process.env.REACT_APP_PREBUILT_SERVICE_NAME,
  Xtract_Classifier: "Xtract-Classifier",
  Xtract_SmartRouter: "Xtract-SmartRouter",
  CHAT: process.env.REACT_APP_CHAT_SERVICE_NAME,
  Front_Plugin: "Front-Plugin",
  AI_Skills: "AI-Skills",
  FrontApp_Plugin: "FrontApp-Plugin",
  AI_Workflow: "AI-Workflow",
};

export const model_type = {
  INVOICE: process.env.REACT_APP_INVOICE_SERVICE_NAME,
  Bank_Statement: process.env.REACT_APP_BANKSTATEMENT_SERVICE_NAME,
};

export const DEBUGMODE = true;
export const JSERRORCODE = 500;
export const Bank_statement = "";
export const ERRORTYPE = "exception";
export const Third_party_app_name = process.env.REACT_APP_THIRD_PARTY_APP_NAME;
export const Converse_service_name = "Converse";
export const maxSliderTokens = 3000;
export const maxPromptTokens = 8000;
export const maxModelTokens = 15500;
export const chat_url = process.env.REACT_APP_CHAT_URL;
export const ignite_url = process.env.REACT_APP_IGNITE_URL;
export const date_token = process.env.REACT_APP_DATE_TOKEN;
export const isDebugMode = process.env.REACT_APP_DEBUG_MODE;
export const source_caller = process.env.REACT_APP_IDENTIFIER;
export const front_plugin_url = process.env.REACT_APP_FRONT_PLUGIN_URL;
export const OCR_service_name = process.env.REACT_APP_OCR_SERVICE_NAME;
export const integration_name = process.env.REACT_APP_PLUGIN_INTEGRATION;
export const Chat_service_name = process.env.REACT_APP_CHAT_SERVICE_NAME;
export const plugin_service_name = process.env.REACT_APP_SERVICE_NAME;
export const Xtract_service_name = process.env.REACT_APP_XTRACT_SERVICE_NAME;
export const AnyDoc_service_name = process.env.REACT_APP_ANYDOC_SERVICE_NAME;
export const Invoice_service_name = process.env.REACT_APP_INVOICE_SERVICE_NAME;
export const xtractUrl = process.env.REACT_APP_XTRACT_SERVICE_URL;
export const apiCall = chat_url + END_POINTS.RESPONSE_ASSIST.API.API_CALL;
export const Prebuilt_service_name =
  process.env.REACT_APP_PREBUILT_SERVICE_NAME;
export const SalesLead_service_name =
  process.env.REACT_APP_SALESLEAD_SERVICE_NAME;
export const ActionItems_service_name =
  process.env.REACT_APP_ACTIONTEMS_SERVICE_NAME;
export const BankStatement_service_name =
  process.env.REACT_APP_BANKSTATEMENT_SERVICE_NAME;
export const portalUrl = process.env.REACT_APP_IGNITE_URL;

export const tonesList = [
  "Professional",
  "Simple",
  "Conversational",
  "Creative",
  "Humorous",
  "Empathic",
  "Academic",
];
export const languageList = [
  { id: "auto-detect", label: "Auto Detect", value: "Auto Detect" },
  { id: "arabic", label: "Arabic", value: "Arabic" },
  {
    id: "simplified-chinese",
    label: "Chinese (Simplified)",
    value: "Simplified Chinese",
  },
  {
    id: "traditional-chinese",
    label: "Chinese (Traditional)",
    value: "Traditional Chinese",
  },
  { id: "danish", label: "Danish", value: "Danish" },
  { id: "dutch", label: "Dutch", value: "Dutch" },
  {
    id: "english-us",
    label: "English (American)",
    value: "English (American)",
  },
  {
    id: "english-au",
    label: "English (Australian)",
    value: "English (Australian)",
  },
  {
    id: "english-uk",
    label: "English (British)",
    value: "English (British)",
  },
  {
    id: "english-ca",
    label: "English (Canadian)",
    value: "English (Canadian)",
  },
  { id: "finnish", label: "Finnish", value: "Finnish" },
  { id: "french", label: "French", value: "French" },
  { id: "german", label: "German", value: "German" },
  { id: "greek", label: "Greek", value: "Greek" },
  { id: "irish", label: "Irish", value: "Irish" },
  { id: "italian", label: "Italian", value: "Italian" },
  { id: "icelandic", label: "Icelandic", value: "Icelandic" },
  { id: "japanese", label: "Japanese", value: "Japanese" },
  { id: "portuguese", label: "Portuguese", value: "Portuguese" },
  { id: "russian", label: "Russian", value: "Russian" },
  { id: "spanish", label: "Spanish", value: "Spanish" },
  { id: "swedish", label: "Swedish", value: "Swedish" },
];
