const getTextFromHTMLstr = (str, full = false, lastIndex) => {
  let stripped_str = str.replace(/(<style[\w\W]+style>)/g, "");
  var parser = new DOMParser();
  var doc = parser.parseFromString(stripped_str, "text/html");

  let stripedHTMLContent = doc.body.innerText.trim();

  if (stripedHTMLContent && !full) {
    stripedHTMLContent = stripedHTMLContent.slice(0, lastIndex);
  }

  stripedHTMLContent = stripedHTMLContent.replace(/(\n){2,}/g, "\n\n");

  return stripedHTMLContent;
};

export default getTextFromHTMLstr;
