import { Button, Select, SelectItem } from "@frontapp/ui-kit";
import React from "react";

const ExtractorDropdown = ({
  extractors,
  selectedExtractor,
  handleSubmit,
  handleSelectExtractor,
  submitError,
}) => {
  return (
    <div className="extractor-container">
      <div className="extractor-label">Select an Extractor:</div>
      <div className="extractor-select-container">
        <div className="extractor-select">
          <Select
            placeholder="Choose Extractor"
            selectedValues={
              selectedExtractor ? selectedExtractor.display_name : null
            }
            layerRootId="story--components-select--basic"
          >
            {extractors?.length &&
              extractors
                ?.sort(function (a, b) {
                  if (a.display_name < b.display_name) {
                    return -1;
                  }
                  if (a.display_name > b.display_name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((extractor) => (
                  <SelectItem
                    key={extractor.id}
                    isSelected={selectedExtractor?.id === extractor?.id}
                    onClick={() => handleSelectExtractor(extractor)}
                  >
                    {extractor.display_name}{" "}
                    {!!extractor?.service_name
                      ?.toLowerCase()
                      .includes("prebuilt") && "(Prebuilt)"}
                    {!!extractor?.service_name
                      ?.toLowerCase()
                      .includes("anydoc") && "(Custom)"}
                  </SelectItem>
                ))}
          </Select>
        </div>
        <Button
          isDisabled={!selectedExtractor}
          onClick={handleSubmit}
          type="primary"
        >
          Extract
        </Button>
      </div>
    </div>
  );
};

export default ExtractorDropdown;
