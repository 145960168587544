import Front from "@frontapp/plugin-sdk";

const createDraft = async (createDraftOption) => {
  try {
    const result = await Front.createDraft(createDraftOption);
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default createDraft;
