import { END_POINTS } from "../../constants/endpoints";
import { chat_url, ignite_url } from "../../constants/variables";

const responseAssistUrl = ignite_url + END_POINTS.RESPONSE_ASSIST.RESOURCES;

export const getResponseAgents = async (
  requestOptions,
  detailed,
  agent_name
) => {
  const url =
    detailed === "True" && agent_name
      ? `${
          ignite_url + END_POINTS.RESPONSE_ASSIST.RESPONSE_AGENTS_CONFIG
        }?detailed=${detailed}&agent_name=${agent_name}`
      : `${
          ignite_url + END_POINTS.RESPONSE_ASSIST.RESPONSE_AGENTS_CONFIG
        }?detailed=${detailed}`;

  const res = await fetch(url, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const getResponseAssist = async (requestOptions) => {
  const res = await fetch(
    `${responseAssistUrl}?status=Processed`,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const deleteResponseAssist = async (id, requestOptions) => {
  const res = await fetch(`${responseAssistUrl}?${id}`, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssist = async (requestOptions) => {
  const res = await fetch(responseAssistUrl, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistQuery = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.QUERY,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistEmail = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.EMAIL,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistChatbot = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.CHATBOT,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistChatbotAutoResponse = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.CHATBOT_AUTO_RESPONSE,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistWebSearch = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.WEB_SEARCH,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistEmailAutoResponse = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.EMAIL_AUTO_RESPONSE,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistApiCall = async (requestOptions, url) => {
  const res = await fetch(url, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};

export const postResponseAssistWorkflow = async (requestOptions) => {
  const res = await fetch(
    chat_url + END_POINTS.RESPONSE_ASSIST.API.WORKFLOW,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};
