const clearFirstWord = (text) => {
  if (!text.length) return text;
  const txt = Array.isArray(text) ? text[0] : text;
  const words = txt.trim().split(/\s+/);
  const firstWord = words.shift();

  if (firstWord?.toLowerCase() === "answer:") {
    let rawText = words.join(" ");
    if (rawText.indexOf("\n") === 0) {
      return rawText.replace("\n", "");
    }
    return rawText;
  }

  if (txt.indexOf("\n") === 0) {
    return txt.replace("\n", "");
  }
  return txt;
};

export default clearFirstWord;
