import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import {
  Button,
  Checkbox,
  Paragraph,
  Select,
  SelectItem,
  Textarea,
} from "@frontapp/ui-kit";
import {
  conversationCounts,
  conversationTypes,
  listOfComments,
} from "../../../../constants/plugin_variables";
import {
  languageList,
  maxSliderTokens,
  tonesList,
} from "../../constants/variables";
import ResponseAssistConfiguration from "./ResponseAssistConfiguration";
import { BeatLoader } from "react-spinners";

const ConversationSettings = ({
  selectedFeature,
  config,
  selectedMessageValue,
  handleSelectMessageValue,
  SelectedTone,
  handleSelectedTone,
  handleSelectedLanguage,
  selectLanguage,
  BulletItems,
  handleChangeBulletItems,
  handleChangeTokenSlider,
  defaultTokenSliderValue,
  valueText,
  TokenSliderValue,
  isFullConversationChecked,
  handleChangeFullConversation,
  isSignatureChecked,
  handleChangeSignature,
  submitButtonConfig,
  isLoading,
  coldError,
  error,
  success,
  loadingMsg,
  AIGeneratedResponse,
  handleChangeTextArea,
  messageSelectLabel = "Message/Comment",
  toneSelectLabel = "Response Tone",
  languageSelectLabel = "Response Language",
  textariaLabel = "Points to include in the response (Recommended)",
  tokenSliderLabel = "Max Response Length",
  resourcesList,
  handleAskResources,
  resetEveryThing,
  autoCompleteValue,
  setAutocompleteValue,
  handleAutoCompleteChange,
  handleResponseAssistTextChange,
  searchInputTerm,
  isEngine,
  urlInput,
  isWorkflowLoading,
  langParams,
  workflowObj,
  responseAssistResRaw,
  handleUrlInput,
  handleLastMessage,
  handleTypeaheadChange,
  handleResponseAssistInputChange,
  templates,
  handleSelectTemplate,
  selectedTemplate,
  templateSelectedLabel,
  findAnswerLabel = (
    <p>
      Find answers to questions in the message from your resources (
      <a
        href="https://portal.upbrainsai.com/settings/knowledgebase"
        target="_blank"
        className="conversation-1"
      >
        Knowledge Base
      </a>{" "}
      and{" "}
      <a
        href="https://portal.upbrainsai.com/settings/apps"
        target="_blank"
        className="conversation-1"
      >
        APIs
      </a>
      ):
    </p>
  ),
  findAnswerHandler,
  resources,
  isSnippets,
  isColChecked,
  hasApiResult,
  selectedRows,
  clearFirstWord,
  setIsColChecked,
  setSelectedRows,
  setHasApiResult,
  title,
  label,
  conversationTypeLabel,
  conversationCountLabel,
  selectedConversationType,
  handleSelectConversationType,
  selectedConversationCount,
  handleSelectConversationCount,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const {
    isManual,
    hasTextArea,
    hasSelectTone,
    hasTokenSlider,
    hasSelectMessage,
    hasSelectLanguage,
    hasResponseConfig,
    hasResourcesSetting,
    hasTemplate,
    hasAutoFindAnswer,
    showFindAnswer,
    hasTwoSteps,
    showDraftConfig,
    hasSelectConversationType,
    hasSelectConversationCount,
  } = config;

  useEffect(() => {
    if (!searchValue) {
      setUsersToDisplay(templates);
      return;
    }

    setUsersToDisplay(
      templates.filter((u) =>
        u.title?.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
  }, [searchValue, templates]);

  return (
    <div className="conversion-settings-container">
      {title && <div className="custom-form-label">{title}</div>}
      {hasAutoFindAnswer && (
        <div className="form-element-container">
          <div className="custom-form-label">{findAnswerLabel}</div>
          <Button type="primary" onClick={findAnswerHandler}>
            Find Answers
          </Button>
        </div>
      )}
      {showFindAnswer && (
        <ResponseAssistConfiguration
          selectedFeature={selectedFeature}
          isManual={false}
          isLoading={isLoading}
          coldError={coldError}
          error={error}
          success={success}
          loadingMsg={loadingMsg}
          handleChangeTextArea={handleChangeTextArea}
          AIGeneratedResponse={AIGeneratedResponse}
          resourcesList={resourcesList}
          handleAskResources={handleAskResources}
          resetEveryThing={resetEveryThing}
          autoCompleteValue={autoCompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleResponseAssistTextChange={handleResponseAssistTextChange}
          searchInputTerm={searchInputTerm}
          isEngine={isEngine}
          urlInput={urlInput}
          isWorkflowLoading={isWorkflowLoading}
          langParams={langParams}
          workflowObj={workflowObj}
          responseAssistResRaw={responseAssistResRaw}
          handleUrlInput={handleUrlInput}
          handleLastMessage={handleLastMessage}
          handleTypeaheadChange={handleTypeaheadChange}
          handleResponseAssistInputChange={handleResponseAssistInputChange}
          isSnippets={isSnippets}
          isColChecked={isColChecked}
          hasApiResult={hasApiResult}
          selectedRows={selectedRows}
          clearFirstWord={clearFirstWord}
          setIsColChecked={setIsColChecked}
          setSelectedRows={setSelectedRows}
          setHasApiResult={setHasApiResult}
          resources={resources}
        />
      )}
      {!!hasResourcesSetting && (
        <ResponseAssistConfiguration
          isManual={isManual}
          selectedFeature={selectedFeature}
          isLoading={isLoading}
          coldError={coldError}
          error={error}
          success={success}
          loadingMsg={loadingMsg}
          handleChangeTextArea={handleChangeTextArea}
          AIGeneratedResponse={AIGeneratedResponse}
          resourcesList={resourcesList}
          handleAskResources={handleAskResources}
          resetEveryThing={resetEveryThing}
          autoCompleteValue={autoCompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleResponseAssistTextChange={handleResponseAssistTextChange}
          searchInputTerm={searchInputTerm}
          isEngine={isEngine}
          urlInput={urlInput}
          isWorkflowLoading={isWorkflowLoading}
          langParams={langParams}
          workflowObj={workflowObj}
          responseAssistResRaw={responseAssistResRaw}
          handleUrlInput={handleUrlInput}
          handleLastMessage={handleLastMessage}
          handleTypeaheadChange={handleTypeaheadChange}
          handleResponseAssistInputChange={handleResponseAssistInputChange}
          resources={resources}
          isSnippets={isSnippets}
          isColChecked={isColChecked}
          hasApiResult={hasApiResult}
          selectedRows={selectedRows}
          clearFirstWord={clearFirstWord}
          setIsColChecked={setIsColChecked}
          setSelectedRows={setSelectedRows}
          setHasApiResult={setHasApiResult}
          label={label}
        />
      )}

      {/* loading */}
      {(hasResourcesSetting || showFindAnswer) && !!isLoading && (
        <div className="ActionInProgress loading-message">
          <p>
            {!!loadingMsg && <i>{loadingMsg}</i>}
            <br />
            <span className="spinner">
              <BeatLoader
                color={"#0059b3"}
                size={10}
                loading={true}
                data-testid="loader"
                aria-label="Loading Spinner"
              />
            </span>
          </p>
        </div>
      )}
      {/* error */}
      {(hasResourcesSetting || showFindAnswer) && !!coldError && (
        <Paragraph color={"red"}>{coldError}</Paragraph>
      )}

      {(hasResourcesSetting || showFindAnswer) && !!error && (
        <Paragraph color={"red"}>{error}</Paragraph>
      )}

      {(hasResourcesSetting || showFindAnswer) && !!success && (
        <Paragraph color={"green"}>{success}</Paragraph>
      )}

      {(!hasTwoSteps || (hasTwoSteps && showDraftConfig)) && (
        <>
          {hasSelectConversationType && (
            <div className="form-element-container">
              <div className="custom-form-label">{conversationTypeLabel}:</div>
              <Select selectedValues={selectedConversationType?.label}>
                {conversationTypes.map((conversationType) => (
                  <SelectItem
                    key={conversationType.id}
                    onClick={() =>
                      handleSelectConversationType(conversationType)
                    }
                  >
                    {conversationType.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          {hasSelectConversationCount &&
            selectedConversationType?.id === "all" && (
              <div className="form-element-container">
                <div className="custom-form-label">
                  {conversationCountLabel}:
                </div>
                <Select selectedValues={selectedConversationCount?.label}>
                  {conversationCounts.map((conversationType) => (
                    <SelectItem
                      key={conversationType.id}
                      onClick={() =>
                        handleSelectConversationCount(conversationType)
                      }
                    >
                      {conversationType.label}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            )}
          {hasTextArea && (
            <div className="form-element-container">
              <div className="custom-form-label">{textariaLabel}:</div>
              <Textarea
                shouldAllowResize={true}
                value={BulletItems}
                placeholder=""
                onChange={handleChangeBulletItems}
                rows={6}
              />
            </div>
          )}

          {!!(hasTemplate && usersToDisplay?.length) && (
            <div className="form-element-container">
              <div className="custom-form-label">{templateSelectedLabel}:</div>
              <Select
                selectedValues={selectedTemplate.title}
                headerLabel="Choose template"
                searchValue={searchValue}
                onSearchChange={(value) => {
                  setSearchValue(value);
                }}
                onSelectClosed={() => {
                  setSearchValue("");
                }}
                layerRootId="story--components-select--search"
                maxHeight={200}
                placeholder="Choose template"
              >
                {usersToDisplay.map((item) => (
                  <SelectItem
                    key={item.id}
                    onClick={() => handleSelectTemplate(item)}
                    isSelected={selectedTemplate?.id === item?.id}
                  >
                    {item.title}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          {hasSelectMessage && (
            <div className="form-element-container">
              <div className="custom-form-label">{messageSelectLabel}:</div>
              <Select
                selectedValues={
                  listOfComments.find(
                    ({ value }) => value === selectedMessageValue.value
                  )?.value
                }
              >
                {listOfComments.map(({ id, label, value }, index) => (
                  <SelectItem
                    key={`${id}-${value}-${index}`}
                    onClick={() =>
                      handleSelectMessageValue({ id, label, value })
                    }
                  >
                    {label}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          {hasSelectTone && (
            <div className="form-element-container">
              <div className="custom-form-label">{toneSelectLabel}:</div>
              <Select
                placeholder="Choose the Tone of the Message"
                selectedValues={SelectedTone}
                layerRootId="story--components-select--basic"
              >
                {tonesList.map((item) => (
                  <SelectItem
                    key={item}
                    onClick={() => handleSelectedTone(item)}
                    isSelected={SelectedTone === item}
                  >
                    {item}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          {hasSelectLanguage && (
            <div className="form-element-container">
              <div className="custom-form-label">{languageSelectLabel}:</div>
              <Select
                layerRootId="story--components-select--basic"
                selectedValues={
                  languageList.find(
                    ({ value }) => value === selectLanguage.value
                  )?.value
                }
              >
                {languageList.map(({ id, label, value }, index) => (
                  <SelectItem
                    key={`${id}-${value}-${index}`}
                    onClick={() => handleSelectedLanguage({ id, label, value })}
                  >
                    {label}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}

          {hasTokenSlider && (
            <div className="form-element-container">
              <div className="custom-form-label">
                {tokenSliderLabel}:&nbsp;{TokenSliderValue}&nbsp; Tokens
              </div>
              <Slider
                aria-label="Length of text"
                defaultValue={defaultTokenSliderValue}
                getAriaValueText={valueText}
                valueLabelDisplay="auto"
                onChange={(evt) => handleChangeTokenSlider(evt)}
                step={100}
                marks
                value={TokenSliderValue}
                min={50}
                max={maxSliderTokens}
              />
            </div>
          )}

          {hasResponseConfig && (
            <div>
              <Checkbox
                isChecked={isFullConversationChecked}
                onChange={handleChangeFullConversation}
              >
                Use full conversation text to create the draft response
              </Checkbox>
              <div className="margin-top" />
              <Checkbox
                isChecked={isSignatureChecked}
                onChange={handleChangeSignature}
              >
                Sign the message with my name
              </Checkbox>
            </div>
          )}

          {submitButtonConfig && (
            <Button
              className="margin-left-auto"
              type="primary"
              onClick={() => submitButtonConfig.onSubmit()}
            >
              {submitButtonConfig.buttonTitle}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ConversationSettings;
