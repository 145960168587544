import useSWR from "swr";
import {
  AnyDoc_service_name,
  OCR_service_name,
  Prebuilt_service_name,
  ignite_url,
  integration_name,
} from "../../constants/variables";
import { usePluginContext } from "../../../../providers/contextManagement";
import usePrimitiveFunctions from "../../services/server/usePrimitiveFunctions";
import { logoutUser } from "../functions/logoutUser";

const useAttachmentsResultSWR = (UpbrainsToken, TeamEmail) => {
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  const { checkAuth, postLog } = usePrimitiveFunctions(
    UpbrainsToken,
    TeamEmail
  );

  const getAttachmentsResult = async (Conv_Id) => {
    try {
      var requestOptions = {
        method: "GET",
        headers: await checkAuth(),
        redirect: "follow",
      };

      let service_names =
        OCR_service_name +
        "," +
        Prebuilt_service_name +
        "," +
        AnyDoc_service_name;

      let get_url_params =
        "?ticket_or_conversation_id=" +
        Conv_Id +
        "&integration_name=" +
        integration_name +
        "&service_names=" +
        service_names;
      let fetch_url =
        ignite_url + "/service-apis/service-results" + get_url_params;
      let result = await fetch(fetch_url, requestOptions);

      if (!result?.ok && result?.status === 401) logoutUser();

      const conversationResults = await result.json();

      if (conversationResults.status !== 200) {
        if (conversationResults.status === 401) {
          throw new Error(
            "Please check and provide your subscription information for UpBrains AI's Front Plugin in Settings."
          );
        } else {
          throw new Error(
            "Request to server failed. Please verify your settings or contact support. Error code: x0001p2."
          );
        }
      } else {
        return conversationResults.results;
      }
    } catch (e) {
      postLog(`Failed to get service results - get_data(): ${e}`);
      console.log("e", e);
    }
  };

  const {
    data: attachmentsResults,
    error: getAttachmentsResultError,
    isLoading: getAttachmentsResultLoading,
    mutate: mutateAttachmentsResult,
  } = useSWR(
    context?.conversation?.id
      ? [
          "/service-apis/service-results",
          context?.conversation?.id,
          localStorage.getItem("last_message_id"),
        ]
      : null,
    ([url, conversationId]) => getAttachmentsResult(conversationId),
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  return {
    attachmentsResults,
    getAttachmentsResultError,
    getAttachmentsResultLoading,
    mutateAttachmentsResult,
  };
};

export default useAttachmentsResultSWR;
