import Front from "@frontapp/plugin-sdk";

/**
 * front
 * fetching the draft
 * @param {*} id
 * @returns
 */
const fetchDraft = async (id) => {
  try {
    const result = await Front.fetchDraft(id);
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default fetchDraft;
