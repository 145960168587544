import { usePluginContext } from "../../../../providers/contextManagement";

const usePostProcessMessage = () => {
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  const postProcessMessage = (text, replace_n_with_br = false) => {
    if (text) {
      let name_index = text.indexOf("[Name]");
      if (
        name_index > 0 &&
        name_index < 100 &&
        localStorage.getItem("MessageRecipient")
      ) {
        text = text.replace(
          /\[Name\]/i,
          localStorage.getItem("MessageRecipient")
        );
      }

      if (name_index == -1) name_index = text.indexOf("[Name of Sender]");
      if (
        name_index > 0 &&
        name_index < 100 &&
        localStorage.getItem("MessageRecipient")
      ) {
        text = text.replace(
          /\[Name of Sender\]/i,
          localStorage.getItem("MessageRecipient")
        );
      }

      if (context?.teammate?.name) {
        text = text.replace(/\[Your name\]/gi, context?.teammate?.name);
      }

      if (replace_n_with_br) text = text.replace(/\n/g, "<br/>");

      if (!JSON.parse(localStorage.getItem("isSignatureChecked"))) {
        const lastTeammateCharacters = text.substr(
          -(context?.teammate?.name?.length + 5)
        );
        const modifiedText = text.slice(0, -context?.teammate?.name?.length);
        let teammateName = lastTeammateCharacters.indexOf(
          context?.teammate?.name
        );
        if (teammateName !== -1) {
          text = modifiedText;
        }
      }
    }
    return text;
  };

  return {
    postProcessMessage,
  };
};

export default usePostProcessMessage;
