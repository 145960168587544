import Front from "@frontapp/plugin-sdk";

const listMessages = async () => {
  try {
    const result = await Front.listMessages();
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default listMessages;
