import { END_POINTS } from "../../constants/endpoints";
import { xtractUrl } from "../../constants/variables";

export const postTranslateAttachment = async (mode, requestOptions) => {
  const url =
    mode === "last_message"
      ? END_POINTS.INTEGRATION_APIS.TRANSLATE_MESSAGE
      : END_POINTS.INTEGRATION_APIS.TRANSLATE;

  const res = await fetch(xtractUrl + url, requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });

  return res;
};
