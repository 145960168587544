export const isZendesk = false;
export const summarizeProduct = "Conversation";
export const responseAssistSource = "Message";
export const summarizeSelectedValue = {
  id: "public",
  label: "Messages",
  value: "Messages",
};
export const listOfComments = [
  {
    id: "public",
    label: "Messages",
    value: "Messages",
  },
  {
    id: "private",
    label: "Comments",
    value: "Comments",
  },
  {
    id: "both",
    label: "Messages and Comments",
    value: "Messages and Comments",
  },
];
export const messageType = {
  tweet_dm: "tweet_dm",
  googleplay: "googleplay",
  sms: "sms",
  whatsapp: "whatsapp",
  smooch: "smooch",
  facebook: "facebook",
  intercom: "intercom",
  front_chat: "front_chat",
};

export const conversationTypes = [
  {
    id: "conversation",
    label: "This Conversation",
  },
  {
    id: "all",
    label: "All my conversations with the sender",
  },
];

export const conversationCounts = [
  {
    id: 5,
    value: 5,
    label: "Recent 5 conversations",
  },
  {
    id: 10,
    value: 10,
    label: "Recent 10 conversations",
  },
  {
    id: 15,
    value: 15,
    label: "Recent 15 conversations",
  },
];
