import React, { useEffect, useState } from "react";
import { usePluginContext } from "../../../providers/contextManagement.js";
import {
  PluginLayout,
  Button,
  Input,
  TooltipCoordinator,
  Tooltip,
  InlineBanner,
  VisualSizesEnum,
} from "@frontapp/ui-kit";
import { Paragraph } from "@frontapp/ui-kit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { BeatLoader } from "react-spinners";
import Highlighter from "react-highlight-words";
import decryptData from "../utils/functions/decryptData.js";
import { updateExtractor } from "../services/server/extractor.service.js";
import usePrimitiveFunctions from "../services/server/usePrimitiveFunctions.js";
import { postExtractedResult } from "../api/extracor/index.js";
import useSWRMutation from "swr/mutation";

import { isZendesk } from "../../../constants/plugin_variables.js";

const DocumentsAndActions = ({
  attachmentResult,
  isOCR,
  isExpanded,
  postAsCommentMutate,
  hasAttachmentResult,
  postAsCommentData,
  posingAsCommentMutating,
  postAsCommentError,
}) => {
  const [show401Banner, setShow401Banner] = useState(false);
  const saveExtractFileInDB = async () => {
    try {
      const processorType = attachmentResult?.payload?.processor_type;
      const extractorResult =
        processorType === "Extractor"
          ? attachmentResult?.payload?.processor_result?.extractor_result
          : attachmentResult?.payload?.processor_result?.extractors_response
              ?.extractor_result;

      const file_name =
        attachmentResult.payload?.processor_result?.extractor_result?.file_name;
      const extractor_or_smartrouter_id =
        attachmentResult.payload?.processor_result?.extractor_info?.id;
      const service_name =
        attachmentResult.payload?.processor_result?.extractor_info
          ?.service_name;
      const ticket_or_conversation_id = context?.conversation.id;
      const extractor_info =
        attachmentResult.payload?.processor_result?.extractor_info;

      const _body = hasAttachmentResult
        ? JSON.stringify({
            id: attachmentResult?.payload?.result_id,
            updated_extractor_result: extractorResult,
          })
        : JSON.stringify({
            result_type: "Extractor_OR_SmartRouter", // This field is always fixed.
            integration_name: isZendesk ? "Zendesk" : "Front",
            service_name,
            message_id: localStorage.getItem("last_message_id"),
            ticket_or_conversation_id,
            processed_data: "Attachment - " + file_name,
            payload: {
              file_name,
              extractor_or_smartrouter_id,
              processor_result: {
                extractor_info,
                extractor_result: extractorResult,
              },
            },
          });
      const headers = await checkAuth();
      const requestOptions = {
        method: hasAttachmentResult ? "PUT" : "POST",
        headers,
        body: _body,
        redirect: "follow",
      };

      if (hasAttachmentResult) {
        const { status } = await updateExtractor(requestOptions);

        if (status !== 200) throw new Error(status);

        return Promise.resolve(status);
      } else {
        const result = await postExtractedResult(requestOptions);

        return result;
      }
    } catch (err) {
      Promise.reject(err);
      console.log("err", err);
    }
  };

  const {
    isMutating: isSaveResultMutating,
    trigger: SaveResultMutation,
    data: SaveResultMutationData,
    error: SaveResultMutationError,
  } = useSWRMutation(
    ["/extractor-apis/results", attachmentResult.id],
    saveExtractFileInDB,
    {
      // onSuccess: () => mutateAttachmentsResult()
    }
  );

  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));

  const [TeamEmail, setTeamEmail] = React.useState("");
  const [UpbrainsToken, setUpbrainsToken] = React.useState("");
  const [pat, setPat] = useState("DEFAULT TOKEN");
  const [ConversationID, setConversationID] = React.useState("");
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [error] = useState("");
  const [isLoading] = useState(false);
  const [removeObj, setRemoveObj] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  let StorageEmail = "";
  let StorageToken = "";
  let StorageAsanaPat = "";
  let StorageEmailVerified = "";

  const { checkAuth } = usePrimitiveFunctions(UpbrainsToken, TeamEmail);

  if (decryptData("UpbrainsToken") && !StorageToken) {
    StorageToken = decryptData("UpbrainsToken");
  }
  if (decryptData("TeamEmail") && !StorageEmail) {
    StorageEmail = decryptData("TeamEmail");
  }
  if (localStorage.getItem("AsanaPat") && !StorageAsanaPat) {
    StorageAsanaPat = JSON.parse(localStorage.getItem("AsanaPat"));
  }
  if (localStorage.getItem("EmailVerified") && !StorageEmailVerified) {
    StorageEmailVerified = JSON.parse(localStorage.getItem("EmailVerified"));
  }

  if (StorageEmail && !TeamEmail) {
    setTeamEmail(StorageEmail);
  } else if (StorageToken && !UpbrainsToken) {
    setUpbrainsToken(StorageToken);
  } else if ((!StorageEmail && !StorageToken) || !StorageEmailVerified) {
    // window.location.replace(
    //   front_plugin_url + `/setting/?app_guid=${appGuid}&origin=${origin}`
    // );
  }

  if (StorageAsanaPat && !pat) {
    setPat(StorageAsanaPat);
  }

  let conversation_id = "";
  if (context && context?.conversation) {
    conversation_id = context?.conversation?.id;
    if (ConversationID != context?.conversation?.id) {
      setConversationID(context?.conversation?.id);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("unauthorized") === "true") {
      setShow401Banner(true);
    } else {
      setShow401Banner(false);
    }
  }, [localStorage.getItem("unauthorized")]);

  return render_page();

  function render_page() {
    const handleClickOpen = ({ itemIndex }) => {
      setIsRemoveModalOpen(true);
      setRemoveObj({
        itemIndex,
      });
    };

    const handleClose = () => {
      setIsRemoveModalOpen(false);
    };

    const handleRemoveSelectedItem = () => {
      handleClose();
      const tempExtractorData = { ...attachmentResult };
      const processorType = tempExtractorData?.payload?.processor_type;
      const specificItemArray =
        processorType === "Extractor"
          ? tempExtractorData?.payload?.processor_result?.extractor_result
              ?.documents[0]?.items
          : tempExtractorData?.payload?.processor_result?.extractors_response
              ?.extractor_result?.documents[0]?.items;

      if (removeObj?.itemIndex > -1) {
        specificItemArray.splice(removeObj?.itemIndex, 1);
      }
    };

    const modal = () => (
      <Dialog
        open={isRemoveModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove selected Item:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveSelectedItem}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );

    const processTheExtractor = () => {
      const processorType = attachmentResult?.payload?.processor_type;
      const extractorInfo =
        processorType === "Extractor"
          ? attachmentResult?.payload?.processor_result?.extractor_info
          : attachmentResult?.payload?.processor_result?.extractors_response
              ?.extractor_info;

      return (
        <>
          {!!extractorInfo && (
            <>
              <tr>
                <th colSpan="2" className="singleRowTitle">
                  Extractor
                </th>
              </tr>

              {Object.keys(extractorInfo)
                .filter(
                  (extractor) =>
                    extractor !== "id" &&
                    extractor !== "only_file_processable" &&
                    extractor !== "model_name" &&
                    extractor !== "service_name"
                )
                .map((extractor) => (
                  <tr key={extractor}>
                    <td className="key">
                      {extractor
                        ?.replace("_", " ")
                        ?.split(" ")
                        .map(
                          (word) =>
                            word.charAt(0)?.toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </td>
                    <TooltipCoordinator
                      renderTooltip={() => (
                        <Tooltip>{extractorInfo[extractor]}</Tooltip>
                      )}
                    >
                      <td className="document-1">{extractorInfo[extractor]}</td>
                    </TooltipCoordinator>
                  </tr>
                ))}
            </>
          )}
        </>
      );
    };

    const handleChangeInput = (value, status, field, itemKey) => {
      const tempExtractorData = { ...attachmentResult };
      const processorType = tempExtractorData?.payload?.processor_type;
      const fieldsObject =
        processorType === "Extractor"
          ? tempExtractorData?.payload?.processor_result?.extractor_result
              ?.documents[0]?.fields
          : tempExtractorData?.payload?.processor_result?.extractors_response
              ?.extractor_result?.documents[0]?.fields;
      const itemsArray =
        processorType === "Extractor"
          ? tempExtractorData?.payload?.processor_result?.extractor_result
              ?.documents[0]?.items
          : tempExtractorData?.payload?.processor_result?.extractors_response
              ?.extractor_result?.documents[0]?.items;

      if (status === "field") {
        fieldsObject[field].content = value;
      }
      if (status === "item") {
        itemsArray[field][itemKey].content = value;
      }
    };

    const processTheExtractedInformation = () => {
      const tempExtractorData = { ...attachmentResult };
      const processorType = tempExtractorData?.payload?.processor_type;
      const serviceName =
        processorType === "Extractor"
          ? tempExtractorData?.payload?.processor_result?.extractor_info
              ?.service_name
          : tempExtractorData?.payload?.processor_result?.extractors_response
              ?.extractor_info?.service_name;

      if (serviceName && !serviceName.toLowerCase().includes("ocr")) {
        const fieldsObject =
          processorType === "Extractor"
            ? tempExtractorData?.payload?.processor_result?.extractor_result
                ?.documents[0]?.fields
            : tempExtractorData?.payload?.processor_result?.extractors_response
                ?.extractor_result?.documents[0]?.fields;
        const itemsArray =
          processorType === "Extractor"
            ? attachmentResult?.payload?.processor_result?.extractor_result
                ?.documents[0]?.items
            : attachmentResult?.payload?.processor_result?.extractors_response
                ?.extractor_result?.documents[0]?.items;

        return (
          <>
            {!!(
              (fieldsObject && Object.keys(fieldsObject)?.length) ||
              itemsArray?.length
            ) && (
              <>
                <tr>
                  <th colSpan="2" className="singleRowTitle">
                    Extracted Information
                  </th>
                </tr>

                {!!(fieldsObject && Object.keys(fieldsObject)?.length) && (
                  <>
                    {Object.keys(fieldsObject).map((field) => (
                      <tr>
                        <td className="key">
                          {fieldsObject[field].field_name}
                        </td>
                        <TooltipCoordinator
                          renderTooltip={() => (
                            <Tooltip>{fieldsObject[field].content}</Tooltip>
                          )}
                        >
                          <td className="field-td">
                            <Input
                              value={fieldsObject[field].content}
                              onChange={(value) =>
                                handleChangeInput(value, "field", field)
                              }
                            />
                          </td>
                        </TooltipCoordinator>
                      </tr>
                    ))}
                  </>
                )}

                {!!itemsArray?.length && (
                  <>
                    {itemsArray.map((item, index) => (
                      <>
                        <tr>
                          <th colSpan="2" className="singleRowTitle">
                            Line Item No. {index + 1}
                          </th>
                        </tr>
                        {Object.keys(item)
                          .filter((innerItem) => innerItem !== "page_number")
                          .map((innerItem) => (
                            <tr>
                              <td className="key">
                                {itemsArray[index][innerItem].field_name}
                              </td>
                              <TooltipCoordinator
                                renderTooltip={() => (
                                  <Tooltip>
                                    {itemsArray[index][innerItem].content}
                                  </Tooltip>
                                )}
                              >
                                <td className="field-td">
                                  <Input
                                    value={itemsArray[index][innerItem].content}
                                    onChange={(value) =>
                                      handleChangeInput(
                                        value,
                                        "item",
                                        index,
                                        innerItem
                                      )
                                    }
                                  />
                                </td>
                              </TooltipCoordinator>
                            </tr>
                          ))}
                        <tr>
                          <td colSpan={2} className="text-align-right">
                            <Button
                              type="primary-danger"
                              className="FrontButton margin-y-5"
                              onClick={() =>
                                handleClickOpen({
                                  itemIndex: index,
                                })
                              }
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        );
      }

      return undefined;
    };

    const processTheHighlight = () => {
      const processorType = attachmentResult?.payload?.processor_type;
      const serviceName =
        processorType === "Extractor"
          ? attachmentResult?.payload?.processor_result?.extractor_info
              ?.service_name
          : attachmentResult?.payload?.processor_result?.extractors_response
              ?.extractor_info?.service_name;

      let highlightAry = [];
      if (serviceName && !serviceName.toLowerCase().includes("ocr")) {
        const fieldsObject =
          processorType === "Extractor"
            ? attachmentResult?.payload?.processor_result?.extractor_result
                ?.documents[0]?.fields
            : attachmentResult?.payload?.processor_result?.extractors_response
                ?.extractor_result?.documents[0]?.fields;
        const itemsArray =
          processorType === "Extractor"
            ? attachmentResult?.payload?.processor_result?.extractor_result
                ?.documents[0]?.items
            : attachmentResult?.payload?.processor_result?.extractors_response
                ?.extractor_result?.documents[0]?.items;

        Object.keys(fieldsObject).map((field) => {
          if (fieldsObject[field]?.content?.length) {
            highlightAry.push(fieldsObject[field]?.content?.replace(/\*/g, ""));
          }
        });
        itemsArray.map((item, index) => {
          Object.keys(item)
            .filter((innerItem) => innerItem !== "page_number")
            .map((innerItem) => {
              if (itemsArray[index][innerItem]?.content?.length) {
                highlightAry.push(
                  itemsArray[index][innerItem]?.content?.replace(/\*/g, "")
                );
              }
            });
        });
      }

      const pagesArray =
        processorType === "Extractor"
          ? attachmentResult?.payload?.processor_result?.extractor_result?.pages
          : attachmentResult?.payload?.processor_result?.extractors_response
              ?.extractor_result?.pages;
      if (pagesArray?.length) {
        return (
          <div className="page-container custom-scrollbar">
            <div className="page-number-container custom-scrollbar">
              {pagesArray.map((page) => (
                <Button
                  key={page.page_number}
                  type="tertiary"
                  onClick={() => setPageNumber(page.page_number)}
                >
                  <span
                    className={`${
                      !!(Number(pageNumber) === Number(page.page_number)) &&
                      "page-number-style"
                    }`}
                  >
                    {page.page_number}
                  </span>
                </Button>
              ))}
            </div>
            <pre className="pre-element custom-scrollbar">
              <Highlighter
                searchWords={highlightAry}
                textToHighlight={pagesArray[pageNumber - 1]?.text}
                highlightClassName="Highlight"
                activeIndex="3"
                activeClassName="Active"
                highlightStyle={{ fontWeight: "normal" }}
                caseSensitive
              />
            </pre>
          </div>
        );
      }
    };
    return (
      <PluginLayout>
        {modal()}
        <div className="mainflex document-2">
          <div className="flex2 document-3">
            {context &&
            (context?.type === "singleConversation" ||
              context?.type?.toLowerCase() === "question" ||
              context?.type?.toLowerCase() === "incident" ||
              context?.type?.toLowerCase() === "problem" ||
              context?.type?.toLowerCase() === "task" ||
              context?.type?.toLowerCase() === "ticket") ? (
              <>
                {!!(
                  attachmentResult && Object.keys(attachmentResult)?.length
                ) ? (
                  <>
                    {isLoading && (
                      <div className="document-loading-container">
                        <BeatLoader
                          color={"#0059b3"}
                          loading={true}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                    <>
                      <div className="document-container">
                        <div className="expand-container">
                          <table
                            className={`${isExpanded && "table-container"}`}
                          >
                            {/* {processTheFileName()} */}
                            {/* {processTheMessage()} */}
                            {processTheExtractor()}
                            {processTheExtractedInformation()}
                            {isOCR && (
                              <tr>
                                <td colSpan={2}>{processTheHighlight()}</td>
                              </tr>
                            )}
                          </table>
                        </div>
                        <div
                          className={`highlight-container ${
                            !isExpanded && "display-none"
                          }`}
                        >
                          {processTheHighlight()}
                        </div>
                      </div>
                      {!!error && <p className="error-message">{error}</p>}
                    </>
                  </>
                ) : (
                  <div className="custom-form-label">
                    No information were found.
                  </div>
                )}
              </>
            ) : (
              <Paragraph className="prgrph">
                Please select a single {isZendesk ? "ticket" : "conversation"}{" "}
                to continue.
              </Paragraph>
            )}
          </div>
        </div>
        {!!(attachmentResult && Object.keys(attachmentResult)?.length) && (
          <div className="flex-end document-4">
            <div className="margin-bottom">
              <Button onClick={SaveResultMutation} size="small">
                Save into Database
              </Button>
            </div>
            <div className="document-5">
              <Button
                onClick={() => postAsCommentMutate(attachmentResult)}
                type="primary"
                size="small"
              >
                Post as a Comment
              </Button>
            </div>
          </div>
        )}
        {isSaveResultMutating && (
          <div className="document-6">
            <div className="document-7">
              Saving the result into the database
            </div>
            <BeatLoader
              color={"#0059b3"}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {SaveResultMutationError && (
          <div className="document-8">
            <Paragraph color={"red"}>
              {SaveResultMutationError.message}
            </Paragraph>
          </div>
        )}
        {SaveResultMutationData && (
          <div className="document-8">
            <Paragraph color={"green"}>Result saved successfully</Paragraph>
          </div>
        )}
        {posingAsCommentMutating && (
          <div className="document-8">
            <div className="document-7">Posting the result as a Comment</div>
            <BeatLoader
              color={"#0059b3"}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {postAsCommentError && (
          <div className="document-8">
            <Paragraph color={"red"}>{postAsCommentError.message}</Paragraph>
          </div>
        )}
        {postAsCommentData &&
          !postAsCommentError &&
          !posingAsCommentMutating && (
            <div className="document-8">
              <Paragraph color={"green"}>{postAsCommentData.msg}</Paragraph>
            </div>
          )}
        {!!show401Banner && (
          <div className="document-9">
            <InlineBanner
              size={VisualSizesEnum.SMALL}
              type="error"
              title="Unauthorized! Please ensure you are subscribed to Upbrains Chat and check your Front Credential in the UpBrains Portal!"
              onClose={() => setShow401Banner(false)}
            />
          </div>
        )}
      </PluginLayout>
    );
  }
};

export default DocumentsAndActions;
