import {
  date_token,
  front_plugin_url,
  ignite_url,
  integration_name,
} from "../constants/variables";
import cryptoJs from "crypto-js";
import sha256 from "crypto-js/sha256";
import decryptData from "../utils/functions/decryptData";
import encryptData from "../utils/functions/encryptData";
import showErrorMessage from "../utils/functions/showErrorMessage";

export const postUserFeedback = async (requestOptions) => {
  const response = await fetch(
    ignite_url + "/service-apis/feedback",
    requestOptions
  );
  return response;
};

export const getDateFromServer = async () => {
  try {
    const headers = new Headers({ Authorization: date_token });

    const requestOptions = {
      method: "GET",
      headers,
    };
    const response = await fetch(
      `${ignite_url}/service-apis/get-date`,
      requestOptions
    );
    const { time } = await response.json();
    if (time) {
      return time;
    } else {
      throw new Error("Failed to fetch date from server");
    }
  } catch (error) {
    console.error("Error fetching date from server:", error);
  }
};

export const getPluginSettings = async (
  systemKey,
  context,
  integration_name
) => {
  try {
    const systemDate = await getDateFromServer();
    if (!systemDate) {
      throw new Error("Failed to fetch date from server, Please Try Again!");
    }
    if (context && context?.teammate?.email) {
      const systemToken = String(
        sha256(systemDate + context?.teammate?.email + systemKey)
      ).toString(cryptoJs.enc.Hex);

      const myHeaders = new Headers();
      myHeaders.append("Authorization", systemToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("identifier", context?.teammate?.email);

      const body = JSON.stringify({
        plugin_integration: integration_name,
        plugin_username: context?.teammate?.username,
        plugin_user_email: context?.teammate?.email,
        identifier: context?.teammate?.email,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body,
        redirect: "follow",
      };

      const response = await fetch(
        `${ignite_url}/service-apis/get-plugin-settings`,
        requestOptions
      );

      if (response.status !== 200) throw new Error(response.status);

      const authorization_data = await response.json();
      return authorization_data;
      // } else if (response.status === 401) {
      //   Promise.reject(
      //     "Unauthorized! Please ensure you are subscribed to UpBrains AI."
      //   );
      // } else {
      //   Promise.reject("Something went wrong! Please contact support.");
      // }
    } else {
      throw new Error("No email found in context");
    }
  } catch (error) {
    return error.message;
  }
};

export const fetchSubscriptionData = async (token, identifier) => {
  try {
    if (identifier && token && token.length > 0 && identifier.length > 0) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("identifier", identifier);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        ignite_url + "/service-apis/subscription-check",
        requestOptions
      );
      if (!response.ok) {
        throw new Error(
          "Unauthorized! Please ensure you are subscribed to UpBrains AI."
        );
      }
      const result = await response.json();
      const arr = [];
      for (var i = 0; i < result.user_subscriptions.length; i++) {
        arr.push(result.user_subscriptions[i].service_name);
      }

      if (result?.user_subscriptions && result.user_subscriptions[0]) {
        localStorage.setItem(
          "expire_date_timestamp",
          result.user_subscriptions[0].subscription_renewal_date_timestamp
        );
        localStorage.setItem(
          "subscription_status",
          result.user_subscriptions[0].subscription_status
        );
      }

      return arr;
    } else {
      throw new Error(
        "Unauthorized! Please ensure you are subscribed to UpBrains AI."
      );
    }
  } catch (error) {
    return Promise.reject(error.message);
  }
};

const setEnvironment = async (authorizationData) => {
  try {
    const teamEmail = authorizationData.result.plugin_team_email;
    const upbrainsToken = authorizationData.result.team_creator_api_key;
    const origin = localStorage.getItem("origin");
    const appGuid = localStorage.getItem("app_guid");

    encryptData("TeamEmail", teamEmail);

    if (
      !decryptData("UpbrainsToken") ||
      decryptData("UpbrainsToken") !== upbrainsToken
    ) {
      encryptData("UpbrainsToken", upbrainsToken);
    }

    localStorage.setItem("EmailVerified", JSON.stringify(true));

    const userSubscriptions = await fetchSubscriptionData(
      upbrainsToken,
      teamEmail
    );
    if (userSubscriptions) {
      if (userSubscriptions.includes("Chat")) {
        localStorage.setItem("subscription", JSON.stringify("Chat"));
        localStorage.setItem("ChatService", JSON.stringify("Yes"));
        window.location.replace(
          front_plugin_url + `/chat/?app_guid=${appGuid}&origin=${origin}`
        );
      } else if (
        userSubscriptions.includes("Converse") ||
        userSubscriptions.includes("Xtract-Prebuilt") ||
        userSubscriptions.includes("Xtract-OCR") ||
        userSubscriptions.includes("Xtract-AnyDoc")
      ) {
        localStorage.setItem("subscription", JSON.stringify("Extract"));
        window.location.replace(
          front_plugin_url + `/results/?app_guid=${appGuid}&origin=${origin}`
        );
      } else if (
        userSubscriptions.includes("FrontApp-Plugin") ||
        userSubscriptions.includes("Front-Plugin") ||
        userSubscriptions.includes("UpBrains-AI")
      ) {
        localStorage.setItem("subscription", JSON.stringify("Chat"));
        localStorage.setItem("ChatService", JSON.stringify("Yes"));
        let default_tab = "";
        if (localStorage.getItem("DefaultTab")) {
          default_tab = localStorage.getItem("DefaultTab");
        } else {
          default_tab = "Chat";
        }
        if (default_tab == "Chat") {
          window.location.replace(
            front_plugin_url + `/chat/?app_guid=${appGuid}&origin=${origin}`
          );
        } else
          window.location.replace(
            front_plugin_url + `/chat/?app_guid=${appGuid}&origin=${origin}`
          );
      }
    } else {
      throw new Error(
        "Unauthorized! Please ensure you are subscribed to UpBrains AI."
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const postUserLead = async (body) => {
  try {
    const systemDate = await getDateFromServer();
    const identifier = process.env.REACT_APP_STATIC_USER_EMAIL;
    const systemKey = process.env.REACT_APP_SYSTEM_KEY;

    if (!systemDate) {
      throw new Error("Failed to fetch date from server");
    }
    const systemToken = String(
      sha256(systemDate + identifier + systemKey)
    ).toString(cryptoJs.enc.Hex);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", systemToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("identifier", identifier);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body,
      redirect: "follow",
    };
    const response = await fetch(
      ignite_url + "/service-apis/lead",
      requestOptions
    );
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const login = async (context, integration_name) => {
  try {
    const systemKey = process.env.REACT_APP_SYSTEM_KEY;

    const authorizationData = await getPluginSettings(
      systemKey,
      context,
      integration_name
    );
    if (authorizationData && authorizationData?.result?.email_verified) {
      await setEnvironment(authorizationData);
      localStorage.setItem("unauthorized", "false");
      return "authorized";
    } else if (
      (authorizationData?.status == 200 &&
        authorizationData.result &&
        !Object.keys(authorizationData.result)?.length) ||
      authorizationData?.status?.includes("401") ||
      String(authorizationData)?.includes("401") ||
      authorizationData?.status?.includes("403") ||
      String(authorizationData)?.includes("403")
    ) {
      // if admin then open popup -> if you don't have an account in upbrains create a free trial account
      throw new Error(
        "No active account found. If your team does not have an account with UpBrains AI, please use Get Start for Free option to create a free 14-days trial subscription."
      );
    } else if (
      authorizationData?.status !== 200 &&
      (!String(authorizationData?.status)?.includes("401") ||
        !String(authorizationData)?.includes("401"))
    ) {
      const errorMessage = showErrorMessage(authorizationData.status);
      throw new Error(errorMessage);
    }
  } catch (error) {
    postUserLead(
      JSON.stringify({
        plugin_user_name: context?.teammate?.username,
        plugin_user_email: context?.teammate?.email,
        integration_name,
        action: "Login",
        team_id: context?.teammate?.team_id,
      })
    );
    return Promise.reject(error.message);
  }
};
